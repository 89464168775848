import { ApiClient } from "~/clApi/main/foundation/ApiClient"

const basePath = "/template/corporation_member"

export const write = {
  /**
   * @returns 新規作成されたテンプレートのIdを返す
   */
  createScoutTemplate: (params: {
    universalCorporationId: string
    title: string
    text: string
  }) =>
    new ApiClient<string>().post(
      `${basePath}/${params.universalCorporationId}/scout/create`,
      { title: params.title, text: params.text }
    ),
  changeScoutTemplate: (params: {
    universalCorporationId: string
    templateId: string
    title: string
    text: string
  }) =>
    new ApiClient<string>().post(
      `${basePath}/${params.universalCorporationId}/scout/${params.templateId}/edit/message/change`,
      { title: params.title, text: params.text }
    ),
  archiveScoutTemplate: (params: {
    universalCorporationId: string
    templateId: string
  }) =>
    new ApiClient<string>().post(
      `${basePath}/${params.universalCorporationId}/scout/${params.templateId}/archive`,
      {}
    ),
}
