import { ApplicationError } from "./ApplicationError"

/*
 * アプリケーション上で発生するエラー全般。
 * JavaScript標準のErrorクラスをそのまま使わず、本クラスを利用することで独自で定義したエラーがThrowされていることを明示するようにしている。
 * ただし、頻繁に利用するエラーケースにはまた別に ApplicationErrorクラス を継承したエラークラスを独自で定義して利用するようにすること。
 */
export class StandardApplicationError extends ApplicationError {
  constructor(message: string) {
    super({ className: "StandardApplicationError", message })
  }
}
