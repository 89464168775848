import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { NumberOfProjectsView } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
  daysOfAggregationPeriod?: number
}

export class CountPublishedProjectsReadRequest extends ReadRequest<NumberOfProjectsView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<NumberOfProjectsView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { universalCorporationId, daysOfAggregationPeriod } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/count_published_projects${
      daysOfAggregationPeriod
        ? `?daysOfAggregationPeriod=${daysOfAggregationPeriod}`
        : ""
    }`
  }
}
