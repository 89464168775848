import { ApiClient } from "~/backendApis/foundation/ApiClient"

/**
 * This is base class for write request.
 */
export abstract class WriteRequest<T> {
  protected readonly apiClient = new ApiClient<T>()

  abstract post(): Promise<T>
}
