type TargetEvent = "keydown" | "mousedown" | "click"

interface ListeningTargetFunctions {
  event: TargetEvent
  func: () => void
}

/*
 * ユーザー操作に関するイベントを監視することを責務とするクラス。
 * eventListenerを利用して、ユーザーが操作した時に任意の処理を実行してくれる。
 */
export class UserOperatingEventListener {
  // ユーザーが操作したと見做すイベント
  // scroll はイベント発生頻度がかなり多く、パフォーマンスを懸念して意図的に外している。
  private static EVENTS_CONSIDERED_USER_ACTION: TargetEvent[] = [
    "keydown",
    "mousedown",
    "click",
  ]

  // addEventListenerでバインド中のイベントとその関数が入る。
  // removeEventListenerする際に利用する。
  private listeningTargetFunctions: ListeningTargetFunctions[] = []

  startListening(func: () => void) {
    const events = UserOperatingEventListener.EVENTS_CONSIDERED_USER_ACTION
    events.forEach((event: TargetEvent) => {
      this.listeningTargetFunctions.push({ event, func })
      document.addEventListener(event, func)
    })
  }

  stopListening() {
    this.listeningTargetFunctions.forEach((elm: ListeningTargetFunctions) => {
      document.removeEventListener(elm.event, elm.func)
    })
    this.listeningTargetFunctions = []
  }

  static build(): UserOperatingEventListener {
    return new UserOperatingEventListener()
  }
}
