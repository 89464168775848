import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { UniversalUidByEmailView } from "~/backendApis/viewModel/userAccount"

interface Params {
  universalCorporationId: string
  email: string
}

export class GetUserAccountByEmailReadRequest extends ReadRequest<UniversalUidByEmailView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<UniversalUidByEmailView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/user_account/corporation_member/${
      this.params.universalCorporationId
    }/corporation_member_invitation/get_user_account_by_email?email=${encodeURIComponent(
      this.params.email
    )}`
  }
}
