











import Vue from "vue"
export default Vue.extend({
  props: {
    isShown: {
      type: Boolean,
      default: true,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click")
    },
  },
})
