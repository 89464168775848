import Vue from 'vue'
import Rollbar from 'rollbar'

const createRollbarInstance = function (accessToken) {
  const config = {"captureUncaught":false,"captureUnhandledRejections":false,"source_map_enabled":true,"enabled":true,"environment":"production"}
  config.accessToken = accessToken
  return new Rollbar(config)
}

const serverRollbar = createRollbarInstance('1e9c54c7ebae4296bf119cdefb32f704')

const clientRollbar = createRollbarInstance('7928b25c62d348ccb00f06fad4e4473b')

const noop = function () {}
const stubRollbar = Object.create(null)
Object.defineProperties(
  stubRollbar,
  Object.getOwnPropertyNames(Rollbar.prototype).reduce(function (acc, key) {
    acc[key] = { value: noop }
    return acc
  }, {}))

const RollbarAdapter = Object.create(null)
Object.defineProperty(RollbarAdapter, 'instance', {
  get() {
    if (process.server) {
      return serverRollbar
    }

    if (process.client) {
      return clientRollbar
    }

    return stubRollbar
  },
})

export default function (ctx, inject) {
  // Inject Rollbar to the context as $rollbar
  ctx.$rollbar = RollbarAdapter.instance
  inject('rollbar', RollbarAdapter.instance)
  Vue.rollbar = RollbarAdapter.instance
}
