import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0590869d = () => interopDefault(import('../src/pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _fdeec70e = () => interopDefault(import('../src/pages/chats/index.vue' /* webpackChunkName: "pages/chats/index" */))
const _087bb788 = () => interopDefault(import('../src/pages/chats/index/index.vue' /* webpackChunkName: "pages/chats/index/index" */))
const _2677f4b8 = () => interopDefault(import('../src/pages/chats/index/_id.vue' /* webpackChunkName: "pages/chats/index/_id" */))
const _1cd9f278 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e4424b82 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _43de9ebc = () => interopDefault(import('../src/pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _6256ad9c = () => interopDefault(import('../src/pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _240b3790 = () => interopDefault(import('../src/pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _58648052 = () => interopDefault(import('../src/pages/switch_account.vue' /* webpackChunkName: "pages/switch_account" */))
const _5c2dbc3c = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _53f82e28 = () => interopDefault(import('../src/pages/unsubscribed.vue' /* webpackChunkName: "pages/unsubscribed" */))
const _d6813a6e = () => interopDefault(import('../src/pages/biz/signup.vue' /* webpackChunkName: "pages/biz/signup" */))
const _72a15682 = () => interopDefault(import('../src/pages/corporation/applications/index.vue' /* webpackChunkName: "pages/corporation/applications/index" */))
const _42ea04a0 = () => interopDefault(import('../src/pages/corporation/applications/index/chat/_id.vue' /* webpackChunkName: "pages/corporation/applications/index/chat/_id" */))
const _5a2b7bf2 = () => interopDefault(import('../src/pages/corporation/chats/index.vue' /* webpackChunkName: "pages/corporation/chats/index" */))
const _fc587616 = () => interopDefault(import('../src/pages/corporation/chats/index/index.vue' /* webpackChunkName: "pages/corporation/chats/index/index" */))
const _9beefec6 = () => interopDefault(import('../src/pages/corporation/chats/index/_id.vue' /* webpackChunkName: "pages/corporation/chats/index/_id" */))
const _7c49bca6 = () => interopDefault(import('../src/pages/corporation/edit.vue' /* webpackChunkName: "pages/corporation/edit" */))
const _4417dd38 = () => interopDefault(import('../src/pages/corporation/favorite.vue' /* webpackChunkName: "pages/corporation/favorite" */))
const _71b5cf20 = () => interopDefault(import('../src/pages/corporation/members/index.vue' /* webpackChunkName: "pages/corporation/members/index" */))
const _4a514b92 = () => interopDefault(import('../src/pages/corporation/new/index.vue' /* webpackChunkName: "pages/corporation/new/index" */))
const _3500c03d = () => interopDefault(import('../src/pages/corporation/profile.vue' /* webpackChunkName: "pages/corporation/profile" */))
const _c0c091d8 = () => interopDefault(import('../src/pages/corporation/scouts/index.vue' /* webpackChunkName: "pages/corporation/scouts/index" */))
const _a00baf64 = () => interopDefault(import('../src/pages/corporation/scouts/index/chat/_id.vue' /* webpackChunkName: "pages/corporation/scouts/index/chat/_id" */))
const _92619ba6 = () => interopDefault(import('../src/pages/faq/corporation/index.vue' /* webpackChunkName: "pages/faq/corporation/index" */))
const _5da81948 = () => interopDefault(import('../src/pages/faq/user/index.vue' /* webpackChunkName: "pages/faq/user/index" */))
const _518951c4 = () => interopDefault(import('../src/pages/form/future_uptime_hours.vue' /* webpackChunkName: "pages/form/future_uptime_hours" */))
const _eea6d3be = () => interopDefault(import('../src/pages/lp/client_contact_form.vue' /* webpackChunkName: "pages/lp/client_contact_form" */))
const _7bae4e78 = () => interopDefault(import('../src/pages/lp/client_download_document.vue' /* webpackChunkName: "pages/lp/client_download_document" */))
const _51e6ba89 = () => interopDefault(import('../src/pages/lp/client_type1.vue' /* webpackChunkName: "pages/lp/client_type1" */))
const _51f4d20a = () => interopDefault(import('../src/pages/lp/client_type2.vue' /* webpackChunkName: "pages/lp/client_type2" */))
const _5202e98b = () => interopDefault(import('../src/pages/lp/client_type3.vue' /* webpackChunkName: "pages/lp/client_type3" */))
const _8adac726 = () => interopDefault(import('../src/pages/lp/kobe.vue' /* webpackChunkName: "pages/lp/kobe" */))
const _75d123e2 = () => interopDefault(import('../src/pages/lp/thanks.vue' /* webpackChunkName: "pages/lp/thanks" */))
const _4ffc795a = () => interopDefault(import('../src/pages/profiles/view_history.vue' /* webpackChunkName: "pages/profiles/view_history" */))
const _2c1ff26c = () => interopDefault(import('../src/pages/projects/favorite.vue' /* webpackChunkName: "pages/projects/favorite" */))
const _a41de1ac = () => interopDefault(import('../src/pages/reset/request.vue' /* webpackChunkName: "pages/reset/request" */))
const _3be3d09b = () => interopDefault(import('../src/pages/signup/confirm_email.vue' /* webpackChunkName: "pages/signup/confirm_email" */))
const _684b6132 = () => interopDefault(import('../src/pages/user/account_setting/index.vue' /* webpackChunkName: "pages/user/account_setting/index" */))
const _399376c9 = () => interopDefault(import('../src/pages/corporation/member_profile/edit.vue' /* webpackChunkName: "pages/corporation/member_profile/edit" */))
const _107e1b30 = () => interopDefault(import('../src/pages/corporation/member_profile/login_password.vue' /* webpackChunkName: "pages/corporation/member_profile/login_password" */))
const _1e976884 = () => interopDefault(import('../src/pages/corporation/members/new.vue' /* webpackChunkName: "pages/corporation/members/new" */))
const _17925eb4 = () => interopDefault(import('../src/pages/corporation/new/thanks.vue' /* webpackChunkName: "pages/corporation/new/thanks" */))
const _73f02e88 = () => interopDefault(import('../src/pages/corporation/payment/management.vue' /* webpackChunkName: "pages/corporation/payment/management" */))
const _8e208cf4 = () => interopDefault(import('../src/pages/profiles/edit/base.vue' /* webpackChunkName: "pages/profiles/edit/base" */))
const _b9e89ae6 = () => interopDefault(import('../src/pages/profiles/edit/capability.vue' /* webpackChunkName: "pages/profiles/edit/capability" */))
const _37a24f98 = () => interopDefault(import('../src/pages/profiles/edit/job.vue' /* webpackChunkName: "pages/profiles/edit/job" */))
const _ce8f4f34 = () => interopDefault(import('../src/pages/profiles/edit/philosophy.vue' /* webpackChunkName: "pages/profiles/edit/philosophy" */))
const _14addd63 = () => interopDefault(import('../src/pages/profiles/edit/portfolio.vue' /* webpackChunkName: "pages/profiles/edit/portfolio" */))
const _ca9c4e70 = () => interopDefault(import('../src/pages/profiles/edit/self_introduction.vue' /* webpackChunkName: "pages/profiles/edit/self_introduction" */))
const _77bcaec7 = () => interopDefault(import('../src/pages/profiles/edit/skill_tag.vue' /* webpackChunkName: "pages/profiles/edit/skill_tag" */))
const _12f51bd3 = () => interopDefault(import('../src/pages/profiles/edit/sns.vue' /* webpackChunkName: "pages/profiles/edit/sns" */))
const _2c548284 = () => interopDefault(import('../src/pages/profiles/edit/supplementary_explanation.vue' /* webpackChunkName: "pages/profiles/edit/supplementary_explanation" */))
const _bf4ed210 = () => interopDefault(import('../src/pages/profiles/edit/work_style.vue' /* webpackChunkName: "pages/profiles/edit/work_style" */))
const _54199b40 = () => interopDefault(import('../src/pages/profiles/new/base.vue' /* webpackChunkName: "pages/profiles/new/base" */))
const _bc033bb6 = () => interopDefault(import('../src/pages/profiles/new/initial.vue' /* webpackChunkName: "pages/profiles/new/initial" */))
const _f1800e30 = () => interopDefault(import('../src/pages/profiles/new/initial/index.vue' /* webpackChunkName: "pages/profiles/new/initial/index" */))
const _4afe13f2 = () => interopDefault(import('../src/pages/profiles/new/initial/capability.vue' /* webpackChunkName: "pages/profiles/new/initial/capability" */))
const _9de27750 = () => interopDefault(import('../src/pages/profiles/new/initial/career.vue' /* webpackChunkName: "pages/profiles/new/initial/career" */))
const _a8184958 = () => interopDefault(import('../src/pages/profiles/new/initial/career_change_intention.vue' /* webpackChunkName: "pages/profiles/new/initial/career_change_intention" */))
const _f9ebe61e = () => interopDefault(import('../src/pages/profiles/new/initial/image.vue' /* webpackChunkName: "pages/profiles/new/initial/image" */))
const _8666f45a = () => interopDefault(import('../src/pages/profiles/new/initial/job.vue' /* webpackChunkName: "pages/profiles/new/initial/job" */))
const _6751e87a = () => interopDefault(import('../src/pages/profiles/new/initial/self_introduction.vue' /* webpackChunkName: "pages/profiles/new/initial/self_introduction" */))
const _9213e67c = () => interopDefault(import('../src/pages/profiles/new/initial/skill_tag.vue' /* webpackChunkName: "pages/profiles/new/initial/skill_tag" */))
const _484af85d = () => interopDefault(import('../src/pages/profiles/new/initial/work_style.vue' /* webpackChunkName: "pages/profiles/new/initial/work_style" */))
const _358dd510 = () => interopDefault(import('../src/pages/profiles/new/thanks.vue' /* webpackChunkName: "pages/profiles/new/thanks" */))
const _217575d6 = () => interopDefault(import('../src/pages/profiles/notice/skill_tag.vue' /* webpackChunkName: "pages/profiles/notice/skill_tag" */))
const _16d00efc = () => interopDefault(import('../src/pages/user/account_setting/email.vue' /* webpackChunkName: "pages/user/account_setting/email" */))
const _234558b4 = () => interopDefault(import('../src/pages/user/account_setting/login_email.vue' /* webpackChunkName: "pages/user/account_setting/login_email" */))
const _64f2443c = () => interopDefault(import('../src/pages/user/account_setting/login_methods.vue' /* webpackChunkName: "pages/user/account_setting/login_methods" */))
const _24ddd1fe = () => interopDefault(import('../src/pages/user/account_setting/login_password.vue' /* webpackChunkName: "pages/user/account_setting/login_password" */))
const _050c4dec = () => interopDefault(import('../src/pages/user/account_setting/phone_number.vue' /* webpackChunkName: "pages/user/account_setting/phone_number" */))
const _880844c4 = () => interopDefault(import('../src/pages/user/account_setting/profile_readability.vue' /* webpackChunkName: "pages/user/account_setting/profile_readability" */))
const _054f8b05 = () => interopDefault(import('../src/pages/user/account_setting/withdrawal.vue' /* webpackChunkName: "pages/user/account_setting/withdrawal" */))
const _075c697c = () => interopDefault(import('../src/pages/user/account_setting/withdrawal_description.vue' /* webpackChunkName: "pages/user/account_setting/withdrawal_description" */))
const _f64d2ff8 = () => interopDefault(import('../src/pages/corporation/payment/form/billing_destination.vue' /* webpackChunkName: "pages/corporation/payment/form/billing_destination" */))
const _2a4f278e = () => interopDefault(import('../src/pages/corporation/payment/form/free_trial.vue' /* webpackChunkName: "pages/corporation/payment/form/free_trial" */))
const _67cf77a3 = () => interopDefault(import('../src/pages/corporation/payment/form/subscription_request.vue' /* webpackChunkName: "pages/corporation/payment/form/subscription_request" */))
const _66eaeae9 = () => interopDefault(import('../src/pages/corporation/projects/new/detail.vue' /* webpackChunkName: "pages/corporation/projects/new/detail" */))
const _4667bbe4 = () => interopDefault(import('../src/pages/corporation/projects/new/feature.vue' /* webpackChunkName: "pages/corporation/projects/new/feature" */))
const _b3645c40 = () => interopDefault(import('../src/pages/corporation/projects/new/preview.vue' /* webpackChunkName: "pages/corporation/projects/new/preview" */))
const _3e6daf20 = () => interopDefault(import('../src/pages/corporation/projects/new/title.vue' /* webpackChunkName: "pages/corporation/projects/new/title" */))
const _d9ee0584 = () => interopDefault(import('../src/pages/corporation/projects/edit/_id.vue' /* webpackChunkName: "pages/corporation/projects/edit/_id" */))
const _23f09a72 = () => interopDefault(import('../src/pages/corporation/projects/edit/_id/detail.vue' /* webpackChunkName: "pages/corporation/projects/edit/_id/detail" */))
const _7f0738b6 = () => interopDefault(import('../src/pages/corporation/projects/edit/_id/feature.vue' /* webpackChunkName: "pages/corporation/projects/edit/_id/feature" */))
const _60a88c02 = () => interopDefault(import('../src/pages/corporation/projects/edit/_id/setting.vue' /* webpackChunkName: "pages/corporation/projects/edit/_id/setting" */))
const _49fae3c7 = () => interopDefault(import('../src/pages/corporation/projects/edit/_id/title.vue' /* webpackChunkName: "pages/corporation/projects/edit/_id/title" */))
const _224bdd00 = () => interopDefault(import('../src/pages/corporation/scouts/create/_userId.vue' /* webpackChunkName: "pages/corporation/scouts/create/_userId" */))
const _0a2a2c89 = () => interopDefault(import('../src/pages/profiles/edit/career/_experience_corporation_index.vue' /* webpackChunkName: "pages/profiles/edit/career/_experience_corporation_index" */))
const _2253bf4e = () => interopDefault(import('../src/pages/corporation/profiles/_universalCorporationId.vue' /* webpackChunkName: "pages/corporation/profiles/_universalCorporationId" */))
const _4cb85fc1 = () => interopDefault(import('../src/pages/corporation/projects/_id.vue' /* webpackChunkName: "pages/corporation/projects/_id" */))
const _48145184 = () => interopDefault(import('../src/pages/corporation/projects/_id/index.vue' /* webpackChunkName: "pages/corporation/projects/_id/index" */))
const _c77930b8 = () => interopDefault(import('../src/pages/corporation/projects/_id/applications/index.vue' /* webpackChunkName: "pages/corporation/projects/_id/applications/index" */))
const _5ae8f9b6 = () => interopDefault(import('../src/pages/corporation/projects/_id/applications/index/chat/_chatId.vue' /* webpackChunkName: "pages/corporation/projects/_id/applications/index/chat/_chatId" */))
const _48707646 = () => interopDefault(import('../src/pages/corporation/projects/_id/favorited_users/index.vue' /* webpackChunkName: "pages/corporation/projects/_id/favorited_users/index" */))
const _14186e7c = () => interopDefault(import('../src/pages/corporation/scouts/_id.vue' /* webpackChunkName: "pages/corporation/scouts/_id" */))
const _4bb51803 = () => interopDefault(import('../src/pages/lp/wp_download/_id.vue' /* webpackChunkName: "pages/lp/wp_download/_id" */))
const _194c83b5 = () => interopDefault(import('../src/pages/profiles/_userId.vue' /* webpackChunkName: "pages/profiles/_userId" */))
const _017f501a = () => interopDefault(import('../src/pages/projects/_id.vue' /* webpackChunkName: "pages/projects/_id" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _07df0283 = () => interopDefault(import('../src/pages/__/auth/action.vue' /* webpackChunkName: "pages/__/auth/action" */))
const _7b0cb465 = () => interopDefault(import('../src/pages/__/auth/signup.vue' /* webpackChunkName: "pages/__/auth/signup" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _0590869d,
    name: "auth"
  }, {
    path: "/chats",
    component: _fdeec70e,
    children: [{
      path: "",
      component: _087bb788,
      name: "chats-index"
    }, {
      path: ":id",
      component: _2677f4b8,
      name: "chats-index-id"
    }]
  }, {
    path: "/login",
    component: _1cd9f278,
    name: "login"
  }, {
    path: "/logout",
    component: _e4424b82,
    name: "logout"
  }, {
    path: "/profiles",
    component: _43de9ebc,
    name: "profiles"
  }, {
    path: "/projects",
    component: _6256ad9c,
    name: "projects"
  }, {
    path: "/signup",
    component: _240b3790,
    name: "signup"
  }, {
    path: "/switch_account",
    component: _58648052,
    name: "switch_account"
  }, {
    path: "/terms",
    component: _5c2dbc3c,
    name: "terms"
  }, {
    path: "/unsubscribed",
    component: _53f82e28,
    name: "unsubscribed"
  }, {
    path: "/biz/signup",
    component: _d6813a6e,
    name: "biz-signup"
  }, {
    path: "/corporation/applications",
    component: _72a15682,
    name: "corporation-applications",
    children: [{
      path: "chat/:id?",
      component: _42ea04a0,
      name: "corporation-applications-index-chat-id"
    }]
  }, {
    path: "/corporation/chats",
    component: _5a2b7bf2,
    children: [{
      path: "",
      component: _fc587616,
      name: "corporation-chats-index"
    }, {
      path: ":id",
      component: _9beefec6,
      name: "corporation-chats-index-id"
    }]
  }, {
    path: "/corporation/edit",
    component: _7c49bca6,
    name: "corporation-edit"
  }, {
    path: "/corporation/favorite",
    component: _4417dd38,
    name: "corporation-favorite"
  }, {
    path: "/corporation/members",
    component: _71b5cf20,
    name: "corporation-members"
  }, {
    path: "/corporation/new",
    component: _4a514b92,
    name: "corporation-new"
  }, {
    path: "/corporation/profile",
    component: _3500c03d,
    name: "corporation-profile"
  }, {
    path: "/corporation/scouts",
    component: _c0c091d8,
    name: "corporation-scouts",
    children: [{
      path: "chat/:id?",
      component: _a00baf64,
      name: "corporation-scouts-index-chat-id"
    }]
  }, {
    path: "/faq/corporation",
    component: _92619ba6,
    name: "faq-corporation"
  }, {
    path: "/faq/user",
    component: _5da81948,
    name: "faq-user"
  }, {
    path: "/form/future_uptime_hours",
    component: _518951c4,
    name: "form-future_uptime_hours"
  }, {
    path: "/lp/client_contact_form",
    component: _eea6d3be,
    name: "lp-client_contact_form"
  }, {
    path: "/lp/client_download_document",
    component: _7bae4e78,
    name: "lp-client_download_document"
  }, {
    path: "/lp/client_type1",
    component: _51e6ba89,
    name: "lp-client_type1"
  }, {
    path: "/lp/client_type2",
    component: _51f4d20a,
    name: "lp-client_type2"
  }, {
    path: "/lp/client_type3",
    component: _5202e98b,
    name: "lp-client_type3"
  }, {
    path: "/lp/kobe",
    component: _8adac726,
    name: "lp-kobe"
  }, {
    path: "/lp/thanks",
    component: _75d123e2,
    name: "lp-thanks"
  }, {
    path: "/profiles/view_history",
    component: _4ffc795a,
    name: "profiles-view_history"
  }, {
    path: "/projects/favorite",
    component: _2c1ff26c,
    name: "projects-favorite"
  }, {
    path: "/reset/request",
    component: _a41de1ac,
    name: "reset-request"
  }, {
    path: "/signup/confirm_email",
    component: _3be3d09b,
    name: "signup-confirm_email"
  }, {
    path: "/user/account_setting",
    component: _684b6132,
    name: "user-account_setting"
  }, {
    path: "/corporation/member_profile/edit",
    component: _399376c9,
    name: "corporation-member_profile-edit"
  }, {
    path: "/corporation/member_profile/login_password",
    component: _107e1b30,
    name: "corporation-member_profile-login_password"
  }, {
    path: "/corporation/members/new",
    component: _1e976884,
    name: "corporation-members-new"
  }, {
    path: "/corporation/new/thanks",
    component: _17925eb4,
    name: "corporation-new-thanks"
  }, {
    path: "/corporation/payment/management",
    component: _73f02e88,
    name: "corporation-payment-management"
  }, {
    path: "/profiles/edit/base",
    component: _8e208cf4,
    name: "profiles-edit-base"
  }, {
    path: "/profiles/edit/capability",
    component: _b9e89ae6,
    name: "profiles-edit-capability"
  }, {
    path: "/profiles/edit/job",
    component: _37a24f98,
    name: "profiles-edit-job"
  }, {
    path: "/profiles/edit/philosophy",
    component: _ce8f4f34,
    name: "profiles-edit-philosophy"
  }, {
    path: "/profiles/edit/portfolio",
    component: _14addd63,
    name: "profiles-edit-portfolio"
  }, {
    path: "/profiles/edit/self_introduction",
    component: _ca9c4e70,
    name: "profiles-edit-self_introduction"
  }, {
    path: "/profiles/edit/skill_tag",
    component: _77bcaec7,
    name: "profiles-edit-skill_tag"
  }, {
    path: "/profiles/edit/sns",
    component: _12f51bd3,
    name: "profiles-edit-sns"
  }, {
    path: "/profiles/edit/supplementary_explanation",
    component: _2c548284,
    name: "profiles-edit-supplementary_explanation"
  }, {
    path: "/profiles/edit/work_style",
    component: _bf4ed210,
    name: "profiles-edit-work_style"
  }, {
    path: "/profiles/new/base",
    component: _54199b40,
    name: "profiles-new-base"
  }, {
    path: "/profiles/new/initial",
    component: _bc033bb6,
    children: [{
      path: "",
      component: _f1800e30,
      name: "profiles-new-initial"
    }, {
      path: "capability",
      component: _4afe13f2,
      name: "profiles-new-initial-capability"
    }, {
      path: "career",
      component: _9de27750,
      name: "profiles-new-initial-career"
    }, {
      path: "career_change_intention",
      component: _a8184958,
      name: "profiles-new-initial-career_change_intention"
    }, {
      path: "image",
      component: _f9ebe61e,
      name: "profiles-new-initial-image"
    }, {
      path: "job",
      component: _8666f45a,
      name: "profiles-new-initial-job"
    }, {
      path: "self_introduction",
      component: _6751e87a,
      name: "profiles-new-initial-self_introduction"
    }, {
      path: "skill_tag",
      component: _9213e67c,
      name: "profiles-new-initial-skill_tag"
    }, {
      path: "work_style",
      component: _484af85d,
      name: "profiles-new-initial-work_style"
    }]
  }, {
    path: "/profiles/new/thanks",
    component: _358dd510,
    name: "profiles-new-thanks"
  }, {
    path: "/profiles/notice/skill_tag",
    component: _217575d6,
    name: "profiles-notice-skill_tag"
  }, {
    path: "/user/account_setting/email",
    component: _16d00efc,
    name: "user-account_setting-email"
  }, {
    path: "/user/account_setting/login_email",
    component: _234558b4,
    name: "user-account_setting-login_email"
  }, {
    path: "/user/account_setting/login_methods",
    component: _64f2443c,
    name: "user-account_setting-login_methods"
  }, {
    path: "/user/account_setting/login_password",
    component: _24ddd1fe,
    name: "user-account_setting-login_password"
  }, {
    path: "/user/account_setting/phone_number",
    component: _050c4dec,
    name: "user-account_setting-phone_number"
  }, {
    path: "/user/account_setting/profile_readability",
    component: _880844c4,
    name: "user-account_setting-profile_readability"
  }, {
    path: "/user/account_setting/withdrawal",
    component: _054f8b05,
    name: "user-account_setting-withdrawal"
  }, {
    path: "/user/account_setting/withdrawal_description",
    component: _075c697c,
    name: "user-account_setting-withdrawal_description"
  }, {
    path: "/corporation/payment/form/billing_destination",
    component: _f64d2ff8,
    name: "corporation-payment-form-billing_destination"
  }, {
    path: "/corporation/payment/form/free_trial",
    component: _2a4f278e,
    name: "corporation-payment-form-free_trial"
  }, {
    path: "/corporation/payment/form/subscription_request",
    component: _67cf77a3,
    name: "corporation-payment-form-subscription_request"
  }, {
    path: "/corporation/projects/new/detail",
    component: _66eaeae9,
    name: "corporation-projects-new-detail"
  }, {
    path: "/corporation/projects/new/feature",
    component: _4667bbe4,
    name: "corporation-projects-new-feature"
  }, {
    path: "/corporation/projects/new/preview",
    component: _b3645c40,
    name: "corporation-projects-new-preview"
  }, {
    path: "/corporation/projects/new/title",
    component: _3e6daf20,
    name: "corporation-projects-new-title"
  }, {
    path: "/corporation/projects/edit/:id?",
    component: _d9ee0584,
    name: "corporation-projects-edit-id",
    children: [{
      path: "detail",
      component: _23f09a72,
      name: "corporation-projects-edit-id-detail"
    }, {
      path: "feature",
      component: _7f0738b6,
      name: "corporation-projects-edit-id-feature"
    }, {
      path: "setting",
      component: _60a88c02,
      name: "corporation-projects-edit-id-setting"
    }, {
      path: "title",
      component: _49fae3c7,
      name: "corporation-projects-edit-id-title"
    }]
  }, {
    path: "/corporation/scouts/create/:userId?",
    component: _224bdd00,
    name: "corporation-scouts-create-userId"
  }, {
    path: "/profiles/edit/career/:experience_corporation_index?",
    component: _0a2a2c89,
    name: "profiles-edit-career-experience_corporation_index"
  }, {
    path: "/corporation/profiles/:universalCorporationId?",
    component: _2253bf4e,
    name: "corporation-profiles-universalCorporationId"
  }, {
    path: "/corporation/projects/:id?",
    component: _4cb85fc1,
    children: [{
      path: "",
      component: _48145184,
      name: "corporation-projects-id"
    }, {
      path: "applications",
      component: _c77930b8,
      name: "corporation-projects-id-applications",
      children: [{
        path: "chat/:chatId?",
        component: _5ae8f9b6,
        name: "corporation-projects-id-applications-index-chat-chatId"
      }]
    }, {
      path: "favorited_users",
      component: _48707646,
      name: "corporation-projects-id-favorited_users"
    }]
  }, {
    path: "/corporation/scouts/:id",
    component: _14186e7c,
    name: "corporation-scouts-id"
  }, {
    path: "/lp/wp_download/:id?",
    component: _4bb51803,
    name: "lp-wp_download-id"
  }, {
    path: "/profiles/:userId",
    component: _194c83b5,
    name: "profiles-userId"
  }, {
    path: "/projects/:id",
    component: _017f501a,
    name: "projects-id"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index"
  }, {
    path: "/:_/auth/action",
    component: _07df0283,
    name: "_-auth-action"
  }, {
    path: "/:_/auth/signup",
    component: _7b0cb465,
    name: "_-auth-signup"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
