export interface UserAccountView {
  universalUid: string
  achievedGradeUpConditions: string[]
  achievedSignUpCompletionConditions: string[]
  activeStatus: "active" | "frozen" | "resigned"
  email: string
  signCompletionStatus: "incompleted_sign_up" | "completed_sign_up"
  userGrade: "assist" | "standard"
  roleId: number | null
}

export const decorateUserAccountView = (view: UserAccountView) => ({
  isCompletedSignUp: (): boolean =>
    view.signCompletionStatus === "completed_sign_up",
  isAssistUserGrade: (): boolean => view.userGrade === "assist",
  isStandardUserGrade: (): boolean => view.userGrade === "standard",
})

export interface UserAccountCumulativeStatsView {
  numberOfTotalUserAccount: number
  numberOfEachActiveStatus: {
    active: number
    frozen: number
    resigned: number
  }
  numberOfEachSignUpCompletionStatus: {
    incompletedSignUp: number
    completedSignUp: number
  }
}

export interface OnboardingStatusView {
  universalUid: string
  finishedStages: string[]
}

export const decorateOnboardingStatusView = (view: OnboardingStatusView) => ({
  isFinishedProfilePublishSettingOnboarding: (): boolean =>
    view.finishedStages.includes("profile_publish_setting"),
})

export interface TemporaryAccountView {
  email: string
}

export interface UniversalUidByEmailView {
  universalUid: string | null
}
