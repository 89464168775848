/**
 * プロジェクト応募のフィルタリングに利用するパラメーターに関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { BackendApi } from "~/backendApis"
import { clAuth } from "~/clAuth"
import { StandardApplicationError } from "~/errorHandlers"
import {
  SelectionStatus,
  ProjectApplicationSortType,
} from "~/backendApis/viewModel/matching"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface ProjectOption {
  label: string
  value: string
}
export interface StatusOption {
  label: string
  value: SelectionStatus
}
export interface SortTypeOption {
  label: string
  value: ProjectApplicationSortType
}
interface ProjectApplicationFilteringParameterOptionState {
  projectOptions: ProjectOption[]
  statusOptions: StatusOption[]
  sortTypeOptions: SortTypeOption[]
}
const makeDefaultState = () => ({
  projectOptions: [],
  statusOptions: [],
  sortTypeOptions: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProjectApplicationFilteringParameterOptionState = Vue.observable<ProjectApplicationFilteringParameterOptionState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.projectOptions = defaultState.projectOptions
  state.statusOptions = defaultState.statusOptions
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setProjectOptions(projectOptions: ProjectOption[]) {
    state.projectOptions = projectOptions
  },
  setStatusOptions(statusOptions: StatusOption[]) {
    state.statusOptions = statusOptions
  },
  setSortTypeOptions(sortTypeOptions: SortTypeOption[]) {
    state.sortTypeOptions = sortTypeOptions
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get projectOptions(): ProjectOption[] {
    return state.projectOptions
  },
  get statusOptions(): StatusOption[] {
    return state.statusOptions
  },
  get sortTypeOptions(): SortTypeOption[] {
    return state.sortTypeOptions
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * パラメータを読み込む
   */
  loadParameter() {
    if (!clAuth.corporationId) {
      throw new StandardApplicationError(
        "企業アカウントに切り替えられていません。"
      )
    }

    // プロジェクトのオプション
    new BackendApi.CorporationMember.ProjectSearch.GetProjectListReadRequest({
      universalCorporationId: clAuth.corporationIdOrFail,
    })
      .get()
      .then((projects) =>
        projects.list
          .filter((project) => !!project.title)
          .map((project) => ({
            value: project.id,
            label: project.title || "",
          }))
      )
      .then((options) => mutations.setProjectOptions(options))

    mutations.setStatusOptions([
      { label: "選考中", value: "during_selection" },
      { label: "選考終了", value: "end_of_selection" },
    ])

    mutations.setSortTypeOptions([
      {
        label: "応募順",
        value: "project_application_created",
      },
      {
        label: "最終やりとり順",
        value: "latest_message_sent",
      },
    ])
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const projectApplicationFilteringParameterOption = { getters, actions }
