/**
 * Actionの定義
 */
import { buildAccountMetadata } from "./model"
import { mutations } from "./mutations"
import { getters } from "./getters"
import { StandardApplicationError } from "~/errorHandlers"
import { clHelpers } from "~/clHelpers"
import { BackendApi } from "~/backendApis"
import {
  getSwitchedCorporationAccountId,
  resetSwitchedCorporationAccount,
} from "~/uiLogics/corporation/switchedCorporationStateManager"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"
import { logout } from "~/clAuth/logout"
import { auth } from "~/plugins/firebase"

export const actions = {
  /**
   * LocalStorage、IndexedDB（Firebase Authentication）にある情報をもとにStoreの認証情報を再読み込みする。
   * 初回認証時やログイン後にStoreの情報を更新したい場合等に利用する。
   */
  refreshAuthState: async () => {
    // Firebase Authenticationで未認証の場合は、Storeの認証情報もすべてnullで初期化する。
    if (!auth.currentUser) {
      const params = {
        userAccount: null,
        userProfile: null,
        corporationAccount: null,
        contractType: null,
      }
      mutations.initialize(params)
      return params
    }

    clHelpers.debugLogger.print({
      title: "refreshAuthStateにより認証情報初期化処理開始",
      logType: "START",
    })

    /**
     * 切り替え対象のアカウント情報を取得する権限がない場合等にの例外を扱う。
     * ２度目以降も同じエラーが発生しないように、１度目で失敗した場合はアカウント切り替えを解除する。
     * 企業のメンバー管理から削除されたアカウントに切り替え中だった場合が主に想定されるユースケース。
     */
    const handlePermissionExceptionForCorporationResource = (
      e: ApplicationApiError
    ) => {
      resetSwitchedCorporationAccount()

      // 権限の問題であれば想定内なので例外は発生させない。
      if (e.isUnauthorized()) {
        return null
      }
      throw e
    }

    const handlePermissionExceptionForUserResource = (
      e: ApplicationApiError
    ) => {
      // 退会直後等に起こるエラーで想定内なので例外としては処理せず、直後の処理でログアウトさせる。
      if (e.isUnauthorized()) {
        return null
      }
      throw e
    }

    const switchedUniversalCorporationId = getSwitchedCorporationAccountId()
    const [
      userAccount,
      userProfile,
      corporationAccount,
      contractType,
    ] = await Promise.all([
      new BackendApi.User.UserAccount.GetCurrentUserAccountReadRequest()
        .get()
        .catch(handlePermissionExceptionForUserResource),
      new BackendApi.User.UserProfileSearch.GetCurrentUserProfileReadRequest()
        .get()
        .catch(handlePermissionExceptionForUserResource),
      switchedUniversalCorporationId
        ? new BackendApi.CorporationMember.CorporationAccount.GetJoiningCorporationAccountReadRequest(
            { universalCorporationId: switchedUniversalCorporationId }
          )
            .get()
            .catch(handlePermissionExceptionForCorporationResource)
        : null,
      switchedUniversalCorporationId
        ? new BackendApi.CorporationMember.CorporationContract.GetCurrentContractTypeReadRequest(
            { universalCorporationId: switchedUniversalCorporationId }
          ).get()
        : null,
    ])

    if (!userAccount) {
      logout()
      alert(
        "該当するユーザーアカウントが存在しませんでした。お手数ですが、ログインをやり直してください。"
      )
      throw new StandardApplicationError(
        "該当するユーザーアカウントが存在しませんでした。 - ログインやり直し"
      )
    }

    const params = {
      userAccount,
      userProfile,
      corporationAccount,
      contractType,
    }
    mutations.initialize(params)
    return params
  },
  /*
   * アカウントのメタデータを更新する処理。
   * メタデータとして持っている情報を更新した際に実行して、Store上に保持しているメタデータを更新するための処理
   */
  reloadAccountMetadata: async (): Promise<void> => {
    const switchedCorporationId = getters.getCorporationId()

    const [userProfile, corporation] = await Promise.all([
      getters.isLoggedIn()
        ? new BackendApi.User.UserProfileSearch.GetCurrentUserProfileReadRequest().get()
        : null,
      switchedCorporationId
        ? new BackendApi.CorporationMember.CorporationAccount.GetJoiningCorporationAccountReadRequest(
            {
              universalCorporationId: switchedCorporationId,
            }
          ).get()
        : null,
    ])

    const accountMetadata = buildAccountMetadata(userProfile, corporation)
    mutations.setAccountMetadata(accountMetadata)

    clHelpers.debugLogger.print({
      title: "メタデータのリロード完了",
      content: accountMetadata,
      logType: "SUCCESS",
    })
  },
}
