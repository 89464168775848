import Vue, { VNode } from "vue"

Vue.component("Nl2br", {
  functional: true,
  props: {
    tag: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: null,
    },
  },
  render: (createElement, context) => {
    const { tag, text, className } = context.props
    // 文字列を \n で区切った配列を、要素の間に brタグ の要素(VNode)を挟むようにして配列を生成し、
    // 最後にその配列をVNodeにすることで、nl2br（安全に\nのみをHTMLとして利用可能な<br>タグに変更する）処理を再現している。
    return createElement(
      tag,
      { class: className },
      text.split("\n").reduce((allElms: (string | VNode)[], elm: string): (
        | string
        | VNode
      )[] => {
        if (allElms.length === 0) {
          return [elm]
        }
        return allElms.concat([createElement("br"), elm])
      }, [])
    )
  },
})
