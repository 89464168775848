import { MetaInfo } from "vue-meta"

interface ArgType {
  title?: string
  description?: string
  currentPath?: string
  ogpImageUrl?: string
  disableTitleTemplate?: boolean
  noIndex?: boolean
}

const makeMetaWithName = (name: string, content: string) => ({
  hid: name,
  name,
  content,
})

const makeMetaWithProperty = (property: string, content: string) => ({
  hid: property,
  property,
  content,
})

export const metaTag = {
  generate: ({
    title,
    description,
    currentPath,
    ogpImageUrl,
    disableTitleTemplate,
    noIndex,
  }: ArgType): MetaInfo => {
    const head: MetaInfo = {}
    head.meta = []

    if (title) {
      const defaultSuffix = disableTitleTemplate ? "" : "【クラウドリンクス】"
      head.title = title + defaultSuffix
      head.meta.push(makeMetaWithProperty("og:title", title + defaultSuffix))
    }

    if (description) {
      head.meta.push(makeMetaWithName("description", description))
      head.meta.push(makeMetaWithProperty("og:description", description))
    }

    if (currentPath) {
      const url = `${process.env.SERVICE_BASE_URL}${currentPath}`
      head.meta.push(makeMetaWithProperty("og:url", url))
    }

    if (ogpImageUrl) {
      head.meta.push(makeMetaWithProperty("og:image", ogpImageUrl))
    }

    if (noIndex) {
      head.meta.push(makeMetaWithName("robots", "noindex"))
    }

    return head
  },
}
