/**
 * read
 */
import { read } from "./read"
import { GetCurrentUserAccountReadRequest } from "./read/GetCurrentUserAccountReadRequest"

/**
 * write
 */
import { write } from "./write"
import { ResignUserAccountWriteRequest } from "./write/ResignUserAccountWriteRequest"

export const UserAccount = {
  /**
   * read
   */
  read,
  GetCurrentUserAccountReadRequest,

  /**
   * write
   */
  write,
  ResignUserAccountWriteRequest,
}
