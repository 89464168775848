import { ApiClient } from "~/clApi/main/foundation/ApiClient"
import { InformationListView } from "~/clApi/viewModel"

const basePath = "/internal_notification/corporation_member"

export const read = {
  getCorporationInformationList: (params: { universalCorporationId: string }) =>
    new ApiClient<InformationListView>().get(
      `${basePath}/${params.universalCorporationId}/information/list`
    ),
}
