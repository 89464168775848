/**
 * 企業側の公開済みプロジェクト数を管理している
 * 肥大化を防ぐため、プロジェクト一覧のStoreとして定義していない
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { BackendApi } from "~/backendApis"
import { clAuth } from "~/clAuth"
import { ParticularWriteAcl } from "~/config/acl/accessControlList"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface CorporationProjectCountState {
  refreshed: boolean
  countPublishedProjects: number | null
}

const makeDefaultState = () => ({
  refreshed: false,
  countPublishedProjects: null,
})

// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: CorporationProjectCountState = Vue.observable<CorporationProjectCountState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.countPublishedProjects = defaultState.countPublishedProjects
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setRefreshedFlagUp() {
    state.refreshed = true
  },
  setState(params: { countPublishedProjects: number }) {
    state.countPublishedProjects = params.countPublishedProjects
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get refreshed(): boolean {
    return state.refreshed
  },
  /**
   * 公開済みプロジェクト数（直近１ヶ月）
   */
  get countPublishedProjects(): number {
    return state.countPublishedProjects === null
      ? Infinity
      : state.countPublishedProjects
  },
  get isExceedLimitProjectCount(): boolean {
    if (!clAuth.limitCount(ParticularWriteAcl.PROJECT_CREATE)) {
      return false
    }

    return (
      clAuth.limitCount(ParticularWriteAcl.PROJECT_CREATE)! <=
      this.countPublishedProjects
    )
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  async forceRefresh() {
    // 1ヶ月=30日として設定
    const daysOfAggregationPeriod = 30
    const countPublishedProjects = (
      await new BackendApi.CorporationMember.Project.CountPublishedProjectsReadRequest(
        {
          universalCorporationId: clAuth.corporationIdOrFail,
          daysOfAggregationPeriod,
        }
      ).get()
    ).numberOfProjects

    mutations.setState({
      countPublishedProjects,
    })
  },
  /**
   * stateを最新状態に更新
   */
  async refresh() {
    // すでにリフレッシュ済みの場合は何もしない。
    if (state.refreshed) {
      return
    }

    mutations.setRefreshedFlagUp()
    await actions.forceRefresh()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const corporationProjectCount = { getters, actions }
