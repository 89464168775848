

















import Vue from "vue"
import SimpleModal from "~/components/guideline/modal/SimpleModal.vue"
import ConversionButton from "~/components/guideline/button/ConversionButton.vue"

export const emitEventWhenClickReloadButton = "click-reload-button"

export default Vue.extend({
  components: {
    SimpleModal,
    ConversionButton,
  },
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClickReloadButton() {
      this.$emit(emitEventWhenClickReloadButton)
    },
  },
})
