/**
 * フラッシュメッセージに関する状態管理をしている。
 * フラッシュメッセージは小コンポーネントから複数階層上の親コンポーネントに描画リクエストをするケースが多いため、
 * emitでの受け渡しではなくグローバルで状態を持ち、操作できるようにしている。
 */
import Vue from "vue"
import { StandardApplicationError } from "~/errorHandlers"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export const flashSuccess = "success"
export const flashError = "error"
export type FlashMessageType = typeof flashSuccess | typeof flashError
export interface FlashMessageState {
  message: string
  type: FlashMessageType | null
}
const makeDefaultState = () => ({
  message: "",
  type: null,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: FlashMessageState = Vue.observable<FlashMessageState>(
  makeDefaultState()
)

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  set(message: string, type: FlashMessageType): void {
    state.message = message
    state.type = type
  },
  reset(): void {
    state.message = ""
    state.type = null
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get message(): string {
    return state.message
  },
  get isSuccess(): boolean {
    return state.type === flashSuccess
  },
  get isError(): boolean {
    return state.type === flashError
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const isValidMessage = (message: string): boolean =>
  message.length > 0 && message.length < 100

const actions = {
  drawFlashSuccess(message: string) {
    if (!isValidMessage(message)) {
      throw new StandardApplicationError(
        "リクエストされたフラッシュメッセージの形式が不正です。"
      )
    }
    mutations.set(message, flashSuccess)
  },
  drawFlashError(message: string) {
    if (!isValidMessage(message)) {
      throw new StandardApplicationError(
        "リクエストされたフラッシュメッセージの形式が不正です。"
      )
    }
    mutations.set(message, flashError)
  },
  initialize() {
    mutations.reset()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const flashMessage = { getters, actions }
