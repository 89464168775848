/**
 * read
 */
import { CanContractFreeTrialReadRequest } from "./read/CanContractFreeTrialReadRequest"
import { GetAppliablePaidPlanByIdReadRequest } from "./read/GetAppliablePaidPlanByIdReadRequest"
import { GetAppliablePaidPlanListReadRequest } from "./read/GetAppliablePaidPlanListReadRequest"
import { GetContractedPaidPlanReadRequest } from "./read/GetContractedPaidPlanReadRequest"
import { GetCurrentBillingDestinationReadRequest } from "./read/GetCurrentBillingDestinationReadRequest"
import { GetCurrentContractTypeReadRequest } from "./read/GetCurrentContractTypeReadRequest"
import { GetCurrentFreeTrialContractReadRequest } from "./read/GetCurrentFreeTrialContractReadRequest"
import { GetCurrentPaidPlanContractReadRequest } from "./read/GetCurrentPaidPlanContractReadRequest"
import { GetRequestingContractApplicationReadRequest } from "./read/GetRequestingContractApplicationReadRequest"

/**
 * write
 */
import { ApplyForPaidPlanContractWriteRequest } from "./write/ApplyForPaidPlanContractWriteRequest"
import { ChangeBillingDestinationWriteRequest } from "./write/ChangeBillingDestinationWriteRequest"
import { ContractFreeTrialWriteRequest } from "./write/ContractFreeTrialWriteRequest"

export const CorporationContract = {
  /**
   * read
   */
  CanContractFreeTrialReadRequest,
  GetAppliablePaidPlanByIdReadRequest,
  GetAppliablePaidPlanListReadRequest,
  GetContractedPaidPlanReadRequest,
  GetCurrentBillingDestinationReadRequest,
  GetCurrentContractTypeReadRequest,
  GetCurrentFreeTrialContractReadRequest,
  GetCurrentPaidPlanContractReadRequest,
  GetRequestingContractApplicationReadRequest,

  /**
   * write
   */
  ApplyForPaidPlanContractWriteRequest,
  ChangeBillingDestinationWriteRequest,
  ContractFreeTrialWriteRequest,
}
