

















import Vue, { PropType } from "vue"

export const floatingAlertSuccessType = "floatingAlertSuccessType"
export const floatingAlertErrorType = "floatingAlertErrorType"
type AlertType = typeof floatingAlertSuccessType | typeof floatingAlertErrorType

export const emitEventWhenClickMessage = "click-message"

export default Vue.extend({
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String as PropType<AlertType>,
      default: null,
    },
  },
  computed: {
    alertTypeClassName(): string | null {
      if (this.type === floatingAlertSuccessType) {
        return "is-success"
      } else if (this.type === floatingAlertErrorType) {
        return "is-error"
      }
      return null
    },
  },
  methods: {
    handleClickMessage() {
      this.$emit(emitEventWhenClickMessage)
    },
  },
})
