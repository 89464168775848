import { ApplicationError } from "./ApplicationError"

/*
 * Firebase認証中にエラーが発生した時に利用するエラークラス。
 */
export class FirebaseAuthFailure extends ApplicationError {
  constructor(message: string) {
    super({
      className: "FirebaseAuthFailure",
      message: `認証中にエラーが発生：\n${message}`,
    })
  }
}
