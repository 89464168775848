import { auth, facebookProvider, googleProvider } from "~/plugins/firebase"

/**
 * Firebase Auth の認証プロバイダ
 */
export type ProviderData = {
  password: boolean
  google: boolean
  facebook: boolean
}

export const getProviderData = (): ProviderData => {
  const providerIds = auth.currentUser?.providerData.map(
    (info) => info?.providerId
  )
  const providerData: ProviderData = {
    password: providerIds?.includes("password") || false,
    google: providerIds?.includes("google.com") || false,
    facebook: providerIds?.includes("facebook.com") || false,
  }
  return providerData
}

export const loginEmail = (email: string, password: string) => {
  setTryingToLoginFlag()
  return auth.signInWithEmailAndPassword(email, password)
}

export const loginGoogle = () => {
  setTryingToLoginFlag()
  return auth.signInWithRedirect(googleProvider)
}

export const loginFacebook = () => {
  setTryingToLoginFlag()
  return auth.signInWithRedirect(facebookProvider)
}

const key = "cl_auth.proof_of_trying_login"
const value = "1"

/*
 * ログイン開始を示すフラグを立てる処理
 */
const setTryingToLoginFlag = (): void => {
  sessionStorage.setItem(key, value)
}

/*
 * ログイン中かどうかを判定する処理
 */
export const isTryingToLogin = (): boolean => {
  return sessionStorage.getItem(key) === value
}

/*
 * ログイン終了を示すフラグを削除する処理
 */
export const finishTryingToLogin = (): void => {
  sessionStorage.removeItem(key)
}
