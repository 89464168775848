import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { CorporationAccountListView } from "~/backendApis/viewModel/corporationAccount"

export class GetMyCorporationAccountListReadRequest extends ReadRequest<CorporationAccountListView> {
  private readonly endpoint: string =
    "/corporation_account/user/corporation_account/my_account_list"

  public get(): Promise<CorporationAccountListView> {
    return this.apiClient.get(this.endpoint)
  }
}
