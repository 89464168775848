// フリーメールアドレスのドメイン
// メールアドレスは以下のサイトを参考にリストアップしている。
// フリーアドレス : https://freesoft-100.com/community/freemail.html
// キャリアアドレス : https://www.gpoint.co.jp/support/domain/
export const freeAddressList = [
  // ここからフリーアドレスドメイン
  "yahoo.co.jp",
  "gmail.com",
  "outlook.jp",
  "outlook.com",
  "hotmail.co.jp",
  "live.jp",
  "zoho.com",
  "protonmail.com",
  "mprotonmail.ch",
  "yandex.com",
  "aol.jp",
  "momo-mail.com",
  "smoug.net",
  "mail.ru",
  "inbox.ru",
  "list.ru",
  "bk.ru",
  "mail.goo.ne.jp",
  "excite.co.jp",
  "infoseek.jp",
  "livedoor.com",
  "tora.zzn.com",
  "hage.zzzn.com",
  "auone.jp",
  "yesyes.jp",
  // ここからキャリアドメイン
  "ezweb.ne.jp",
  "ido.ne.jp",
  "biz.ezweb.ne.jp",
  "augps.ezweb.ne.jp",
  "uqmobile.jp",
  "docomo.ne.jp",
  "mopera.net",
  "dwmail.jp",
  "pdx.ne.jp",
  "wcm.ne.jp",
  "willcom.com",
  "y-mobile.ne.jp",
  "emnet.ne.jp",
  "emobile-s.ne.jp",
  "emobile.ne.jp",
  "ymobile1.ne.jp",
  "ymobile.ne.jp",
  "jp-c.ne.jp",
  "jp-d.ne.jp",
  "jp-h.ne.jp",
  "jp-k.ne.jp",
  "jp-n.ne.jp",
  "jp-q.ne.jp",
  "jp-r.ne.jp",
  "jp-s.ne.jp",
  "jp-t.ne.jp",
  "sky.tkc.ne.jp",
  "sky.tkk.ne.jp",
  "sky.tu-ka.ne.jp",
  "disney.ne.jp",
  "i.softbank.jp",
  "softbank.ne.jp",
  "vodafone.ne.jp",
  // ここからその他
  "icloud.com",
]
