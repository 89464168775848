import { auth, passwordProvider } from "~/plugins/firebase"

export const sendEmailVerification = (): Promise<void> => {
  return auth.currentUser!.sendEmailVerification()
}

export const applyActionCode = (actionCode: string): Promise<void> => {
  return auth.applyActionCode(actionCode)
}

export const getAuthEmail = (): string | null => {
  return auth.currentUser!.email
}

export const isEmailVerified = (): boolean => {
  return auth.currentUser!.emailVerified
}

export const verifyPasswordResetCode = (
  actionCode: string
): Promise<string> => {
  return auth.verifyPasswordResetCode(actionCode)
}

export const confirmPasswordReset = (
  actionCode: string,
  newPassword: string
): Promise<void> => {
  return auth.confirmPasswordReset(actionCode, newPassword)
}

export const checkActionCode = (actionCode: string): Promise<any> => {
  return auth.checkActionCode(actionCode)
}

export const sendPasswordResetEmail = (
  restoredEmail: string
): Promise<void> => {
  return auth.sendPasswordResetEmail(restoredEmail)
}

export const updateEmail = (newEmail: string): Promise<void> => {
  return auth.currentUser!.updateEmail(newEmail)
}

export const updatePassword = (newPassword: string): Promise<void> => {
  return auth.currentUser!.updatePassword(newPassword)
}

export const reauthenticate = (password: string): Promise<any> => {
  const credential = passwordProvider.credential(
    auth.currentUser!.email || "",
    password
  )
  return auth.currentUser!.reauthenticateWithCredential(credential)
}
