import Router from "vue-router"
import {
  CORPORATION_ID_QUERY_KEY,
  getSwitchedCorporationAccountId,
} from "~/uiLogics/corporation/switchedCorporationStateManager"
import { BackendApi } from "~/backendApis"
import {
  FullInfoUserProfileView,
  decorateFullInfoUserProfileView,
  CorporationAccountView,
  CorporationAccountListView,
} from "~/backendApis/viewModel"

export interface SwitchableAccountSource {
  router: Router
}
export interface SwitchableAccount {
  key: string
  imageUrl: string | null
  name: string
  switchEvent: () => void
}

export const makeSwitchableAccountList = async ({
  router,
}: SwitchableAccountSource) => {
  const promise = await Promise.all([
    new BackendApi.User.UserProfileSearch.GetCurrentUserProfileReadRequest().get(),
    new BackendApi.User.CorporationAccount.GetMyCorporationAccountListReadRequest().get(),
  ])
  const myUserProfile: FullInfoUserProfileView | null = promise[0]
  const myCorporationAccountList: CorporationAccountListView | null = promise[1]
  const switchedUniversalCorporationId:
    | string
    | null = getSwitchedCorporationAccountId()

  if (!myUserProfile) {
    throw new Error("myUserProfile is not found.")
  }

  // TODO
  // TypeScript v3.7.3 時点で、Promiseの返り値の型にnullが付加されてしまうバグがあるため、そのための対応として先の処理でnullでないことを保証するための処理を記述している。
  // 修正用のプルリクエストがリリースされ次第削除すること。
  //
  // 問題を指摘しているプルリクエスト - Insufficient type narrowing of return type with Promise.all
  // @see https://github.com/microsoft/TypeScript/issues/35258
  //
  // 本問題の修正用のプルリクエスト - Better typings for Promise.all(), like #31117
  // @see https://github.com/microsoft/TypeScript/pull/33707
  if (!myCorporationAccountList) {
    throw new Error("Invalid statement error.")
  }
  // ここまでコメントごと削除して良い。

  if (switchedUniversalCorporationId) {
    // case of the current user switches a corporation account.
    return [
      makeSwitchableAccountForUserAccount(myUserProfile, router),
      ...myCorporationAccountList.list
        .filter(
          (e) => e.universalCorporationId !== switchedUniversalCorporationId
        )
        .map((e) => makeSwitchableAccountForCorporationAccount(e, router)),
    ]
  } else {
    // case of the current user doesn't switch an account.
    return myCorporationAccountList.list.map((e) =>
      makeSwitchableAccountForCorporationAccount(e, router)
    )
  }
}

const makeSwitchableAccountForCorporationAccount = (
  corporationAccountView: CorporationAccountView,
  router: Router
): SwitchableAccount => ({
  key: corporationAccountView.universalCorporationId,
  imageUrl: corporationAccountView.logoImage
    ? window.$nuxt.$clHelpers.thumbnail.generateURL({
        image: corporationAccountView.logoImage,
        width: 50,
        height: 50,
      })
    : null,
  name: corporationAccountView.name,
  switchEvent: () => {
    router.push({
      path: `/switch_account?${CORPORATION_ID_QUERY_KEY}=${corporationAccountView.universalCorporationId}`,
    })
  },
})

const makeSwitchableAccountForUserAccount = (
  userProfileView: FullInfoUserProfileView,
  router: Router
): SwitchableAccount => ({
  key: userProfileView.universalUid,
  imageUrl:
    userProfileView.image &&
    window.$nuxt.$clHelpers.thumbnail.generateURL({
      image: userProfileView.image,
      width: 50,
      height: 50,
    }),
  name: decorateFullInfoUserProfileView(userProfileView).getFullName(),
  switchEvent: () => {
    router.push({
      path: `/switch_account`,
    })
  },
})
