import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  targetProjectId: string
}

export class AddToFavoriteProjectList extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { targetProjectId } = this.params
    return this.apiClient.post(this.makeEndpoint(), { targetProjectId })
  }

  private makeEndpoint(): string {
    return `/project/user/favorite_project_list/add`
  }
}
