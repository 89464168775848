/**
 * ここで記述している関数は開発環境でデバック用のログをコンソールに出力するための処理。
 * デバック効率を上げるために利用することを目的としているが、あまりに多くの箇所から利用しすぎると逆に見辛くなってしまう。
 * そのため利用前には十分検討すること。
 * 想定利用ケースとしては、認証後の認証情報の出力など。
 */
import consola, { ConsolaLogObject } from "consola"
import dayjs from "dayjs"
import { clHelpers } from "~/clHelpers"
import { StandardApplicationError } from "~/errorHandlers"

/*
 * 出力できるログの種類
 */
type LogType = "INFO" | "WARN" | "ERROR" | "START" | "SUCCESS"

export const debugLogger = {
  /**
   * デバックログを出力する処理
   */
  print(params: { title?: string; content?: any; logType: LogType }): void {
    // ここの判定操作を誤ると本番環境でログが出力されてしまいかねないので、改修する際は十分に注意すること。
    if (!clHelpers.environment.isDev) {
      return
    }

    if (params.title !== undefined) {
      const now = dayjs()
      const time = dayjs().format("HH:mm:ss")
      makeLogPrinter(params.logType)(
        `${makeLogLabel(params.logType)}\n${
          params.title
        }\nCurrent Time - ${time}.${now.millisecond()}`
      )
    }
    if (params.content !== undefined) {
      makeLogPrinter(params.logType)(params.content)
    }
  },
  /**
   * 複数のデバックログをコンソールに出力する処理
   * contentsはどのような形式でも問題ないため、意図的にanyとしている。
   */
  printList(params: {
    title?: string
    contents: Array<any>
    logType: LogType
  }): void {
    if (params.title !== undefined) {
      debugLogger.print({ title: params.title, logType: params.logType })
    }

    params.contents.forEach((content: any) => {
      debugLogger.print({ content, logType: params.logType })
    })
  },
}

/**
 * ログ出力をする関数を生成する処理
 */
const makeLogPrinter = (
  logType: LogType
): ((message: ConsolaLogObject | any, ...args: any[]) => void) => {
  switch (logType) {
    case "INFO":
      return consola.info
    case "WARN":
      return consola.warn
    case "ERROR":
      return consola.error
    case "START":
      return consola.start
    case "SUCCESS":
      return consola.success
    default:
      throw new StandardApplicationError(
        "clHelpersのdebugLoggerで要求されたlogTypeが不正です。"
      )
  }
}

/**
 * ログ出力をする関数を生成する処理
 * 絵文字を入れるようにしているのは、可読性の向上を目的としている。
 * あとは遊び心を入れて、開発をより楽しくなればという思いが込められている。
 */
const makeLogLabel = (logType: LogType): string => {
  const label = "DEBUG LOG TITLE"
  switch (logType) {
    case "INFO":
      return `🦉🦉🦉 ${label}`
    case "WARN":
      return `🐥🐥🐥 ${label}`
    case "ERROR":
      return `🐁🐁🐁 ${label}`
    case "START":
      return `🐳🐳🐳 ${label}`
    case "SUCCESS":
      return `🐉🐉🐉 ${label}`
    default:
      throw new StandardApplicationError(
        "clHelpersのdebugLoggerで要求されたlogTypeが不正です。"
      )
  }
}
