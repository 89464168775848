/**
 * ユーザー側のチャットに関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { ChatGroupView } from "~/backendApis/viewModel"
import { BackendApi } from "~/backendApis"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface UserSideChatState {
  /**
   * 現在のページ数
   */
  currentPage: number
  /**
   * グループ情報を初期化したかどうかを示すフラグ
   */
  isInitializedGroups: boolean
  /**
   * メッセージが送信されたかどうかを示すフラグ
   */
  isSendMessage: boolean
  /**
   * グループのリスト
   */
  groups: ChatGroupView[]
  /**
   * グループ情報を取得中（バックエンドと通信中）かどうかを示すフラグ
   */
  isFetchingGroups: boolean
  /**
   * 次のページが取得可能かどうかを示すフラグ
   */
  canFetchNextPage: boolean
}
const makeDefaultState = () => ({
  currentPage: 1,
  isInitializedGroups: false,
  isSendMessage: false,
  groups: [],
  isFetchingGroups: false,
  canFetchNextPage: true,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: UserSideChatState = Vue.observable<UserSideChatState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.currentPage = defaultState.currentPage
  state.isInitializedGroups = defaultState.isInitializedGroups
  state.isSendMessage = defaultState.isSendMessage
  state.groups = defaultState.groups
  state.isFetchingGroups = defaultState.isFetchingGroups
  state.canFetchNextPage = defaultState.canFetchNextPage
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  initializePageNumber() {
    state.currentPage = 1
  },
  increasePageNumber() {
    state.currentPage += 1
  },
  setUpInitializedGroupsFlag(): void {
    state.isInitializedGroups = true
  },
  setUpSendMessageFlag(): void {
    state.isSendMessage = true
  },
  setDownSendMessageFlag(): void {
    state.isSendMessage = false
  },
  setGroups(groups: ChatGroupView[]): void {
    state.groups = groups
  },
  appendGroups(groups: ChatGroupView[]) {
    // idをキーに重複を除外している。
    const uniqueGroups = [...state.groups, ...groups].reduce(
      (sum: ChatGroupView[], groups: ChatGroupView) => {
        // まだ配列（sum）に追加されていないチャットグループ(ID)の場合のみ配列（sum）に加える。
        if (sum.every((s: ChatGroupView) => s.id !== groups.id)) {
          sum.push(groups)
        }
        return sum
      },
      []
    )
    state.groups = uniqueGroups
  },
  startFetching() {
    state.isFetchingGroups = true
  },
  finishFetching() {
    state.isFetchingGroups = false
  },
  setUpCanFetchNextPageFlag() {
    state.canFetchNextPage = true
  },
  setDownCanFetchNextPageFlag() {
    state.canFetchNextPage = false
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get currentPage(): number {
    return state.currentPage
  },
  get groups(): ChatGroupView[] {
    return state.groups
  },
  get initializedGroups(): boolean {
    return state.isInitializedGroups
  },
  get isSendMessage(): boolean {
    return state.isSendMessage
  },
  get isFetchingGroups(): boolean {
    return state.isFetchingGroups
  },
  get canFetchNextPage(): boolean {
    return state.canFetchNextPage
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * 所属しているチャットグループを取得してstateに保持する。
   */
  async initializeGroups() {
    if (getters.isFetchingGroups) {
      // 現在グループ情報を取得中の場合は何もしない。
      return
    }

    mutations.startFetching() // 取得処理開始

    // 初期化
    mutations.setUpCanFetchNextPageFlag()
    mutations.initializePageNumber()
    mutations.setDownSendMessageFlag()

    const initialPage = 1
    const groupList = await new BackendApi.User.Matching.GetMyChatGroupListReadRequest(
      {
        page: initialPage,
      }
    )
      .get()
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (groupList.numberOfTotalPages <= initialPage) {
      mutations.setDownCanFetchNextPageFlag() // チャットグループが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.setGroups(groupList.list)
    mutations.setUpInitializedGroupsFlag()
  },
  /**
   * 所属している次のページのチャットグループをstateに保持する。
   */
  async fetchNextPage() {
    if (getters.isFetchingGroups || !getters.canFetchNextPage) {
      // 現在グループ情報を取得中、もしくは次のページが存在しない場合は何もしない。
      return
    }

    mutations.startFetching() // 取得処理開始

    const nextPage = getters.currentPage + 1
    const groupList = await new BackendApi.User.Matching.GetMyChatGroupListReadRequest(
      {
        page: nextPage,
      }
    )
      .get()
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (groupList.numberOfTotalPages <= nextPage) {
      mutations.setDownCanFetchNextPageFlag() // チャットグループが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendGroups(groupList.list)
    mutations.increasePageNumber()
  },
  /**
   * メッセージを送信フラグをtrueでstateに保持する。
   */
  setUpSendMessageFlag() {
    mutations.setUpSendMessageFlag()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const userSideChat = { getters, actions }
