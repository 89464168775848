import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { MessageListView } from "~/backendApis/viewModel/matching"

interface Params {
  chatGroupId: string
  oldestMessageId: string | null
}

export class GetPastMessageListReadRequest extends ReadRequest<MessageListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<MessageListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { chatGroupId, oldestMessageId } = this.params
    const query = oldestMessageId ? `?oldestMessageId=${oldestMessageId}` : ""
    return `/matching/user/chat_group/${chatGroupId}/message/past_list${query}`
  }
}
