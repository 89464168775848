import dayjs from "dayjs"
import { ImageView } from "./common"
import { ProjectPaysType, ProjectStatusType } from "./project"
import { priceFormat } from "~/plugins/priceFormatter"

export interface ProjectView {
  id: string
  universalCorporationId: string
  corporationName: string
  corporationLogoImage: ImageView | null
  corporationAddress: string
  corporationWebsiteUrl: string
  status: ProjectStatusType
  possibleOperatingMaxHours: number | null
  possibleOperatingMinHours: number | null
  prefectureId: number | null
  prefectureName: string | null
  publishedDateTime: string | null
  publishedEndDate: string | null
  recruitmentLargeJobCategoryId: number | null
  recruitmentLargeJobCategoryName: string | null
  recruitmentSmallJobCategoryId: number | null
  recruitmentSmallJobCategoryName: string | null
  recruitmentWorkingFormId: number | null
  recruitmentWorkingFormName: string | null
  relatedProductUrl: string | null
  title: string | null
  detail: string | null
  projectPaysType: ProjectPaysType | null
  projectPaysMin: number | null
  projectPaysMax: number | null
  publicationPeriod: number | null
}

export interface ProjectListView {
  list: ProjectView[]
  numberOfTotalPages: number
  numberOfTotalHits: number
}

export const convertStatusToLabel = (status: ProjectStatusType | "copy") =>
  ({
    draft: "下書き",
    published: "公開",
    unpublished: "非公開",
    applications_closed: "募集終了",
    copy: "コピー",
    archived: "削除",
  }[status])

export const convertPaysTypeToLabel = (paysType: ProjectPaysType) =>
  ({
    hourly: "時給",
    monthly: "月固定",
    other: "応相談",
  }[paysType])

export const decorateProjectView = (view: ProjectView) => ({
  isPublishableFromDraft: (): boolean => {
    return (
      view.status === "draft" &&
      !!view.recruitmentWorkingFormId &&
      // フルリモートOK(3)以外の場合は稼働地域が入力されていること
      (view.recruitmentWorkingFormId !== 3 ? !!view.prefectureId : true) &&
      !!view.recruitmentLargeJobCategoryId &&
      !!view.recruitmentSmallJobCategoryId &&
      !!view.title &&
      !!view.detail &&
      !!view.possibleOperatingMaxHours &&
      !!view.possibleOperatingMinHours &&
      !!view.projectPaysType &&
      (view.projectPaysType !== "other"
        ? !!view.projectPaysMin && !!view.projectPaysMax
        : true) &&
      !!view.publicationPeriod
    )
  },
  isOpenForApplication: (): boolean => {
    return view.status === "published"
  },
  isPublishable: (): boolean => {
    return (
      view.status === "unpublished" ||
      decorateProjectView(view).isPublishableFromDraft()
    )
  },
  isUnpublishable: (): boolean => {
    return view.status === "published"
  },
  isClosedApplication: (): boolean => {
    return view.status === "applications_closed"
  },
  isClosableApplication: (): boolean => {
    return view.status === "published" || view.status === "unpublished"
  },
  isDraft: (): boolean => {
    return view.status === "draft"
  },
  isScoutable: (): boolean => {
    return !!view.title && view.status === "published"
  },
  getMonthDate: (): string => {
    const publishedDateTime = new Date(view.publishedDateTime || "")
    const year = publishedDateTime.getFullYear().toString()
    const month = (publishedDateTime.getMonth() + 1).toString()
    const date = publishedDateTime.getDate().toString()
    return `${year}/${month}/${date}`
  },
  getPublishedLimitDate: (): string => {
    if (view.publishedEndDate === null) {
      return ""
    }

    const publishedEndDate = dayjs(view.publishedEndDate || "")

    const today = new Date()
    const dateNow = dayjs(
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    )
    const result = publishedEndDate.diff(dayjs(dateNow), "day")
    if (result === 0) {
      return "本日まで"
    }
    if (result < 0) {
      return ""
    }
    return `残り ${result + 1}日`
  },
  getPublishedEndDate: (): string => {
    if (view.publishedEndDate === null) {
      return ""
    }

    const endDay = new Date(view.publishedEndDate || "")
    return `${endDay.getFullYear()}/${
      endDay.getMonth() + 1
    }/${endDay.getDate()}`
  },
  getHeaderImageLargeJobCategoryId(): number {
    // 募集職種が選択されていない場合は99
    return view.recruitmentLargeJobCategoryId
      ? view.recruitmentLargeJobCategoryId
      : 99
  },
  getHeaderImagePatternNo(): number {
    if (
      view.publishedDateTime !== null &&
      view.recruitmentLargeJobCategoryId !== null
    ) {
      // プロジェクトの募集職種が設定されており、公開されている場合
      // （募集職種が設定されずに公開されてるプロジェクトがあることを考慮）
      // 公開日時の分をもとにパターン1~3を決定する
      const dateTime = new Date(view.publishedDateTime || "")
      const minutes = dateTime.getMinutes().toString()
      return (Number(minutes) % 3) + 1
    } else {
      // 上の条件以外の場合はパターン1固定
      return 1
    }
  },
  getPublicationPeriodToPublishedEndDate() {
    if (view.publicationPeriod === null) {
      return ""
    }

    return dayjs(new Date())
      .add(view.publicationPeriod - 1, "d")
      .format("YYYY-MM-DD")
  },
  get hasTitle(): boolean {
    return !!view.title
  },
  get hasDetail(): boolean {
    return !!view.detail
  },
  get hasFullFeature(): boolean {
    // ここにその他判定
    return (
      !!view.recruitmentWorkingFormId &&
      !!view.recruitmentLargeJobCategoryId &&
      !!view.recruitmentSmallJobCategoryId &&
      !!view.possibleOperatingMaxHours &&
      !!view.possibleOperatingMinHours &&
      !!view.projectPaysType &&
      (view.projectPaysType !== "other"
        ? !!view.projectPaysMin && !!view.projectPaysMax
        : true)
    )
  },
  get possibleOperatingHoursTag(): string | null {
    if (view.possibleOperatingMaxHours && view.possibleOperatingMinHours) {
      return view.possibleOperatingMinHours === view.possibleOperatingMaxHours
        ? `週${view.possibleOperatingMinHours}時間`
        : `週${view.possibleOperatingMinHours}~${view.possibleOperatingMaxHours}時間`
    }
    return null
  },
  get projectPaysRangeTag(): string | null {
    if (view.projectPaysMin && view.projectPaysMax) {
      return `${convertPaysTypeToLabel(view.projectPaysType!)} ${priceFormat(
        view.projectPaysMin
      )}~${priceFormat(view.projectPaysMax)}円`
    }
    return null
  },
  get statusName(): string {
    return convertStatusToLabel(view.status) || "-"
  },
  featureTexts: (): string[] => {
    const labels = []

    if (view.status === "applications_closed") {
      labels.push("募集終了")
      return labels
    }

    const possibleOperatingHoursTag = decorateProjectView(view)
      .possibleOperatingHoursTag
    if (possibleOperatingHoursTag) {
      labels.push(possibleOperatingHoursTag)
    }
    if (view.recruitmentWorkingFormName) {
      labels.push(view.recruitmentWorkingFormName)
    }
    if (view.prefectureName) {
      labels.push(view.prefectureName)
    }
    const projectPaysRange = decorateProjectView(view).projectPaysRangeTag
    if (projectPaysRange) {
      labels.push(`${projectPaysRange}`)
    }
    return labels
  },
})
