/**
 * プロジェクト応募タブフィルターパラメーターの状態を管理している。
 * 企業アカウント用のStore。
 */
import Vue from "vue"
import {
  SelectionStatus,
  ProjectApplicationSortType,
} from "~/backendApis/viewModel/matching"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface FilteringParameter {
  statusKey: SelectionStatus
  sortType: ProjectApplicationSortType
}

const makeDefaultState = (): FilteringParameter => ({
  statusKey: "during_selection",
  sortType: "project_application_created",
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: FilteringParameter = Vue.observable<FilteringParameter>(
  makeDefaultState()
)

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */

const mutations = {
  setFilteringParameter(params: FilteringParameter) {
    state.statusKey = params.statusKey
    state.sortType = params.sortType
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get filteringParameter(): FilteringParameter {
    return state
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  updateFilteringParameter(parameter: FilteringParameter) {
    mutations.setFilteringParameter(parameter)
  },
  resetFilteringParameter() {
    mutations.setFilteringParameter({
      statusKey: "during_selection",
      sortType: "project_application_created",
    })
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const projectApplicationFilteringParameterForSingleProject = {
  getters,
  actions,
}
