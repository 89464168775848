import { UserProfileSearchResultView } from "../../../../viewModel/userProfileSearch"
import ApplicationApiError from "../../../../foundation/ApplicationApiError"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"

interface Params {
  universalUid: string
  isRequiredLatest?: true // whether to fetch the profile from original data or search index.
}

export class GetUserProfileReadRequest extends ReadRequest<UserProfileSearchResultView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<UserProfileSearchResultView | null> {
    return this.apiClient
      .get(this.makeEndpoint())
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      })
  }

  private makeEndpoint(): string {
    const { universalUid, isRequiredLatest } = this.params
    const query = isRequiredLatest
      ? `?isRequiredLatest=${isRequiredLatest}`
      : ""
    return `/user_profile_search/user/user_profile/show/${universalUid}${query}`
  }
}
