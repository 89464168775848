import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  paidPlanId: string
  billingDestination: {
    address: string
    corporationName: string
    email: string
    phoneNumber: string
    representativeDepartmentName: string
    representativeDirector: string
    representativePerson: string
  }
  informationForSupport: {
    billingMethodId: number
    invoiceArrivalDueDate: string
    isAppliedSupportOption: boolean
  }
}

export class ApplyForPaidPlanContractWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const {
      paidPlanId,
      billingDestination,
      informationForSupport,
    } = this.params
    return this.apiClient.post(this.makeEndpoint(), {
      paidPlanId,
      billingDestination,
      informationForSupport,
    })
  }

  private makeEndpoint(): string {
    return `/corporation_contract/corporation_member/${this.params.universalCorporationId}/contract_application/create`
  }
}
