import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  chatGroupId: string
  fileName: string
  filePath: string
}

export class SendFileMessageWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { fileName, filePath } = this.params
    return this.apiClient.post(this.makeEndpoint(), { fileName, filePath })
  }

  private makeEndpoint(): string {
    const { universalCorporationId, chatGroupId } = this.params
    return `/matching/corporation_member/${universalCorporationId}/chat_group/${chatGroupId}/file/create`
  }
}
