/**
 * 初回登録フローの状態管理をしている。
 * フォーム全体の順番も管理しており、このStoreで前後のフォームのパス等も判断できるようにしていることで、
 * 順番に変更が生じても各フォームは変更する必要がないようにしている。
 */
import Vue from "vue"
import { FormType, formPages, FormPage } from "./pageStepConfig"
import { BackendApi } from "~/backendApis"
import { StandardApplicationError } from "~/errorHandlers"
import { FullInfoUserProfileView } from "~/backendApis/viewModel"
import { consumeRefererPath } from "~/uiLogics/webStorage/profileCreationReferer"
import { clAuth } from "~/clAuth"
export { FormType } from "./pageStepConfig"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface ProfileInitialCreationFlowState {
  currentUserProfile: FullInfoUserProfileView | null
  currentFormType: FormType | null
}
const makeDefaultState = (): ProfileInitialCreationFlowState => ({
  currentUserProfile: null,
  currentFormType: null,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProfileInitialCreationFlowState = Vue.observable<ProfileInitialCreationFlowState>(
  makeDefaultState()
)

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  updateUserProfile(fullInfoUserProfileView: FullInfoUserProfileView) {
    state.currentUserProfile = fullInfoUserProfileView
  },
  updateFormType(formType: FormType) {
    state.currentFormType = formType
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getPageByStep = (step: number) => {
  const firstPage = formPages.find((p) => p.step === step)
  if (!firstPage) {
    throw new StandardApplicationError(
      `step${step}のページ情報が見つかりません。`
    )
  }
  return firstPage
}
const getters = {
  get currentUserProfile(): FullInfoUserProfileView | null {
    return state.currentUserProfile
  },
  isFirstForm: (currentFormType: FormType) =>
    currentFormType === getters.firstPage.type,
  isLastForm: (currentFormType: FormType) =>
    currentFormType === getters.lastPage.type,
  get currentUesrProfile(): FullInfoUserProfileView | null {
    return state.currentUserProfile
  },
  get prevPagePath(): string {
    return !state.currentFormType ||
      state.currentFormType === getters.firstPage.type
      ? ""
      : getPageByStep(getters.currentPageStep - 1).path
  },
  get nextPagePath(): string {
    if (!state.currentFormType) {
      return ""
    } else if (state.currentFormType !== getters.lastPage.type) {
      return getPageByStep(getters.currentPageStep + 1).path
    }

    // 最後のページだけフォームではなく、マイページかフォームに遷移する前のページのパスが返される。
    const returnPath: string | null = consumeRefererPath()
    if (!returnPath) {
      return `/profiles/${clAuth.userIdOrFail}`
    } else {
      return returnPath
    }
  },
  get currentPageTitle(): string {
    return formPages.find((p) => p.type === state.currentFormType)?.title || ""
  },
  get currentPageStep(): number {
    return formPages.find((p) => p.type === state.currentFormType)?.step || 0
  },
  get currentPageSkipable(): boolean {
    return (
      formPages.find((p) => p.type === state.currentFormType)?.isSkip || false
    )
  },
  get firstPage(): FormPage {
    const firstPageStep = 1
    return getPageByStep(firstPageStep)
  },
  get lastPage(): FormPage {
    const maxStep = Math.max(...formPages.map((p) => p.step))
    return getPageByStep(maxStep)
  },
  get numOfTotalStep(): number {
    return formPages.length
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * ページ間を跨いで保持する現在のプロフィール情報を更新する。
   * すでに登録済みの項目でも速やかにフォームに表示できるように、ページを跨いで現在のプロフィールを保持し、遷移後すぐに情報を表示できるようにしている。
   * storeで保持していないと、ページ遷移後ローディングしてからでないとフォームを表示できないなどのUX的にいまいちな状態になる。
   */
  async updateUserProfile() {
    const userProfile = await new BackendApi.User.UserProfileSearch.GetCurrentUserProfileReadRequest().get()

    if (!userProfile) {
      throw new StandardApplicationError(
        "初回登録フロー表示時にログイン中ユーザーのプロフィールが存在しません。"
      )
    }

    mutations.updateUserProfile(userProfile)
  },
  updateFormType(formType: FormType) {
    mutations.updateFormType(formType)
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const profileInitialCreationFlow = { getters, actions }
