import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ChatGroupView } from "~/backendApis/viewModel/matching"

interface Params {
  universalCorporationId: string
}

export class GetChatGroupByJoiningSetReadRequest extends ReadRequest<ChatGroupView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ChatGroupView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/matching/user/chat_group/show_by_joining_set?universalCorporationId=${this.params.universalCorporationId}`
  }
}
