


























import Vue from "vue"
import SimpleModal from "~/components/guideline/modal/SimpleModal.vue"
import PAvailableUptimeSelector, {
  Option,
} from "~/components/custom/availableUptimeUpdateModal/local/PAvailableUptimeSelector.vue"
import ProgressCircular from "~/components/guideline/loading/ProgressCircular.vue"
import ConversionButton from "~/components/guideline/button/ConversionButton.vue"
import { BackendApi } from "~/backendApis"
import {
  FullInfoUserProfileView,
  decorateFullInfoUserProfileView,
} from "~/backendApis/viewModel"
import { StandardApplicationError } from "~/errorHandlers"
import { UserRole } from "~/config/acl/userRoleList"

interface DataType {
  isShown: boolean
  selectedAvailableUptimeId: number | null
  availableUptimeOptions: Option[]
}

export default Vue.extend({
  components: {
    SimpleModal,
    PAvailableUptimeSelector,
    ProgressCircular,
    ConversionButton,
  },
  data(): DataType {
    return {
      isShown: false,
      selectedAvailableUptimeId: null,
      availableUptimeOptions: [],
    }
  },
  computed: {
    currentUserProfile(): FullInfoUserProfileView | null {
      return this.$clAuth.userProfile
    },
  },
  watch: {
    "$clAuth.loggedIn": {
      handler() {
        if (this.$clAuth.loggedIn) {
          this.initializeModalState()
        }
      },
      immediate: true,
    },
  },
  methods: {
    initializeModalState() {
      // ユーザーのロールがcorporationだったら表示しない
      if (this.$clAuth.userRole === UserRole.CORPORATION) {
        return
      }

      // ワークスタイルが30日以内に更新された場合、モーダルを表示しない
      if (
        !this.currentUserProfile ||
        decorateFullInfoUserProfileView(
          this.currentUserProfile
        ).isWorkStyleChangedWithinLastThirtyDays()
      ) {
        return
      }

      // 稼働時間が入力されていない場合、週3を選択した状態にする
      this.selectedAvailableUptimeId =
        this.currentUserProfile.availableUptimeId ?? 3
      this.initializeAvailableUptimeOptions()
      this.isShown = true
    },
    initializeAvailableUptimeOptions() {
      BackendApi.general.userProfile.read.getAvailableUptimeList().then(
        (availableUptimeList) =>
          (this.availableUptimeOptions = availableUptimeList.list.map(
            (elm) => ({
              value: elm.id,
              label: elm.name,
            })
          ))
      )
    },
    updateAvailableUptime() {
      if (!this.selectedAvailableUptimeId) {
        throw new StandardApplicationError("選択中の稼働時間の値が不正です")
      }

      BackendApi.User.UserProfile.write
        .changeAvailableUptime({
          availableUptimeId: this.selectedAvailableUptimeId,
        })
        .then(() => this.$clAuth.refreshAuthState())

      this.isShown = false
    },
  },
})
