import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { ProjectFavoritedUserListView } from "~/backendApis/viewModel"

const basePath = "/user_profile/corporation_member"

export const read = {
  getProjectFavoritedUserList: ({
    universalCorporationId,
    projectId,
    page,
  }: {
    universalCorporationId: string
    projectId: string
    page: number
  }) =>
    new ApiClient<ProjectFavoritedUserListView>().get(
      `${basePath}/${universalCorporationId}/project_favorite_user/${projectId}/list?page=${page}`
    ),
}
