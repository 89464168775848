/**
 * プロジェクト検索に関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { BackendApi } from "~/backendApis"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface JobCategoryOption {
  label: string
  value: number
}
export interface WorkingFormOption {
  label: string
  value: number
}
export interface SearchSortOption {
  label: string
  value: boolean
}
interface ProjectSearchParameterOptionState {
  jobCategoryOptions: JobCategoryOption[]
  possibleOperatingHourOptions: number[]
  workingFormOptions: WorkingFormOption[]
  searchSortOptions: SearchSortOption[]
}
const makeDefaultState = () => ({
  jobCategoryOptions: [],
  possibleOperatingHourOptions: [],
  workingFormOptions: [],
  searchSortOptions: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProjectSearchParameterOptionState = Vue.observable<ProjectSearchParameterOptionState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.jobCategoryOptions = defaultState.jobCategoryOptions
  state.possibleOperatingHourOptions = defaultState.possibleOperatingHourOptions
  state.workingFormOptions = defaultState.workingFormOptions
  state.searchSortOptions = defaultState.searchSortOptions
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setJobCategoryOptions(jobCategoryOptions: JobCategoryOption[]) {
    state.jobCategoryOptions = jobCategoryOptions
  },
  setPossibleOperatingHourOptions(hourOptions: number[]) {
    state.possibleOperatingHourOptions = hourOptions
  },
  setWorkingFormOptions(workingFormOptions: WorkingFormOption[]) {
    state.workingFormOptions = workingFormOptions
  },
  setSearchSortOptions(searchSortOptions: SearchSortOption[]) {
    state.searchSortOptions = searchSortOptions
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get jobCategoryOptions(): JobCategoryOption[] {
    return state.jobCategoryOptions
  },
  get possibleOperatingHourOptions(): number[] {
    return state.possibleOperatingHourOptions
  },
  get workingFormOptions(): WorkingFormOption[] {
    return state.workingFormOptions
  },
  get searchSortOptions(): SearchSortOption[] {
    return state.searchSortOptions
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * パラメータを読み込む
   */
  loadParameter() {
    // 職種のオプション
    BackendApi.general.shared.read
      .getJobCategoryList()
      .then((jobCategories) => {
        const options = jobCategories.map((elm) => ({
          label: elm.name,
          value: elm.id,
        }))
        mutations.setJobCategoryOptions(options)
      })

    // 稼働時間のオプション
    mutations.setPossibleOperatingHourOptions([1, 5, 10, 20, 30, 40])

    // リモート可否のオプション
    BackendApi.general.project.read
      .getRecruitmentWorkingFormList()
      .then((workingFormList) => {
        const options = workingFormList.list.map((elm) => ({
          label: elm.name,
          value: elm.id,
        }))
        mutations.setWorkingFormOptions(options)
      })

    // 並び替えのオプション
    mutations.setSearchSortOptions([
      {
        label: "おすすめ順",
        value: true,
      },
      {
        label: "新着順",
        value: false,
      },
    ])
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const projectSearchParameterOption = { getters, actions }
