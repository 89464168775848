import { debugLogger } from "~/clHelpers/debugLogger"
import { emailAddress } from "~/clHelpers/emailAddress"
import { environment } from "~/clHelpers/environment"
import { errorNotifier } from "~/clHelpers/errorNotifier"
import { metaTag } from "~/clHelpers/metaTag"
import { thumbnail } from "~/clHelpers/thumbnail"

/**
 * Vueインスタンスからグローバルでアクセスできるプロパティの定義。（プリフィックスの cl はCrowdLinksの略。）
 * この値を plugins/dependencyInjection でVueインスタンスにプロパティとして定義し、グローバルで利用できるようにしている。
 *
 * **注意**
 * 新しいプロパティを追加したい場合は、
 * 追加する前に必ずチームのエンジニアメンバーと本当に追加する必要があるのか、他の方法がないか十分に検討すること。
 * グローバルアクセス可能なプロパティ追加の是非の判断は、１人で行うにはとても荷が重すぎる。
 */
export const clHelpers = {
  debugLogger,
  emailAddress,
  environment,
  errorNotifier,
  metaTag,
  thumbnail,
}

export type CLHelpersSchema = typeof clHelpers
