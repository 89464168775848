/**
 * read
 */
import { GetMyContactInformationReadRequest } from "./read/GetMyContactInformationReadRequest"
import { GetMyUserMailNotificationReadRequest } from "./read/GetMyUserMailNotificationReadRequest"
import { GetMailSubscribeSettingReadRequest } from "./read/GetMailSubscribeSettingReadRequest"

/**
 * write
 */
import { AcceptUserMailNotificationWriteRequest } from "./write/AcceptUserMailNotificationWriteRequest"
import { ChangeContactEmailWriteRequest } from "./write/ChangeContactEmailWriteRequest"
import { ChangePhoneNumberWriteRequest } from "./write/ChangePhoneNumberWriteRequest"
import { CreateContactInformationWriteRequest } from "./write/CreateContactInformationWriteRequest"
import { RejectUserMailNotificationWriteRequest } from "./write/RejectUserMailNotificationWriteRequest"
import { AddSuppressionMailSubscribeSettingWriteRequest } from "./write/AddSuppressionMailSubscribeSettingWriteRequest"
import { RemoveSuppressionMailSubscribeSettingWriteRequest } from "./write/RemoveSuppressionMailSubscribeSettingWriteRequest"

export const ExternalNotification = {
  /**
   * read
   */
  GetMyContactInformationReadRequest,
  GetMyUserMailNotificationReadRequest,
  GetMailSubscribeSettingReadRequest,

  /**
   * write
   */
  AcceptUserMailNotificationWriteRequest,
  ChangeContactEmailWriteRequest,
  ChangePhoneNumberWriteRequest,
  CreateContactInformationWriteRequest,
  RejectUserMailNotificationWriteRequest,
  AddSuppressionMailSubscribeSettingWriteRequest,
  RemoveSuppressionMailSubscribeSettingWriteRequest,
}
