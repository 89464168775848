import { ImageView } from "./common"
export interface CorporationAccountView {
  universalCorporationId: string
  address: string
  logoImagePath: string | null
  logoImage: ImageView | null
  name: string
  websiteUrl: string
}

export interface FullCorporationAccountView {
  universalCorporationId: string
  address: string
  logoImagePath: string | null
  logoImage: ImageView | null
  name: string
  websiteUrl: string
  email: string
  phoneNumber: string
}

export interface CorporationAccountListView {
  list: CorporationAccountView[]
}

export const decorateCorporationAccountListView = (
  view: CorporationAccountListView
) => ({
  findCorporationNameByIdOrEmpty: (universalCorporationId: string): string => {
    const foundCorporationAccountView = view.list.find(
      (accountView) =>
        accountView.universalCorporationId === universalCorporationId
    )

    return foundCorporationAccountView ? foundCorporationAccountView.name : ""
  },
})

export interface CorporationMemberInvitationView {
  id: string
  universalUidOfInvitee: string
  universalCorporationIdOfInviter: string
  corporationNameOfInviter: string
  corporationLogoImageOfInviter: string | null
  invitedDatetime: string
}

export interface CorporationMemberInvitationListView {
  list: CorporationMemberInvitationView[]
}

export interface CorporationMemberView {
  fullName: string
  image: ImageView | null
  imagePath: string | null
  userUid: string
}

export interface CorporationMemberListView {
  list: CorporationMemberView[]
}

export interface CorporationAccountCumulativeStatsView {
  numberOfTotalCorporationAccount: number
  numberOfTotalCorporationFreeMember: number
  numberOfTotalCorporationPaidMember: number
  numberOfTotalInvitation: {
    approved: number
    rejected: number
    wait: number
  }
}
