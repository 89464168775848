import { write } from "./write"
import { read } from "./read"

export const userProfile = {
  /** read */
  read,

  /** write */
  write,
}
