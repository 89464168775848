






import Vue, { PropType } from "vue"
import ProgressCircular from "~/components/guideline/loading/ProgressCircular.vue"

export const TRANSPARENT_BACKGROUND = "transparent"
export const TRANSLUCENT_BACKGROUND = "translucent"
export const WHITE_BACKGROUND = "white"
type BackgroundType =
  | typeof TRANSPARENT_BACKGROUND
  | typeof TRANSLUCENT_BACKGROUND
  | typeof WHITE_BACKGROUND

export default Vue.extend({
  components: {
    ProgressCircular,
  },
  props: {
    backgroundType: {
      type: String as PropType<BackgroundType>,
      default: TRANSLUCENT_BACKGROUND,
    },
  },
})
