import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ChatGroupListView } from "~/backendApis/viewModel/matching"

interface Params {
  universalCorporationId: string
  page: number
}

export class GetMyChatGroupListReadRequest extends ReadRequest<ChatGroupListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ChatGroupListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/matching/corporation_member/${this.params.universalCorporationId}/chat_group/list?page=${this.params.page}`
  }
}
