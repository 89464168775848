/**
 * スカウトのフィルタリングに利用するパラメーターに関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { clAuth } from "~/clAuth"
import { StandardApplicationError } from "~/errorHandlers"
import {
  SelectionStatus,
  ScoutSortType,
} from "~/backendApis/viewModel/matching"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface StatusOption {
  label: string
  value: SelectionStatus
}
export interface SortTypeOption {
  label: string
  value: ScoutSortType
}
interface ScoutFilteringParameterOptionState {
  statusOptions: StatusOption[]
  sortTypeOptions: SortTypeOption[]
}
const makeDefaultState = () => ({
  statusOptions: [],
  sortTypeOptions: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ScoutFilteringParameterOptionState = Vue.observable<ScoutFilteringParameterOptionState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.statusOptions = defaultState.statusOptions
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setStatusOptions(statusOptions: StatusOption[]) {
    state.statusOptions = statusOptions
  },
  setSortTypeOptions(sortTypeOptions: SortTypeOption[]) {
    state.sortTypeOptions = sortTypeOptions
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get statusOptions(): StatusOption[] {
    return state.statusOptions
  },
  get sortTypeOptions(): SortTypeOption[] {
    return state.sortTypeOptions
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * パラメータを読み込む
   */
  loadParameter() {
    if (!clAuth.corporationId) {
      throw new StandardApplicationError(
        "企業アカウントに切り替えられていません。"
      )
    }

    mutations.setStatusOptions([
      { label: "選考中", value: "during_selection" },
      { label: "選考終了", value: "end_of_selection" },
    ])

    mutations.setSortTypeOptions([
      { label: "スカウト順", value: "scout_created" },
      { label: "最終やりとり順", value: "latest_message_sent" },
    ])
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const scoutFilteringParameterOption = { getters, actions }
