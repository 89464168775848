import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  selectionId: string
  isSendMessage?: boolean
}

export class FinishSelectionWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { isSendMessage } = this.params
    return this.apiClient.post(this.makeEndpoint(), {
      isSendMessage,
    })
  }

  private makeEndpoint(): string {
    return `/matching/corporation_member/${this.params.universalCorporationId}/selection/${this.params.selectionId}/finish`
  }
}
