import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  projectId: string
  applicationMessage: string
}

export class ApplyForProjectWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/matching/user/project_application/create"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
