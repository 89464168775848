/**
 * モバイルのナビゲーションメニューに関する状態管理をしている。
 * クラウドリンクスでは、モバイルデザインにアプリLIKEなナビゲーションデザインを採用しており、メニューが並ぶ状態や前のページの戻るアイコンとタイトルだけを置くパターンなど、
 * ページによって状態が変化する。
 * それらの状態を各ページから簡単に操作できるように、ここで操作できるようにしている。
 */
import Vue from "vue"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface VisibleStatus {
  isVisibleHeaderNav: boolean
  isVisibleBottomNav: boolean
}
const defaultVisibleStatus = {
  isVisibleHeaderNav: true,
  isVisibleBottomNav: false,
}
interface HeaderParams {
  previousPagePath?: string
  previousIcon?: string
  pageTitle?: string
}
interface MobileNavigationMenuState {
  /**
   * 表示状態
   */
  visibleStatus: VisibleStatus
  /**
   * Header Navigation Barで利用する値
   */
  headerParams: HeaderParams
}
const makeDefaultState = () => ({
  visibleStatus: defaultVisibleStatus,
  headerParams: {},
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: MobileNavigationMenuState = Vue.observable<MobileNavigationMenuState>(
  makeDefaultState()
)

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  changeVisibleStatus(params: VisibleStatus) {
    state.visibleStatus = params
  },
  changeHeaderParams(parmas: HeaderParams = {}): void {
    state.headerParams = parmas
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get isVisibleHeaderNav(): boolean {
    return state.visibleStatus.isVisibleHeaderNav
  },
  get isVisibleBottomNav(): boolean {
    return state.visibleStatus.isVisibleBottomNav
  },
  get headerTitle(): string {
    return state.headerParams.pageTitle || ""
  },
  get headerPreviousPagePath(): string {
    return state.headerParams.previousPagePath || ""
  },
  get headerPreviousIcon(): string {
    return state.headerParams.previousIcon || "arrow_back"
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  changeState(params: {
    previousPagePath?: string
    previousIcon?: string
    pageTitle?: string
    isVisibleHeaderNav?: boolean
    isVisibleBottomNav?: boolean
  }) {
    mutations.changeVisibleStatus({
      isVisibleHeaderNav:
        params.isVisibleHeaderNav === undefined
          ? defaultVisibleStatus.isVisibleHeaderNav
          : params.isVisibleHeaderNav,
      isVisibleBottomNav:
        params.isVisibleBottomNav === undefined
          ? defaultVisibleStatus.isVisibleBottomNav
          : params.isVisibleBottomNav,
    })

    if (params.isVisibleHeaderNav) {
      // ヘッダーを隠す場合はヘッダーのパラメーターも初期化する。
      mutations.changeHeaderParams()
    } else {
      mutations.changeHeaderParams({
        previousPagePath: params.previousPagePath,
        previousIcon: params.previousIcon,
        pageTitle: params.pageTitle,
      })
    }
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const mobileNavigationMenu = { getters, actions }
