/**
 * テンプレートに関する状態管理をしている。
 * 企業アカウント用のStore。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { clAuth } from "~/clAuth"
import { StandardApplicationError } from "~/errorHandlers"
import {
  TemplateType,
  CorporationTemplateView,
} from "~/clApi/viewModel/template"
import { clApi } from "~/clApi"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface TemplateListState {
  /**
   * 現在の企業ID
   * 異なる企業アカウントのリストが誤って表示されないように利用している。
   */
  currentCorporationId: string | null
  /**
   * テンプレートを取得中(バックエンドと通信中)かどうかを示すフラグ
   */
  isFetching: boolean
  /**
   * 企業のテンプレートのリスト
   */
  corporationTemplates: CorporationTemplateView[]
}

const makeDefaultState = (): TemplateListState => ({
  currentCorporationId: null,
  isFetching: false,
  corporationTemplates: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: TemplateListState = Vue.observable<TemplateListState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.currentCorporationId = defaultState.currentCorporationId
  state.isFetching = defaultState.isFetching
  state.corporationTemplates = defaultState.corporationTemplates
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
enum AppendType {
  TO_HEAD = "TO_HEAD", // 先頭に追加
  TO_TAIL = "TO_TAIL", // 末尾に追加
}
const mutations = {
  updateCorporationId(corporationId: string) {
    state.currentCorporationId = corporationId
  },
  resetCorporationTemplateList() {
    state.corporationTemplates = []
  },
  appendCorporationTemplates(
    corporationTemplates: CorporationTemplateView[],
    toTail: AppendType = AppendType.TO_TAIL
  ) {
    const newList =
      toTail === AppendType.TO_TAIL
        ? [...state.corporationTemplates, ...corporationTemplates]
        : [...corporationTemplates, ...state.corporationTemplates]

    // idをキーに重複を除外している。
    const uniqueCorporationTemplates = newList.reduce(
      (
        sum: CorporationTemplateView[],
        corporationTemplates: CorporationTemplateView
      ) => {
        // まだ配列（sum）に追加されていないスカウトのテンプレート(ID)の場合のみ配列（sum）に加える。
        if (
          sum.every(
            (ct: CorporationTemplateView) => ct.id !== corporationTemplates.id
          )
        ) {
          sum.push(corporationTemplates)
        }
        return sum
      },
      []
    )
    state.corporationTemplates = uniqueCorporationTemplates
  },
  removeFromCorporationTemplateList(templateId: string) {
    state.corporationTemplates = state.corporationTemplates.filter(
      (ct) => ct.id !== templateId
    )
  },
  startFetching() {
    state.isFetching = true
  },
  finishFetching() {
    state.isFetching = false
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get corporationTemplates(): CorporationTemplateView[] {
    return state.corporationTemplates
  },
  get exists(): boolean {
    return state.corporationTemplates.length > 0
  },
  get isFetching(): boolean {
    return state.isFetching
  },
  findCorporationTemplateById(
    templateId: string
  ): CorporationTemplateView | null {
    return state.corporationTemplates.find((st) => st.id === templateId) || null
  },
  requireSameCorporationId(corporationId: string) {
    if (state.currentCorporationId !== corporationId) {
      throw new StandardApplicationError(
        "現在設定されている企業IDと異なるIDでtemplateListを利用しようとしています。"
      )
    }
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * 最新のテンプレート一覧を取得する。
   */
  async updateLatestTemplates(typeId: keyof TemplateType) {
    if (getters.isFetching) {
      // 現在テンプレートを取得中の場合は何もしない。
      return
    }

    mutations.updateCorporationId(clAuth.corporationIdOrFail) // 企業IDを更新

    mutations.startFetching() // 取得処理開始
    mutations.resetCorporationTemplateList() // テンプレート一覧を初期化

    // テンプレートの種別がスカウトの場合
    if (typeId === 1) {
      const corporationTemplates = await clApi.main.corporationMember.template.read
        .getScoutTemplateList({
          universalCorporationId: clAuth.corporationIdOrFail,
        })
        .finally(() => {
          mutations.finishFetching() // 取得処理終了
        })

      mutations.appendCorporationTemplates(
        corporationTemplates.list,
        AppendType.TO_HEAD
      )
    }
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const templateList = { getters, actions }
