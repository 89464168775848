import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ContractApplicationView } from "~/backendApis/viewModel"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

interface Params {
  universalCorporationId: string
}

export class GetRequestingContractApplicationReadRequest extends ReadRequest<ContractApplicationView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ContractApplicationView | null> {
    return this.apiClient
      .get(this.makeEndpoint())
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      })
  }

  private makeEndpoint(): string {
    return `/corporation_contract/corporation_member/${this.params.universalCorporationId}/contract_application/show`
  }
}
