/**
 * read
 */
import { GetUserAccountByEmailReadRequest } from "./read/GetUserAccountByEmailReadRequest"

export const UserAccount = {
  /**
   * read
   */
  GetUserAccountByEmailReadRequest,
}
