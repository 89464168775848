import { ApiClient } from "~/backendApis/foundation/ApiClient"

const basePath = "/project/corporation_member"

export const write = {
  /**
   * @returns 新規作成されたプロジェクトのIdを返す
   */
  createProject: (params: {
    universalCorporationId: string
    projectTitle: string
  }) =>
    new ApiClient<string>().post(
      `${basePath}/${params.universalCorporationId}/project/create`,
      {
        title: params.projectTitle,
      }
    ),
}
