/**
 * read
 */
import { read } from "./read"
import { GetMyCorporationAccountListReadRequest } from "./read/GetMyCorporationAccountListReadRequest"
import { GetMyUndecidedInvitationListReadRequest } from "./read/GetMyUndecidedInvitationListReadRequest"

/**
 * write
 */
import { ApproveCorporationMemberInvitationWriteRequest } from "./write/ApproveCorporationMemberInvitationWriteRequest"
import { CreateCorporationAccountWriteRequest } from "./write/CreateCorporationAccountWriteRequest"
import { RejectCorporationMemberInvitationWriteRequest } from "./write/RejectCorporationMemberInvitationWriteRequest"

export const CorporationAccount = {
  /**
   * read
   */
  read,
  GetMyCorporationAccountListReadRequest,
  GetMyUndecidedInvitationListReadRequest,

  /**
   * write
   */
  ApproveCorporationMemberInvitationWriteRequest,
  CreateCorporationAccountWriteRequest,
  RejectCorporationMemberInvitationWriteRequest,
}
