type StateInitializer = () => void

const stateInitializers: StateInitializer[] = []

/**
 * アカウント切り替え時にstateを初期化するための関数を登録するための処理
 */
export const registerStateInitializerForSwitchingAccount = (
  initializer: StateInitializer
) => {
  stateInitializers.push(initializer)
}

/**
 * アカウント切り替え時にstateを初期化するための関数を実行するための処理
 */
export const initlizeStateWhenSwitchingAccount = () => {
  stateInitializers.forEach((f) => {
    f()
  })
}
