




















import Vue from "vue"
import Overlay from "~/components/guideline/modal/Overlay.vue"

export const emitEventWhenClickOverlay = "click-overlay"

export default Vue.extend({
  components: {
    Overlay,
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    handleClickOverlay() {
      this.$emit(emitEventWhenClickOverlay)
    },
  },
})
