import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ChatGroupView } from "~/backendApis/viewModel/matching"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

interface Params {
  universalUid: string
  universalCorporationId: string
}

export class GetChatGroupByJoiningSetReadRequest extends ReadRequest<ChatGroupView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ChatGroupView | null> {
    return this.apiClient
      .get(this.makeEndpoint())
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      })
  }

  private makeEndpoint(): string {
    return `/matching/corporation_member/${this.params.universalCorporationId}/chat_group/show_by_joining_set?universalUid=${this.params.universalUid}`
  }
}
