import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { CorporationMemberInvitationListView } from "~/backendApis/viewModel"

export class GetMyUndecidedInvitationListReadRequest extends ReadRequest<CorporationMemberInvitationListView> {
  private readonly endpoint: string =
    "/corporation_account/user/my_invitation/undecided_list"

  public get(): Promise<CorporationMemberInvitationListView> {
    return this.apiClient.get(this.endpoint)
  }
}
