import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { FileMessageView } from "~/backendApis/viewModel/matching"

interface Params {
  chatGroupId: string
  messageId: string
}

export class GetFileMessageByIdReadRequest extends ReadRequest<FileMessageView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<FileMessageView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { chatGroupId, messageId } = this.params
    return `/matching/user/chat_group/${chatGroupId}/file/${messageId}`
  }
}
