import dayjs from "dayjs"

export interface ContractApplicationView {
  id: string
  appliedDateTime: string
  billingDestinationAddress: string
  billingDestinationCorporationName: string
  billingDestinationEmail: string
  billingDestinationPhoneNumber: string
  billingDestinationRepresentativeDepartmentName: string
  billingDestinationRepresentativeDirector: string
  billingDestinationRepresentativePerson: string
  billingMethodId: number
  billingMethodName: string
  invoiceArrivalDueDate: string
  isAppliedSupportOption: boolean
  paidPlanId: string
  postedDate: string
  representativeDirector: string
  universalCorporationId: string
  universalUid: string
}

export interface ContractTypeView {
  contractType: "free_trial_contract" | "paid_plan_contract" | null
}

export interface FreeTrialContractableStatus {
  canApply: boolean
}

export interface FreeTrialContractView {
  id: string
  universalCorporationId: string
  status: "active" | "finished"
  startDate: string
}

export interface PaidPlanContractView {
  id: string
  paidPlanId: string
  startDate: string
  status: "paid" | "unpaid" | "canceled"
  universalCorporationId: string
}

export const decoratePaidPlanContractView = (view: PaidPlanContractView) => ({
  formatStartDate: () => dayjs(view.startDate).format("YYYY-MM-DD HH:mm"),
})

export type AppliableStatus = "appliable" | "not_appliable"

export interface PaidPlanView {
  availablePeriod: number
  freePeriod: number
  id: string
  isApplicable: boolean
  monthlyPriceExcludingTax: number
  incentiveFee: number
  initialCost: number
  name: string
  specialNote: string
}

export interface FullPaidPlanView {
  availablePeriod: number
  freePeriod: number
  id: string
  isApplicable: boolean
  monthlyPriceExcludingTax: number
  incentiveFee: number
  initialCost: number
  name: string
  adminMemo: string
  specialNote: string
}

export const decoratePaidPlanView = (view: PaidPlanView) => ({
  calcTotalMonthlyPriceExcludingTaxWithComma: (): string => {
    return (
      view.monthlyPriceExcludingTax * view.availablePeriod
    ).toLocaleString()
  },
  calcTotalMonthlyPriceExcludingTax: (): number => {
    return view.monthlyPriceExcludingTax * view.availablePeriod
  },
  calcTotalCostExcludingTax: (): number => {
    return (
      decoratePaidPlanView(view).calcTotalMonthlyPriceExcludingTax() +
      view.initialCost
    )
  },
  getMonthlyPriceExcludingTaxWithComma: (): string => {
    return view.monthlyPriceExcludingTax.toLocaleString()
  },
})

export interface PaidPlanListView {
  list: PaidPlanView[]
}

export interface FullPaidPlanListView {
  list: FullPaidPlanView[]
}

export interface BillingMethodListView {
  list: {
    id: number
    name: string
  }[]
}

export interface CorporationBillingDestinationView {
  universalCorporationId: string
  address: string
  corporationName: string
  email: string
  phoneNumber: string
  representativeDepartmentName: string
  representativeDirector: string
  representativePerson: string
}
