import { UserProfileSearchResultListView } from "../../../../viewModel/userProfileSearch"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { queryParamsGenerator } from "~/backendApis/helpers/queryParamsGenerator"

interface Params {
  corporationId: string
  searchWord: string | null
  smallJobCategoryIds: number[]
  availableUptimeIds: number[]
  sinceLastAccessDays: number | null
  prefectureIds: number[]
  workingFormId?: number | undefined
  minAge: number | null // 5歳刻みでのみ指定可能
  maxAge: number | null // 5歳刻みでのみ指定可能
  page: number
}

export class SearchUserProfileListForPaidReadRequest extends ReadRequest<UserProfileSearchResultListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<UserProfileSearchResultListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const keys: Array<keyof Params> = [
      "searchWord",
      "smallJobCategoryIds",
      "availableUptimeIds",
      "sinceLastAccessDays",
      "prefectureIds",
      "workingFormId",
      "minAge",
      "maxAge",
      "page",
    ]

    const queryString = queryParamsGenerator.generateSearchQuery<Params>({
      keys,
      params: this.params,
    })

    return `/user_profile_search/paid_corporation_member/${this.params.corporationId}/user_profile/search${queryString}`
  }
}
