import { NormalNavigationItem } from "./type"

/**
 * アカウントに応じたナビゲーションバーの項目を生成する関数。
 */
export const navGenerator = {
  // 未認証ユーザー用
  generateForUnauthed: (): NormalNavigationItem[] => [
    projectNavItem,
    inactiveUserChatNavItem,
  ],
  // ユーザーアカウント用
  generateForUserAccount: (
    batchStatus: {
      hasUnreadMessage: boolean
    },
    userId: string
  ): NormalNavigationItem[] => [
    projectNavItem,
    favoriteProjectNavItem,
    {
      ...activeUserChatNavItem,
      hasBatch: batchStatus.hasUnreadMessage,
    },
    {
      ...myPageNavItem,
      path: `/profiles/${userId}`,
    },
  ],
  // 企業アカウント用
  generateForCorporationAccount: (batchStatus: {
    hasUnreadMessageOfScout: boolean
    hasUnreadMessageOfApplication: boolean
  }): NormalNavigationItem[] => [
    projectNavItem,
    profileNavItem,
    corporationFavoriteUserNavItem,
    {
      ...scoutNavItem,
      hasBatch: batchStatus.hasUnreadMessageOfScout,
    },
    {
      ...projectApplicationNavItem,
      hasBatch: batchStatus.hasUnreadMessageOfApplication,
    },
  ],
}

// プロジェクト一覧
const projectNavItem = {
  defaultIcon: "work_outline",
  activeIcon: "work",
  label: "プロジェクト",
  path: "/projects",
  isClickable: true,
}

// プロフィール一覧
const profileNavItem = {
  defaultIcon: "people_outline",
  activeIcon: "people",
  label: "ユーザー",
  path: "/profiles",
  isClickable: true,
}

// お気に入りプロジェクト
const favoriteProjectNavItem = {
  defaultIcon: "bookmark_border",
  activeIcon: "bookmark",
  label: "お気に入り",
  path: "/projects/favorite",
  isClickable: true,
}

// （ユーザーの）チャットグループ一覧（アクティブ）
const activeUserChatNavItem = {
  defaultIcon: "forum",
  label: "メッセージ",
  path: "/chats",
  isClickable: true,
}

// （ユーザーの）チャットグループ一覧（インアクティブ）
const inactiveUserChatNavItem = {
  defaultIcon: "forum",
  label: "メッセージ",
  path: "/chats",
  isClickable: false,
}

const myPageNavItem = {
  defaultIcon: "person",
  label: "マイページ",
  path: "/profile/",
  isClickable: true,
  isOnlyMobile: true,
}

// お気に入りプロフィール
const corporationFavoriteUserNavItem = {
  defaultIcon: "bookmark_border",
  activeIcon: "bookmark",
  label: "お気に入り",
  path: "/corporation/favorite",
  isClickable: true,
}

// スカウト一覧
const scoutNavItem = {
  defaultIcon: "contact_mail",
  label: "スカウト",
  path: "/corporation/scouts",
  isClickable: true,
}

// 応募一覧
const projectApplicationNavItem = {
  defaultIcon: "work_outline",
  activeIcon: "work",
  label: "応募",
  path: "/corporation/applications",
  isClickable: true,
}
