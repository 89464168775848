/**
 * read
 */
import { GetChatGroupByIdReadRequest } from "./read/GetChatGroupByIdReadRequest"
import { GetChatGroupByJoiningSetReadRequest } from "./read/GetChatGroupByJoiningSetReadRequest"
import { GetLatestMessageListReadRequest } from "./read/GetLatestMessageListReadRequest"
import { GetMyChatGroupListReadRequest } from "./read/GetMyChatGroupListReadRequest"
import { GetNumberOfUnreadMessagesReadRequest } from "./read/GetNumberOfUnreadMessagesReadRequest"
import { GetPastMessageListReadRequest } from "./read/GetPastMessageListReadRequest"
import { GetProjectApplicationReadRequest } from "./read/GetProjectApplicationReadRequest"
import { GetFileMessageByIdReadRequest } from "./read/GetFileMessageByIdReadRequest"

/**
 * write
 */
import { ApplyForProjectWriteRequest } from "./write/ApplyForProjectWriteRequest"
import { SendMessageWriteRequest } from "./write/SendMessageWriteRequest"
import { SendFileMessageWriteRequest } from "./write/SendFileMessageWriteRequest"
import { CancelFileMessageWriteRequest } from "./write/CancelFileMessageWriteRequest"

export const Matching = {
  /**
   * read
   */
  GetChatGroupByIdReadRequest,
  GetChatGroupByJoiningSetReadRequest,
  GetLatestMessageListReadRequest,
  GetMyChatGroupListReadRequest,
  GetNumberOfUnreadMessagesReadRequest,
  GetPastMessageListReadRequest,
  GetProjectApplicationReadRequest,
  GetFileMessageByIdReadRequest,

  /**
   * write
   */
  ApplyForProjectWriteRequest,
  SendMessageWriteRequest,
  SendFileMessageWriteRequest,
  CancelFileMessageWriteRequest,
}
