import dayjs from "dayjs"
import { RewardType } from "./userProfile"
import { ImageView, OutputOGP } from "./common"
import { clStores } from "~/clStores"

export interface UserProfileSearchResultView {
  availableUptimeId: number | null
  availableUptimeName: string | null
  availableHoursPerDay: number | null
  contactableTypeIds: number[] | null
  contactableTypeNames: string[] | null
  capabilities: {
    title: string
    description: string
  }[]
  career: UserProfileExperienceCorporationView[]
  facebookUrl: string | null
  firstName: string
  firstNameKana: string
  githubUrl: string | null
  isLivingAbroad: boolean | null
  lastName: string
  lastNameKana: string
  linkedInUrl: string | null
  mainLargeJobCategoryId: number | null
  mainLargeJobCategoryName: string | null
  mainSmallJobCategoryId: number | null
  mainSmallJobCategoryName: string | null
  outputs: OneOutput[]
  prefectureId: number | null
  prefectureName: string | null
  selfIntroduction: string | null
  skillTagList: SkillTag[]
  subLargeJobCategoryId: number | null
  subLargeJobCategoryName: string | null
  subSmallJobCategoryId: number | null
  subSmallJobCategoryName: string | null
  supplementaryExplanation: string | null
  twitterUrl: string | null
  universalUid: string
  workValue: string | null
  workingFormId: number | null
  workingFormName: string | null
  rewardType: RewardType | null
  minReward: number | null
  maxReward: number | null
  imagePath: string | null
  image: ImageView | null
  publicStatus: "open_to_public" | "open_to_users" | "close"
  lastAccessDateTime: string | null
}

export interface UserProfileSearchResultListView {
  list: UserProfileSearchResultView[]
  numberOfTotalHits: number
  numberOfTotalPages: number
}

export interface NumberOfTotalHitView {
  number: number
}

export interface FavoriteUserListView {
  list: UserProfileSearchResultView[]
}

export interface ProjectFavoritedUserListView {
  list: UserProfileSearchResultView[]
  numberOfTotalHits: number
  numberOfTotalPages: number
}

const getDurationText = (
  startYear: number,
  startMonth: number,
  endYear: number | null,
  endMonth: number | null
) => {
  const endDayjs =
    !endYear && !endMonth ? dayjs() : dayjs(`${endYear}${endMonth}`)
  const startDayjs = dayjs(`${startYear}${startMonth}`)

  const diffInMonth = endDayjs.diff(startDayjs, "month")

  const durationYear = Math.floor(diffInMonth / 12)
  const durationMonth = diffInMonth % 12

  if (durationYear === 0) {
    return `${durationMonth}ヶ月`
  } else if (durationMonth === 0) {
    return `${durationYear}年`
  }

  return `${durationYear}年${durationMonth}ヶ月`
}

// TODO: clApi/viewModel配下に移行する
export const decorateUserProfileExperienceCorporationView = (
  view: UserProfileExperienceCorporationView
) => ({
  getWorkSpanText: (): string => {
    const { startYear, startMonth, endYear, endMonth } = view.workSpan

    const durationText = getDurationText(
      startYear,
      startMonth,
      endYear,
      endMonth
    )

    if (startYear && startMonth && endYear && endMonth) {
      return `${startYear}年${startMonth}月-${endYear}年${endMonth}月 (${durationText})`
    } else if (startYear && startMonth && view.isCurrentCorporation) {
      const now = new Date()
      const year = now.getFullYear()
      const month = now.getMonth() + 1
      return `${startYear}年${startMonth}月-${year}年${month}月現在 (${durationText})`
    }

    return ""
  },
  getSimpleWorkSpanText: (): string => {
    const { startYear, startMonth, endYear, endMonth } = view.workSpan
    if (startYear && startMonth && endYear && endMonth) {
      return `${startYear}/${startMonth} - ${endYear}/${endMonth}`
    } else if (startYear && startMonth && view.isCurrentCorporation) {
      const now = new Date()
      const year = now.getFullYear()
      const month = now.getMonth() + 1
      return `${startYear}/${startMonth} - ${year}/${month} 現在`
    }
    return ""
  },
})

export const decorateUserProfileSearchResultView = (
  view: UserProfileSearchResultView
) => ({
  isSideJobUser: (): boolean => view.workingFormId === 1,
  skillTagTexts: (numberToSlice?: number): string[] => {
    const targetList = view.skillTagList.slice(
      0,
      numberToSlice || view.skillTagList.length
    )
    return targetList.map((elm) => elm.skillText)
  },
  getFullName: (): string => view.lastName + " " + view.firstName, // 日本語に合わせてlastNameを最初にしている。
  getFullNameWithJobName: (): string => {
    const jobName: string = view.mainSmallJobCategoryName || ""
    const decorator = decorateUserProfileSearchResultView(view)
    return jobName
      ? `${decorator.getFullName()} | ${jobName}`
      : `${decorator.getFullName()}`
  },
  getLivingPlaceName: (): string =>
    view.isLivingAbroad ? "海外在住" : view.prefectureName || "",
  getMainFeatureText: (): string => {
    const decorator = decorateUserProfileSearchResultView(view)
    const currentCorporation = decorator.getCurrentCorporation()

    if (!view.mainSmallJobCategoryName) {
      return currentCorporation?.corporationName || ""
    }

    if (decorator.isSideJobUser() && currentCorporation) {
      return `${view.mainSmallJobCategoryName} | ${currentCorporation.corporationName}`
    }

    if (!decorator.isSideJobUser()) {
      return `${view.mainSmallJobCategoryName} | フリーランス`
    }

    return view.mainSmallJobCategoryName
  },
  /**
   * メインで表示する経験企業。
   * プロフィール一覧や詳細の最上部等で表示される企業。
   */
  getMainExperienceCorporation: (): UserProfileExperienceCorporationView | null => {
    // 優先順位１：在籍中の企業
    const currentCorporation = decorateUserProfileSearchResultView(
      view
    ).getCurrentCorporation()
    if (currentCorporation) return currentCorporation

    // 優先順位２：一流企業
    const leadingCompany = view.career.find(
      (corporation) => corporation.isLeadingCompany
    )
    if (leadingCompany) return leadingCompany

    // 優先順位３：最新の企業
    return view.career[0]
  },
  getCurrentCorporation: (): UserProfileExperienceCorporationView | null =>
    view.career.find((c) => c.isCurrentCorporation) || null,
  loadOutputOGPs() {
    view.outputs.forEach((output) => {
      if (output.relatedUrl) {
        clStores.ogp.actions.load(output.relatedUrl)
      }
    })
  },
  getOutputOGPs(): OutputOGP[] {
    return view.outputs
      .map((output) => {
        if (
          !output.relatedUrl ||
          !clStores.ogp.getters.getOGPMetadata(output.relatedUrl)
        ) {
          return undefined
        }

        return {
          siteURL: output.relatedUrl,
          ...clStores.ogp.getters.getOGPMetadata(output.relatedUrl),
        }
      })
      .filter((elm): elm is OutputOGP => !!elm) // filterだけではTypeScriptがnullableと判定してしまうので、致し方なくisで型を補強している。
  },
  getLastAccessStatus(): string | undefined {
    if (!view.lastAccessDateTime) {
      return
    }

    const now = dayjs()
    const lastAccessDateTime = dayjs(view.lastAccessDateTime)
    const diffInDays = now.diff(lastAccessDateTime, "day")
    const suffix = "日以内アクセス"
    if (diffInDays <= 3) {
      return `3${suffix}`
    } else if (diffInDays <= 7) {
      return `7${suffix}`
    } else if (diffInDays <= 14) {
      return `14${suffix}`
    } else if (diffInDays <= 30) {
      return `30${suffix}`
    } else if (diffInDays <= 90) {
      return `90${suffix}`
    } else {
      return "90日以前アクセス"
    }
  },
})

/**
 * This is view model in other view model.
 * TODO: clApi/viewModel配下に移行する
 */
export interface UserProfileExperienceCorporationView {
  corporationName: string
  leadingCompanyId: string | null
  isLeadingCompany: boolean
  joinReason: string | null
  isCurrentCorporation: boolean
  workSpan: {
    startYear: number
    startMonth: number
    endYear: number | null
    endMonth: number | null
  }
  experiencedProjects: UserProfileExperienceProjectView[]
}

export interface UserProfileExperienceProjectView {
  achievedContent: string | null
  name: string
  personnelScaleId: number | null
  personnelScaleName: string | null
  relatedUrl: string | null
  role: string
  workContent: string
}

export interface OneOutput {
  description: string | null
  filePath: string | null
  fileUrl: ImageView | null
  relatedUrl: string | null
}

export interface SkillTag {
  maxYearsOfExperience: number | null
  minYearsOfExperience: number | null
  skillId: string
  skillText: string
}

export interface FullInfoUserProfileView {
  availableUptimeId: number | null
  availableUptimeName: string | null
  availableHoursPerDay: number | null
  contactableTypeIds: number[] | null
  contactableTypeNames: string[] | null
  capabilities: {
    title: string
    description: string
  }[]
  career: UserProfileExperienceCorporationView[]
  facebookUrl: string | null
  firstName: string
  firstNameKana: string
  githubUrl: string | null
  isLivingAbroad: boolean | null
  jobChangeIntentionId: number | null
  jobChangeIntentionName: string | null
  lastName: string
  lastNameKana: string
  linkedInUrl: string | null
  mainLargeJobCategoryId: number | null
  mainLargeJobCategoryName: string | null
  mainSmallJobCategoryId: number | null
  mainSmallJobCategoryName: string | null
  outputs: OneOutput[]
  prefectureId: number | null
  prefectureName: string | null
  selfIntroduction: string | null
  skillTagList: SkillTag[]
  subLargeJobCategoryId: number | null
  subLargeJobCategoryName: string | null
  subSmallJobCategoryId: number | null
  subSmallJobCategoryName: string | null
  supplementaryExplanation: string | null
  twitterUrl: string | null
  universalUid: string
  workValue: string | null
  workingFormId: number | null
  workingFormName: string | null
  rewardType: RewardType | null
  minReward: number | null
  maxReward: number | null
  imagePath: string | null
  image: ImageView | null
  publicStatus: "open_to_public" | "open_to_users" | "close"
  registeredDateTime: string
  lastAccessDateTime: string | null
  workStyleChangedDateTime: string | null
  sexId: string | null
  birthday: string | null
}

export const decorateFullInfoUserProfileView = (
  view: FullInfoUserProfileView
) => ({
  isSideJobUser: (): boolean => view.workingFormId === 1,
  getFullName: (): string => view.lastName + " " + view.firstName, // 日本語に合わせてlastNameを最初にしている。
  isWorkStyleChangedWithinLastThirtyDays: (): boolean => {
    if (!view.workStyleChangedDateTime) {
      return false
    }

    const workStyleChangedDateTime = dayjs(view.workStyleChangedDateTime)
    const diffInDays = dayjs().diff(workStyleChangedDateTime, "day")
    return diffInDays < 30
  },
})

export const convertRewardTypeToLabel = (rewardType: RewardType) =>
  ({
    hourly: "時給",
    monthly: "月固定",
    negotiable: "応相談",
  }[rewardType])

export interface CorporateEvaluationView {
  id: string
  targetCorporationName: string
  score: number
}

export interface CorporateEvaluationListView {
  list: CorporateEvaluationView[]
}

export interface LeadingCompanyView {
  id: string
  name: string
}

export interface LeadingCompanyListView {
  list: LeadingCompanyView[]
}

export interface BulkEmailTargetUserProfileView {
  universalUid: string
}

export interface BulkEmailTargetUserProfileListView {
  list: BulkEmailTargetUserProfileView[]
  numberOfTotalHits: number
  numberOfTotalPages: number
}
