import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  projectId: string
}

export class CopyProjectWriteRequest extends WriteRequest<string> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  /**
   * @returns コピーしたプロジェクトのid
   */
  public post(): Promise<string> {
    const { projectId } = this.params
    return this.apiClient.post(this.makeEndpoint(), { projectId })
  }

  private makeEndpoint(): string {
    const { universalCorporationId } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/copy`
  }
}
