import { AxiosError } from "axios"

export default class ApplicationApiError extends Error {
  private readonly statusCode: number | undefined

  constructor(error: AxiosError) {
    super(ApplicationApiError.makeMessage(error))
    this.statusCode = error.response?.status
  }

  isUnauthorized(): boolean {
    return this.statusCode === 401
  }

  isNotFoundError(): boolean {
    return this.statusCode === 404
  }

  isUnprocessableEntity(): boolean {
    return this.statusCode === 422
  }

  private static makeMessage(error: AxiosError): string {
    return `status code : ${error.response?.status || "-"} , message : ${
      error.message
    }`
  }
}
