import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { ProjectListView, ProjectView } from "~/backendApis/viewModel"

const basePath = "/project/general"

export const read = {
  getProjectListByCorporationId: ({
    universalCorporationId,
    page,
  }: {
    universalCorporationId: string
    page: number
  }) =>
    new ApiClient<ProjectListView>().get(
      `${basePath}/project/${universalCorporationId}/list?page=${page}`
    ),
  getProjectList: (params: {
    page: number
    searchWord: string | null
    workingFormIds: number[]
    recruitmentLargeJobCategoryIds: number[] // large job category id to filter by
    excludeClosed: boolean
    possibleOperatingMinHours: number[]
    orderByScore: boolean | null
  }) => {
    const query = [
      `page=${params.page}`,
      params.searchWord ? `searchWord=${params.searchWord}` : null,
      params.workingFormIds.length > 0
        ? `workingFormIds=${params.workingFormIds.join(",")}`
        : null,
      `excludeClosed=${params.excludeClosed}`,
      params.recruitmentLargeJobCategoryIds.length > 0
        ? `recruitmentLargeJobCategoryIds=${params.recruitmentLargeJobCategoryIds.join(
            ","
          )}`
        : null,
      params.possibleOperatingMinHours.length > 0
        ? `possibleOperatingMinHours=${params.possibleOperatingMinHours.join(
            ","
          )}`
        : null,
      params.orderByScore !== null
        ? `orderByScore=${params.orderByScore}`
        : null,
    ]
      .filter((elm) => elm)
      .join("&")

    return new ApiClient<ProjectListView>().get(
      `${basePath}/project/list?${query}`
    )
  },
  getProject: (params: { projectId: string }) =>
    new ApiClient<ProjectView | null>().get(
      `${basePath}/project/show/${params.projectId}`
    ),
}
