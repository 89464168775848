




















import Vue from "vue"
import CLayoutTemplate from "~/components/custom/frame/CLayoutTemplate.vue"
import ProgressCircular from "~/components/guideline/loading/ProgressCircular.vue"

export default Vue.extend({
  components: {
    CLayoutTemplate,
    ProgressCircular,
  },
  watch: {
    "$clAuth.finishedAuthProcess": {
      handler(isFinishedAuthProcess) {
        if (isFinishedAuthProcess && !this.$clAuth.loggedIn) {
          this.$nuxt.error({ statusCode: 403 })
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.$clAuth.completeAuthProcess()
  },
})
