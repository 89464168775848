/**
 * 企業のお気に入りユーザーに関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { BackendApi } from "~/backendApis"
import { clAuth } from "~/clAuth"
import { StandardApplicationError } from "~/errorHandlers"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface CorporationSideFavoriteUserListState {
  /**
   * 現在の企業ID
   * 異なる企業アカウントのリストが誤って表示されないように利用している。
   */
  currentCorporationId: string | null
  /**
   * 現在の企業がお気に入りリストに追加しているユーザーのリスト
   */
  userIds: string[]
}
const makeDefaultState = () => ({
  currentCorporationId: null,
  userIds: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: CorporationSideFavoriteUserListState = Vue.observable<CorporationSideFavoriteUserListState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.currentCorporationId = defaultState.currentCorporationId
  state.userIds = defaultState.userIds
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  updateCorporationId(corporationId: string) {
    state.currentCorporationId = corporationId
  },
  setFavoriteUserIds(userIds: string[]): void {
    state.userIds = userIds
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get idList(): string[] {
    return state.userIds
  },
  isFavorited: (userId: string): boolean => state.userIds.includes(userId),
  requireSameCorporationId(corporationId: string) {
    if (state.currentCorporationId !== corporationId) {
      throw new StandardApplicationError(
        "現在設定されている企業IDと異なるIDでfavoriteUserListを利用しようとしています。"
      )
    }
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * リストを最新状態にするための処理
   */
  refreshList: async (): Promise<void> => {
    const currentCorporationId = clAuth.corporationIdOrFail
    mutations.updateCorporationId(currentCorporationId) // 企業IDを更新

    const favoriteUserList = await new BackendApi.CorporationMember.UserProfileSearch.GetFavoriteUserListReadRequest(
      {
        universalCorporationId: currentCorporationId,
      }
    ).get()
    mutations.setFavoriteUserIds(
      favoriteUserList.list.map((favoriteUser) => favoriteUser.universalUid)
    )
  },
  /**
   * お気に入り状態を切り替えるための処理
   * サーバーとの通信時間を待たずに画面に変更が反映されるように、通信より先にstateを更新している。
   */
  toggleFavoriteStatus: async (targetUniversalUid: string): Promise<void> => {
    const universalCorporationId = clAuth.corporationIdOrFail

    // 企業IDをチェック
    getters.requireSameCorporationId(universalCorporationId)

    if (getters.isFavorited(targetUniversalUid)) {
      // 現在お気に入り中。本アクションによってお気に入りリストから外す。
      mutations.setFavoriteUserIds(
        getters.idList.filter((id) => id !== targetUniversalUid)
      )

      await new BackendApi.CorporationMember.FavoriteUser.RemoveFromCorporationFavoriteUserList(
        {
          universalCorporationId,
          targetUniversalUid,
        }
      ).post()
    } else {
      // 現在お気に入りリストに入っていない。本アクションによってリストに追加される。
      mutations.setFavoriteUserIds([...getters.idList, targetUniversalUid])

      await new BackendApi.CorporationMember.FavoriteUser.AddToCorporationFavoriteUserList(
        {
          universalCorporationId,
          targetUniversalUid,
        }
      ).post()
    }

    // サーバー側の状態更新が終わった後にリストを最新状態に更新する。これにより連打等でフロントとサーバー側の状態に一時的に差異が総じても同期される。
    actions.refreshList()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const corporationSideFavoriteUserList = { getters, actions }
