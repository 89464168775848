import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  chatGroupId: string
  messageId: string
}

export class CancelFileMessageWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.makeEndpoint(), {})
  }

  private makeEndpoint(): string {
    return `/matching/user/chat_group/${this.params.chatGroupId}/cancel/${this.params.messageId}`
  }
}
