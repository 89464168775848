import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ProjectApplicationByChatGroupIdView } from "~/backendApis/viewModel/matching"

interface Params {
  universalCorporationId: string
  chatGroupId: string
}

export class GetProjectApplicationByChatGroupIdReadRequest extends ReadRequest<ProjectApplicationByChatGroupIdView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ProjectApplicationByChatGroupIdView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { universalCorporationId, chatGroupId } = this.params
    return `/matching/corporation_member/${universalCorporationId}/chat_group/${chatGroupId}/project_application/show`
  }
}
