import { WriteRequest } from "~/backendApis/foundation/WriteRequest"
import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { UploadedFileResponse } from "~/backendApis/viewModel/file"

interface Params {
  userProfileImage: Blob
}

export class UploadUserProfileImageWriteRequest extends WriteRequest<UploadedFileResponse> {
  private readonly endpoint = "/file/user_account/user_profile_image/upload"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<UploadedFileResponse> {
    const formData = new FormData()
    formData.append("image", this.params.userProfileImage)

    return this.apiClient.post(
      this.endpoint,
      formData,
      ApiClient.MULTIPART_CUSTOM_HEADER
    )
  }
}
