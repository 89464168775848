




// このコンポーネントは以下の @see の1を利用している。
// これを利用するためには Google Fonts API を読み込んでおく必要があり、現状ではnuxt.config.jsに読み込む処理を記述している。
// 手順等知りたい場合は @see の2を参照すること。
//
// @see 1. https://material.io/resources/icons/?style=baseline
// @see 2. https://google.github.io/material-design-icons/#icon-font-for-the-web
import Vue, { PropType } from "vue"

export const OUTLINED = "OUTLINED"
export const FILLED = "FILLED"
export type IconType = typeof OUTLINED | typeof FILLED

export default Vue.extend({
  props: {
    // iconNameは、@seeにあるものを参照すること。
    // @see https://material.io/resources/icons/?icon=calendar_view_day&style=baseline
    iconName: {
      type: String,
      required: true,
    },
    iconType: {
      type: String as PropType<IconType>,
      required: true,
    },
  },
  computed: {
    classNameForMaterialIcon(): string {
      if (this.iconType === OUTLINED) {
        return "material-icons-outlined"
      } else {
        return "material-icons"
      }
    },
  },
})
