/**
 * OGPに関する状態管理をしている。
 * クラウドリンクスでは、外部サイトのOGPのメタデータをサーバー側にリクエストして取得し、フロントで描画している。
 * その取得中の状態管理や取得後した情報の一時的なキャッシュがこのStoreの役割。
 */
import Vue from "vue"
import { clApi } from "~/clApi"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface OGPMetadata {
  version: number // 形式が変わった時にversionが上がる。ブラウザ側で必要に応じてキャッシュを消す際に利用。
  imageURL: string // og:image
  title: string // og:title
  description?: string // og:description
  siteName?: string // og:site_name
  type?: string // og:type
  twitterCard?: string // twitter:card
  url?: string // og:url
  favicon?: string // icon
}
interface OGPState {
  /**
   * OGPのメタデータをサイトURLごとに保持
   */
  opgMetadataList: { [siteURL: string]: OGPMetadata }
  /**
   * OGPのないサイトURL
   * 取得を試みたがOGPがなかった場合にこのリストに追加される。
   */
  noOGPSiteURLs: string[]
}
const makeDefaultState = () => ({
  opgMetadataList: {},
  noOGPSiteURLs: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: OGPState = Vue.observable<OGPState>(makeDefaultState())

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  appendOGPMetadata(siteURL: string, parmas: OGPMetadata): void {
    state.opgMetadataList = {
      ...state.opgMetadataList,
      [siteURL]: parmas,
    }
  },
  appendNoOGPList(siteURL: string): void {
    state.noOGPSiteURLs = [...state.noOGPSiteURLs, siteURL]
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  getOGPMetadata: (
    siteURL: string
  ): {
    imageURL: string
    title: string
    description?: string
    siteName?: string
    favicon?: string
  } | null => {
    const ogpMetadata = state.opgMetadataList[siteURL]

    if (!ogpMetadata) {
      return null
    }

    return {
      imageURL: ogpMetadata.imageURL,
      title: ogpMetadata.title,
      description: ogpMetadata.description,
      siteName: ogpMetadata.siteName,
      favicon: ogpMetadata.favicon,
    }
  },
  isLoaded: (siteURL: string): boolean =>
    !!state.opgMetadataList[siteURL] || state.noOGPSiteURLs.includes(siteURL),
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * サイトURLのOGPを読み込むための処理
   */
  async load(siteURL: string) {
    // すでに読み込み済みのサイトURLの場合は何もしない。
    if (getters.isLoaded(siteURL)) {
      return
    }

    const metadata = await clApi.publicOGP.getMetadata(siteURL)

    if (metadata) {
      mutations.appendOGPMetadata(siteURL, metadata)
    } else {
      mutations.appendNoOGPList(siteURL)
    }
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const ogp = { getters, actions }
