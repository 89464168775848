/**
 * スカウトに関する状態管理をしている。
 * 企業アカウント用のStore。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { ScoutDetailView } from "~/backendApis/viewModel"
import { BackendApi } from "~/backendApis"
import { clAuth } from "~/clAuth"
import { StandardApplicationError } from "~/errorHandlers"
import {
  SelectionStatus,
  ScoutSortType,
} from "~/backendApis/viewModel/matching"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface FilteringParameter {
  statusKey: SelectionStatus
  sortType: ScoutSortType
}
interface ScoutListState {
  /**
   * 現在の企業ID
   * 異なる企業アカウントのリストが誤って表示されないように利用している。
   */
  currentCorporationId: string | null
  /**
   * フィルタリングのパラメーター
   */
  filteringParameter: FilteringParameter
  /**
   * 現在のページ数
   */
  currentPage: number
  /**
   * スカウトのリスト
   */
  scouts: ScoutDetailView[]
  /**
   * スカウトを取得中（バックエンドと通信中）かどうかを示すフラグ
   */
  isFetching: boolean
  /**
   * 次のページが取得可能かどうかを示すフラグ
   */
  canFetchNextPage: boolean
}
const makeDefaultState = (): ScoutListState => ({
  currentCorporationId: null,
  filteringParameter: {
    statusKey: "during_selection",
    sortType: "scout_created",
  },
  currentPage: 1,
  scouts: [],
  isFetching: false,
  canFetchNextPage: true,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ScoutListState = Vue.observable<ScoutListState>(makeDefaultState())

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.filteringParameter = defaultState.filteringParameter
  state.currentPage = defaultState.currentPage
  state.scouts = defaultState.scouts
  state.isFetching = defaultState.isFetching
  state.canFetchNextPage = defaultState.canFetchNextPage
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
enum AppendType {
  TO_HEAD = "TO_HEAD", // 先頭に追加
  TO_TAIL = "TO_TAIL", // 末尾に追加
}
const mutations = {
  setFilteringParameter(parameter: FilteringParameter) {
    state.filteringParameter = parameter
  },
  updateCorporationId(corporationId: string) {
    state.currentCorporationId = corporationId
  },
  resetScoutList() {
    state.currentPage = 1
    state.scouts = []
    state.canFetchNextPage = true
  },
  increasePageNumber() {
    state.currentPage += 1
  },
  appendScouts(
    scouts: ScoutDetailView[],
    toTail: AppendType = AppendType.TO_TAIL
  ) {
    const newList =
      toTail === AppendType.TO_TAIL
        ? [...state.scouts, ...scouts]
        : [...scouts, ...state.scouts]

    // idをキーに重複を除外している。
    const uniqueScouts = newList.reduce(
      (sum: ScoutDetailView[], scout: ScoutDetailView) => {
        // まだ配列（sum）に追加されていないスカウト(ID)の場合のみ配列（sum）に加える。
        if (sum.every((s: ScoutDetailView) => s.id !== scout.id)) {
          sum.push(scout)
        }
        return sum
      },
      []
    )
    state.scouts = uniqueScouts
  },
  removeFromList(scoutId: string) {
    state.scouts = state.scouts.filter((s) => s.id !== scoutId)
  },
  startFetching() {
    state.isFetching = true
  },
  finishFetching() {
    state.isFetching = false
  },
  setDownCanFetchNextPageFlag() {
    state.canFetchNextPage = false
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get filteringParameter(): FilteringParameter {
    return state.filteringParameter
  },
  get currentPage(): number {
    return state.currentPage
  },
  get scouts(): ScoutDetailView[] {
    return state.scouts
  },
  get exists(): boolean {
    return state.scouts.length > 0
  },
  get isFetching(): boolean {
    return state.isFetching
  },
  get canFetchNextPage(): boolean {
    return state.canFetchNextPage
  },
  findScoutById(scoutId: string): ScoutDetailView | null {
    return state.scouts.find((s) => s.id === scoutId) || null
  },
  requireSameCorporationId(corporationId: string) {
    if (state.currentCorporationId !== corporationId) {
      throw new StandardApplicationError(
        "現在設定されている企業IDと異なるIDでscoutListを利用しようとしています。"
      )
    }
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  updateFilteringParameter(parameter: FilteringParameter) {
    mutations.setFilteringParameter(parameter)
  },
  async updateLatestScouts() {
    // 企業IDをチェック
    getters.requireSameCorporationId(clAuth.corporationIdOrFail)

    const scoutDetails = await BackendApi.CorporationMember.Matching.read.getScoutList(
      {
        page: 1,
        selectionStatus: getters.filteringParameter.statusKey,
        sortType: getters.filteringParameter.sortType,
        universalCorporationId: clAuth.corporationIdOrFail,
      }
    )

    mutations.appendScouts(scoutDetails.list, AppendType.TO_HEAD)
  },
  /**
   * 現在設定されている検索パラメータを使って新しく取得する。
   */
  async fetchNew() {
    if (getters.isFetching) {
      // 現在スカウトを取得中の場合は何もしない。
      return
    }

    mutations.updateCorporationId(clAuth.corporationIdOrFail) // 企業IDを更新

    mutations.startFetching() // 取得処理開始
    mutations.resetScoutList() // リストの情報を初期化

    const initialPage = 1
    const scoutDetails = await BackendApi.CorporationMember.Matching.read
      .getScoutList({
        page: initialPage,
        selectionStatus: getters.filteringParameter.statusKey,
        sortType: getters.filteringParameter.sortType,
        universalCorporationId: clAuth.corporationIdOrFail,
      })
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (scoutDetails.numberOfTotalPages <= initialPage) {
      mutations.setDownCanFetchNextPageFlag() // スカウトが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendScouts(scoutDetails.list)
  },
  /**
   * 現在設定されているパラメータを利用して次のページのスカウトを取得する。
   */
  async fetchNextPage() {
    if (getters.isFetching || !getters.canFetchNextPage) {
      // 現在スカウトを取得中の場合は何もしない。
      return
    }

    // 企業IDをチェック
    getters.requireSameCorporationId(clAuth.corporationIdOrFail)

    mutations.startFetching() // 取得処理開始

    const nextPage = getters.currentPage + 1
    const scoutDetails = await BackendApi.CorporationMember.Matching.read
      .getScoutList({
        page: nextPage,
        selectionStatus: getters.filteringParameter.statusKey,
        sortType: getters.filteringParameter.sortType,
        universalCorporationId: clAuth.corporationIdOrFail,
      })
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (scoutDetails.numberOfTotalPages <= nextPage) {
      mutations.setDownCanFetchNextPageFlag() // スカウトが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendScouts(scoutDetails.list)
    mutations.increasePageNumber()
  },
  async finishSelection(params: { scoutId: string }) {
    // 企業IDをチェック
    getters.requireSameCorporationId(clAuth.corporationIdOrFail)

    const scout = getters.findScoutById(params.scoutId)

    if (!scout) {
      throw new StandardApplicationError(
        "選考を終了しようとするスカウトが見つかりません"
      )
    }

    await new BackendApi.CorporationMember.Matching.FinishSelectionWriteRequest(
      {
        universalCorporationId: clAuth.corporationIdOrFail,
        selectionId: scout.selectionId,
      }
    )
      .post()
      .then(() => {
        mutations.removeFromList(params.scoutId)
      })
      .catch((e: ApplicationApiError) => {
        if (e.isUnprocessableEntity()) {
          // 422エラーの場合は、エラーメッセージを表示し、リロードする
          alert("選考終了済みです。リロードし、ページを再読み込みします。")
          window.location.reload()
        }
      })
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const scoutList = { getters, actions }
