import { ImageView } from "~/backendApis/viewModel/common"

interface GenerateParams {
  image: ImageView
  width?: number
  height?: number
}

const backendFileAPIBaseURL = process.env.BACKEND_FILE_API_BASE_URL

export const thumbnail = {
  /**
   * ### image（引数）について
   * 本関数はすべての画像に対して利用できるわけではなく、「プロフィール画像」「企業ロゴ」「トップ企業ロゴ」「プロジェクトヘッダー」など特定の画像でのみ利用できる。
   * すべての利用可能な画像の種類を見たい場合はファイルAPIプロジェクトにある backend-file/serverless.yml に定義されたアクセス可能なS3リソースを見るとわかる。
   *
   *
   * ### width（引数）とheight（引数）について
   * widthとheightの両方を指定すると、そのサイズの枠がすべて覆われるように縦横比を維持して拡大/縮小されたサムネイルのURLが生成される。
   * 片方のみを指定すると、縦横比を維持したまま、指定した方がその長さまで拡大/縮小されたサムネイルのURLが生成される。
   *
   * また指定できるサイズは50~1000で、50~500は50刻み、500~1000は100刻みで指定できる。
   * （単位はpx）
   */
  generateURL: ({ image, width, height }: GenerateParams): string => {
    // widthもheightも指定されていない、またはFile用APIのベースURLが設定されていない（ローカル環境の）場合はオリジナルのURLを返す。
    if ((!width && !height) || !backendFileAPIBaseURL) {
      return image.url
    }

    const queryParams = []
    if (width) queryParams.push(`width=${width}`)
    if (height) queryParams.push(`height=${height}`)

    return `${backendFileAPIBaseURL}/thumbnail/${encodeURIComponent(
      image.path
    )}?${queryParams.join("&")}`
  },
}
