/**
 * 企業側の契約訴求に関する状態を管理している
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { ContractTypeView } from "~/backendApis/viewModel"
import { BackendApi } from "~/backendApis"
import { clAuth } from "~/clAuth"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface CorporationContractAppealState {
  refreshed: boolean
  currentContractType: ContractTypeView | null
}
const makeDefaultState = () => ({
  refreshed: false,
  currentContractType: null,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: CorporationContractAppealState = Vue.observable<CorporationContractAppealState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.refreshed = defaultState.refreshed
  state.currentContractType = defaultState.currentContractType
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setRefreshedFlagUp() {
    state.refreshed = true
  },
  setState(params: { currentContractType: ContractTypeView }) {
    state.currentContractType = params.currentContractType
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  /**
   * 有料プラン契約を訴求をするか。
   */
  get shouldApealPaidPlanContract(): boolean {
    return state.refreshed && state.currentContractType?.contractType === null
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  async forceRefresh() {
    const currentContractType = await new BackendApi.CorporationMember.CorporationContract.GetCurrentContractTypeReadRequest(
      { universalCorporationId: clAuth.corporationIdOrFail }
    ).get()

    mutations.setState({
      currentContractType,
    })
    mutations.setRefreshedFlagUp()
  },
  /**
   * stateを最新状態に更新
   */
  async refresh() {
    // すでにリフレッシュ済みの場合は何もしない。
    if (state.refreshed) {
      return
    }

    await actions.forceRefresh()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const corporationContractAppeal = { getters, actions }
