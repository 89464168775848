import { ApiClient } from "~/clApi/main/foundation/ApiClient"
import { ProfileUnreadableCorporationView } from "~/clApi/viewModel"

const basePath = "/user_profile_search/user"

export const read = {
  getProfileUnreadableCorporationByUniversalUid: () =>
    new ApiClient<ProfileUnreadableCorporationView>().get(
      `${basePath}/profile_unreadable_corporation/list`
    ),
}
