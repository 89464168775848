import { ApiClient } from "~/backendApis/foundation/ApiClient"

const basePath = "/user_account/user"

export const write = {
  achieveOnboardingStage: (params: { finishedStage: string }) =>
    new ApiClient<void>().post(`${basePath}/onboarding_status/achieve`, {
      finishedStage: params.finishedStage,
    }),
}
