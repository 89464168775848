import { ApiClient } from "~/backendApis/foundation/ApiClient"
import {
  LeadingCompanyListView,
  SkillListView,
  ProfileViewedEventCountView,
  ProfileViewedByCorporationEventListView,
} from "~/backendApis/viewModel"
import { queryParamsGenerator } from "~/backendApis/helpers/queryParamsGenerator"

const basePath = "/user_profile/user"

interface Params {
  keyword: string | null
  largeCategoryId: number | null
}

export const read = {
  countProfileViewedByCorporationEventForOneMonth: () =>
    new ApiClient<ProfileViewedEventCountView>().get(
      `${basePath}/profile_viewed_event/count_for_one_month`
    ),
  getProfileViewedByCorporationEventList: ({ page }: { page: number }) =>
    new ApiClient<ProfileViewedByCorporationEventListView>().get(
      `${basePath}/profile_viewed_event/list?page=${page}`
    ),
  searchLeadingCompanyList: (params: { keyword: string }) =>
    new ApiClient<LeadingCompanyListView>().get(
      `${basePath}/leading_company/search?keyword=${encodeURIComponent(
        params.keyword
      )}`
    ),
  searchSkillList: (params: {
    largeCategoryId: number | null
    keyword: string | null
  }) => {
    const keys: Array<keyof Params> = ["largeCategoryId", "keyword"]
    const queryString = queryParamsGenerator.generateSkillSearchQuery<Params>({
      keys,
      params,
    })

    return new ApiClient<SkillListView>().get(
      `${basePath}/skill/search${queryString}`
    )
  },
}
