import { ApiClient } from "~/backendApis/foundation/ApiClient"
import {
  AvailableUptimeListView,
  ContactableTypeListView,
  JobChangeIntentionListView,
  PersonnelScaleListView,
  WorkFormListView,
  SexListView,
} from "~/backendApis/viewModel"

const basePath = "/user_profile/general"

export const read = {
  getAvailableUptimeList: () =>
    new ApiClient<AvailableUptimeListView>().get(
      `${basePath}/master/available_uptime/list`
    ),
  getContactableTypeList: () =>
    new ApiClient<ContactableTypeListView>().get(
      `${basePath}/master/contactable_type/list`
    ),
  getJobChangeIntentionList: () =>
    new ApiClient<JobChangeIntentionListView>().get(
      `${basePath}/master/job_change_intention/list`
    ),
  getPersonnelScaleList: () =>
    new ApiClient<PersonnelScaleListView>().get(
      `${basePath}/master/personnel_scale/list`
    ),
  getWorkFormList: () =>
    new ApiClient<WorkFormListView>().get(`${basePath}/master/work_form/list`),
  getSexList: () =>
    new ApiClient<SexListView>().get(`${basePath}/master/sex/list`),
}
