import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { LargeJobCategoryView, PrefectureView } from "~/backendApis/viewModel"

const basePath = "/shared"

export const read = {
  getJobCategoryList: () =>
    new ApiClient<LargeJobCategoryView[]>().get(
      `${basePath}/job_category/list`
    ),
  getPrefectureList: () =>
    new ApiClient<PrefectureView[]>().get(`${basePath}/prefecture/list`),
}
