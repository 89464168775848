






























import Vue, { PropType } from "vue"
import AccountImageMenu from "./navigationMenuItem/AccountImageMenu.vue"
import { ImageIconNavigationItem } from "./type"

export default Vue.extend({
  components: {
    AccountImageMenu,
  },
  props: {
    previousPageLink: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
    imageIconNavigationItem: {
      type: Object as PropType<ImageIconNavigationItem>,
      default: null,
    },
    previousIcon: {
      type: String,
      default: "arrow_back",
    },
  },
  methods: {
    handleClickAccountImageMenu() {
      this.$emit("click-account-image-menu")
    },
  },
})
