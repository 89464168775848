import { ApiClient } from "~/clApi/main/foundation/ApiClient"
import { CorporationTemplateListView } from "~/clApi/viewModel/template"

const basePath = "/template/corporation_member"

export const read = {
  getScoutTemplateList: (params: { universalCorporationId: string }) =>
    new ApiClient<CorporationTemplateListView>().get(
      `${basePath}/${params.universalCorporationId}/scout/list`
    ),
}
