import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { MessageListView } from "~/backendApis/viewModel/matching"

interface Params {
  universalCorporationId: string
  chatGroupId: string
  latestMessageId: string | null
}

export class GetLatestMessageListReadRequest extends ReadRequest<MessageListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<MessageListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { universalCorporationId, chatGroupId, latestMessageId } = this.params
    const query = latestMessageId ? `?latestMessageId=${latestMessageId}` : ""
    return `/matching/corporation_member/${universalCorporationId}/chat_group/${chatGroupId}/message/latest_list${query}`
  }
}
