import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

export class UpdateUserLastAccessWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/user_profile/user/user_last_access/update"

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, {})
  }
}
