
























import Vue from "vue"
import PNavigationBar from "./local/PNavigationBar.vue"
import { NormalNavigationItem, ImageIconNavigationItem } from "./local/type"
import { navGenerator } from "./local/navGenerator"
import CFlashMessage from "./local/CFlashMessage.vue"
import LoadingOverlay, {
  WHITE_BACKGROUND,
} from "~/components/guideline/loading/LoadingOverlay.vue"
import { BackendApi } from "~/backendApis"

export default Vue.extend({
  components: {
    PNavigationBar,
    CFlashMessage,
    LoadingOverlay,
  },
  data() {
    return {
      /**
       * 未読メッセージがあるかどうか。（ユーザー用）
       */
      hasUnreadMessage: false,
      /**
       * スカウトに未読メッセージがあるかどうか。（企業用）
       */
      hasUnreadMessageOfScout: false,
      /**
       * 応募に未読メッセージがあるかどうか。（企業用）
       */
      hasUnreadMessageOfApplication: false,
    }
  },
  computed: {
    backgroundType() {
      return { WHITE_BACKGROUND }
    },
    isVisibleHeaderNav(): boolean {
      return this.$clStores.mobileNavigationMenu.getters.isVisibleHeaderNav
    },
    isVisibleBottomNav(): boolean {
      return this.$clStores.mobileNavigationMenu.getters.isVisibleBottomNav
    },
    headerTitle(): string {
      return this.$clStores.mobileNavigationMenu.getters.headerTitle
    },
    headerPreviousPagePath(): string {
      return this.$clStores.mobileNavigationMenu.getters.headerPreviousPagePath
    },
    headerPreviousIcon(): string {
      return this.$clStores.mobileNavigationMenu.getters.headerPreviousIcon
    },
    navigationResources(): {
      navItem: NormalNavigationItem[]
      imageIcon: ImageIconNavigationItem | null
    } {
      if (this.$clAuth.corporationId) {
        // 企業アカウント
        return {
          navItem: navGenerator.generateForCorporationAccount({
            hasUnreadMessageOfScout: this.hasUnreadMessageOfScout,
            hasUnreadMessageOfApplication: this.hasUnreadMessageOfApplication,
          }),
          imageIcon: {
            imageUrl: this.$clAuth.accountMetadata.imageUrl,
            url: "/corporation/profile",
          },
        }
      } else if (this.$clAuth.loggedIn) {
        // ユーザーアカウント
        return {
          navItem: navGenerator.generateForUserAccount(
            {
              hasUnreadMessage: this.hasUnreadMessage,
            },
            this.$clAuth.userIdOrFail
          ),
          imageIcon: {
            imageUrl: this.$clAuth.accountMetadata.imageUrl,
            url: `/profiles/${this.$clAuth.userIdOrFail}`,
          },
        }
      } else {
        // 未認証ユーザー
        return {
          navItem: navGenerator.generateForUnauthed(),
          imageIcon: null,
        }
      }
    },
    normalNavigationItems(): NormalNavigationItem[] {
      return this.navigationResources.navItem
    },
    imageIconNavigationItem(): ImageIconNavigationItem | null {
      return this.navigationResources.imageIcon
    },
    /**
     * ナビゲーションの赤ポチ用の監視対象。
     * watchの記述をシンプルにするために、computedに監視対象をまとめて定義している。
     */
    watchedTargetForUnreadBatchStatus(): Array<object | boolean | null> {
      return [
        this.$route, // ページ遷移を検知するためのオブジェクト
        this.$clAuth.finishedAuthProcess,
        !!this.$clAuth.corporationId, // 企業アカウントの切り替えを検知するためのオブジェクト
      ]
    },
  },
  watch: {
    watchedTargetForUnreadBatchStatus: {
      handler() {
        this.initializeUnreadBatchStatus()
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 現在のアカウントに合わせて、未読バッチの状態を初期化する処理。
     */
    initializeUnreadBatchStatus() {
      if (this.$clAuth.corporationId) {
        this.hasUnreadMessage = false
        new BackendApi.CorporationMember.Matching.GetNumberOfUnreadMessagesOfScoutReadRequest(
          { universalCorporationId: this.$clAuth.corporationIdOrFail }
        )
          .get()
          .then((r) => {
            this.hasUnreadMessageOfScout = r.number > 0
          })
        BackendApi.CorporationMember.Matching.read
          .getNumberOfUnreadMessagesOfApplication({
            universalCorporationId: this.$clAuth.corporationIdOrFail,
          })
          .then((r) => {
            this.hasUnreadMessageOfApplication = r.number > 0
          })
      } else if (this.$clAuth.loggedIn) {
        new BackendApi.User.Matching.GetNumberOfUnreadMessagesReadRequest()
          .get()
          .then((r) => {
            this.hasUnreadMessage = r.number > 0
          })
        this.hasUnreadMessageOfScout = false
        this.hasUnreadMessageOfApplication = false
      } else {
        // 未ログイン時
        this.hasUnreadMessage = false
        this.hasUnreadMessageOfScout = false
        this.hasUnreadMessageOfApplication = false
      }
    },
    handleOpenLoginModal() {
      this.$emit("open-login-modal")
    },
    handleOpenSignupModal() {
      this.$emit("open-signup-modal")
    },
  },
})
