import { WriteRequest } from "~/backendApis/foundation/WriteRequest"
import { ProjectPaysType } from "~/backendApis/viewModel/project"

interface Params {
  universalCorporationId: string
  projectId: string
  possibleOperatingMaxHours: number
  possibleOperatingMinHours: number
  prefectureId: number | null
  recruitmentLargeJobCategoryId: number
  recruitmentSmallJobCategoryId: number
  recruitmentWorkingFormId: number
  projectPaysType: ProjectPaysType
  projectPaysMin: number | null
  projectPaysMax: number | null
}

export class ChangeFeatureWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const {
      possibleOperatingMaxHours,
      possibleOperatingMinHours,
      prefectureId,
      recruitmentLargeJobCategoryId,
      recruitmentSmallJobCategoryId,
      recruitmentWorkingFormId,
      projectPaysType,
      projectPaysMin,
      projectPaysMax,
    } = this.params
    return this.apiClient.post(this.makeEndpoint(), {
      possibleOperatingMaxHours,
      possibleOperatingMinHours,
      prefectureId,
      recruitmentLargeJobCategoryId,
      recruitmentSmallJobCategoryId,
      recruitmentWorkingFormId,
      projectPaysType,
      projectPaysMin,
      projectPaysMax,
    })
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectId } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/${projectId}/content/change_feature`
  }
}
