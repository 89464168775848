import axios, { AxiosResponse, AxiosInstance, AxiosError } from "axios"
import ApplicationApiError from "./ApplicationApiError"
import { auth } from "~/plugins/firebase"

export class ApiClient<T> {
  static readonly MULTIPART_CUSTOM_HEADER: object = {
    "Content-Type": "multipart/form-data",
  }

  static readonly APPLICATION_CUSTOM_HEADER: object = {
    "Content-Type": "application/json",
  }

  private httpClient: AxiosInstance

  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.BACKEND_API_BASE_URL + "/internal_frontend_web",
      timeout: 15000, // 15 second
    })
  }

  public async get(path: string): Promise<T> {
    const headers = await this.makeRequestHeaders()

    const axiosResponse: AxiosResponse = await this.httpClient
      .get<T>(path, {
        headers,
      })
      .catch((e: AxiosError) => {
        throw new ApplicationApiError(e)
      })

    return axiosResponse.data
  }

  public async post(
    path: string,
    params: object,
    customHeaders: object = ApiClient.APPLICATION_CUSTOM_HEADER
  ): Promise<T> {
    const headers = await this.makeRequestHeaders()

    const axiosResponse: AxiosResponse = await this.httpClient
      .post<T>(path, params, { headers: { ...headers, ...customHeaders } })
      .catch((e: AxiosError) => {
        throw new ApplicationApiError(e)
      })

    return axiosResponse.data
  }

  private async makeRequestHeaders() {
    /**
     * Firebase Authenticationで認証されているユーザー情報
     */
    const authUser: firebase.default.User | null = auth.currentUser

    if (authUser === null) {
      return {}
    }

    /**
     * 認証済みの場合
     * Firebase Authenticationの仕様上、常にIndexDBにID Token（accessToken）を格納しておき、
     * 期限が切れた場合は自動でrefreshTokenを利用してトークンを更新するようになっている。
     */
    const idToken = await authUser.getIdToken()
    const headers = {
      Authorization: `Bearer ${idToken}`,
    }
    return headers
  }
}
