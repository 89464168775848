/**
 * プロフィール検索に関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { BackendApi } from "~/backendApis"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface JobCategoryOption {
  label: string
  value: number
  smallJobCategories: Array<{
    label: string
    value: number
  }>
}
export interface NormalOption {
  label: string
  value: number
}
export interface AgeOption {
  index: number
  label: string
  minAgeValue: number
  maxAgeValue: number
  minAgeLabel: string
  maxAgeLabel: string
}
interface ProfileSearchParameterOptionState {
  /**
   * 現在のページ数
   */
  jobCategoryOptions: JobCategoryOption[]
  availableUptimeOptions: NormalOption[]
  sinceLastAccessDaysOptions: NormalOption[]
  prefectureOptions: NormalOption[]
  workingFormOptions: NormalOption[]
  ageOptions: AgeOption[]
}
const makeDefaultState = () => ({
  jobCategoryOptions: [],
  availableUptimeOptions: [],
  sinceLastAccessDaysOptions: [],
  prefectureOptions: [],
  workingFormOptions: [],
  ageOptions: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProfileSearchParameterOptionState = Vue.observable<ProfileSearchParameterOptionState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.jobCategoryOptions = defaultState.jobCategoryOptions
  state.availableUptimeOptions = defaultState.availableUptimeOptions
  state.sinceLastAccessDaysOptions = defaultState.sinceLastAccessDaysOptions
  state.prefectureOptions = defaultState.prefectureOptions
  state.workingFormOptions = defaultState.workingFormOptions
  state.ageOptions = defaultState.ageOptions
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setJobCategoryOptions(jobCategoryOptions: JobCategoryOption[]) {
    state.jobCategoryOptions = jobCategoryOptions
  },
  setAvailableUptimeOptions(availableUptimeOptions: NormalOption[]) {
    state.availableUptimeOptions = availableUptimeOptions
  },
  setSinceLastAccessDaysOptions(sinceLastAccessDaysOptions: NormalOption[]) {
    state.sinceLastAccessDaysOptions = sinceLastAccessDaysOptions
  },
  setPrefectureOptions(prefectureOptions: NormalOption[]) {
    state.prefectureOptions = prefectureOptions
  },
  setWorkingFormOptions(workingFormOptions: NormalOption[]) {
    state.workingFormOptions = workingFormOptions
  },
  setAgeOptions(ageOptions: AgeOption[]) {
    state.ageOptions = ageOptions
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get jobCategoryOptions(): JobCategoryOption[] {
    return state.jobCategoryOptions
  },
  get availableUptimeOptions(): NormalOption[] {
    return state.availableUptimeOptions
  },
  get sinceLastAccessDaysOptions(): NormalOption[] {
    return state.sinceLastAccessDaysOptions
  },
  get prefectureOptions(): NormalOption[] {
    return state.prefectureOptions
  },
  get workingFormOptions(): NormalOption[] {
    return state.workingFormOptions
  },
  get ageOptions(): AgeOption[] {
    return state.ageOptions
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * パラメータを読み込む
   */
  loadParameter(isFullParameterSearch: boolean) {
    // 職種のオプション
    if (getters.jobCategoryOptions.length === 0) {
      BackendApi.general.shared.read
        .getJobCategoryList()
        .then((jobCategories) =>
          jobCategories.map((elm) => ({
            value: elm.id,
            label: elm.name,
            smallJobCategories: elm.smallJobCategories.map((elm2) => ({
              value: elm2.id,
              label: elm2.name,
            })),
          }))
        )
        .then((options) => mutations.setJobCategoryOptions(options))
    }

    // 検索がフル機能でない場合は、必要な職種のみ読み込んで処理を終了する。
    if (!isFullParameterSearch) {
      return
    }

    // 居住地域のオプション
    if (getters.prefectureOptions.length === 0) {
      BackendApi.general.shared.read
        .getPrefectureList()
        .then((prefectures) =>
          prefectures.flatMap((elm) =>
            elm.id < 48
              ? {
                  value: elm.id,
                  label: elm.name,
                }
              : []
          )
        )
        .then((options) => mutations.setPrefectureOptions(options))
    }

    // 稼働時間のオプション
    if (getters.availableUptimeOptions.length === 0) {
      BackendApi.general.userProfile.read
        .getAvailableUptimeList()
        .then((availableUptimeList) =>
          availableUptimeList.list.map((elm) => ({
            value: elm.id,
            label: elm.name,
          }))
        )
        .then((options) => mutations.setAvailableUptimeOptions(options))
    }

    // 最終アクセスのオプション
    if (getters.sinceLastAccessDaysOptions.length === 0) {
      const sinceLastAccessDaysOptions = [3, 7, 14, 30, 90].map((n) => ({
        label: `${n}日以内にアクセス`,
        value: n,
      }))
      mutations.setSinceLastAccessDaysOptions(sinceLastAccessDaysOptions)
    }

    // 稼働形態のオプション
    if (getters.workingFormOptions.length === 0) {
      const workingFormOptions = [
        { label: "副業", value: 1 },
        { label: "フリーランス", value: 2 },
      ]
      mutations.setWorkingFormOptions(workingFormOptions)
    }

    // 年齢のオプション
    if (getters.ageOptions.length === 0) {
      const loadAgeOptions = () => {
        // 5歳刻みでオプションを生成する。
        let index = 1
        const ageOptions: AgeOption[] = []
        const minAge = 20
        const maxAge = 65
        for (let age = minAge; age <= maxAge - 5; age += 5) {
          const minAgeValue = age
          const maxAgeValue = age + 5
          const minAgeLabel = `${minAgeValue}歳${
            minAgeValue === minAge ? "未満" : ""
          }`
          const maxAgeLabel = `${maxAgeValue}歳${
            maxAgeValue === maxAge ? "以上" : ""
          }`
          const label = `${minAgeLabel}〜${maxAgeLabel}`
          ageOptions.push({
            index,
            label,
            // 最低年齢・最高年齢の場合はその年齢未満または以上を指定したいのと、型をnumber一択にして処理をシンプルにしたいので極値を格納する。
            minAgeValue: minAgeValue === minAge ? 15 : minAgeValue,
            maxAgeValue: maxAgeValue === maxAge ? 130 : maxAgeValue,
            minAgeLabel,
            maxAgeLabel,
          })
          index += 1
        }
        mutations.setAgeOptions(ageOptions)
      }
      loadAgeOptions()
    }
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const profileSearchParameterOption = { getters, actions }
