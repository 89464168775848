import { render, staticRenderFns } from "./UnclickableIconMenu.vue?vue&type=template&id=af8ac772&scoped=true&"
import script from "./UnclickableIconMenu.vue?vue&type=script&lang=ts&"
export * from "./UnclickableIconMenu.vue?vue&type=script&lang=ts&"
import style0 from "./UnclickableIconMenu.vue?vue&type=style&index=0&id=af8ac772&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af8ac772",
  null
  
)

export default component.exports