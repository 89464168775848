






import Vue from "vue"
import CLayoutTemplate from "~/components/custom/frame/CLayoutTemplate.vue"

/**
 * Install the anti-flicker snippet
 * @link https://support.google.com/optimize/answer/7100284?hl=en
 */
const containerId = process.env.GTM_CONTAINER_ID
const antiFlickerSnippetStyle = ".async-hide { opacity: 0 !important}"
const antiFlickerSnippetScript = `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
})(window,document.documentElement,'async-hide','dataLayer',4000,
{'${containerId}':true});`

export default Vue.extend({
  components: {
    CLayoutTemplate,
  },
  head: {
    style: [
      {
        hid: "antiFlickerSnippetStyle",
        cssText: antiFlickerSnippetStyle,
      },
    ],
    script: [
      {
        hid: "antiFlickerSnippetScript",
        innerHTML: antiFlickerSnippetScript,
      },
    ],
    __dangerouslyDisableSanitizersByTagID: {
      antiFlickerSnippetScript: ["innerHTML"],
    },
  },
})
