/**
 * ミドルウェアレベルでは認証状態のチェックのみを行い、認可（権限）に関するチェックは各ページで行うようにしている。
 * これは認可のチェックまでをミドルウェアで行うと認可の種類の増加に伴いミドルウェアも膨らんでいってしまうことを危惧してのこと。
 */
export enum NuxtMiddleware {
  /**
   * ユーザーアカウントであることが必須のページで利用されるミドルウェア。
   */
  REQUIRE_USER_ACCOUNT = "requireUserAccount",

  /**
   * 企業アカウントであることが必須のページで利用されるミドルウェア。
   */
  REQUIRE_CORPORATION_ACCOUNT = "requireCorporationAccount",
}
