import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ScoutDetailView } from "~/backendApis/viewModel/matching"

interface Params {
  universalCorporationId: string
  universalUid: string
}

export class GetScoutDetailLatestReadRequest extends ReadRequest<ScoutDetailView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ScoutDetailView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { universalCorporationId, universalUid } = this.params
    return `/matching/corporation_member/${universalCorporationId}/scout/${universalUid}/latest`
  }
}
