import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  targetUniversalUid: string
}

export class RemoveCorporationMemberListWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { targetUniversalUid } = this.params
    return this.apiClient.post(this.makeEndpoint(), { targetUniversalUid })
  }

  private makeEndpoint(): string {
    return `/corporation_account/corporation_member/${this.params.universalCorporationId}/corporation_member_list/remove`
  }
}
