import VueRouter, { Route } from "vue-router"
import { decorateUserAccountView } from "~/backendApis/viewModel/userAccount"
import { CallBackWhenSuccess } from "~/clAuth/completer"
import { BackendApi } from "~/backendApis"
import { CORPORATION_ID_QUERY_KEY } from "~/uiLogics/corporation/switchedCorporationStateManager"
import { clAuth } from "~/clAuth"

export const AUTH_REDIRECT_PATH_QUERY_NAME = "redirectPath"

/**
 * 自動アカウント切り替え用のクエリパラメータ名
 * アカウントの自動切り替え処理自体は plugins/autoAccountSwitcher.ts でブラウザ上でページを最初に表示したタイミングで行っている。
 * このクエリパラメータによって、ページ描画時に指定された企業アカウントに切り替えるかユーザーアカウントに戻すかを操作している。
 * 実装の詳細は plugins/autoAccountSwitcher.ts の方を見てほしい。
 */
export const AUTO_SWITCHED_CORPORATION_ACCOUNT_ID_QUERY_NAME = "authCorporation"

interface MakeAuthPagePathArg {
  route: Route
  redirectPath?: string // 認証後にリダイレクトするパス
}

/**
 * 認証ページのパスを作成するための関数
 * 現状の仕様だと、認証は /auth ページで行われており、認証後のリダイレクト先などはクエリパラメータで指定されている。
 * この関数ではそれらのパラメータを考慮したパスを作成している。
 */
export const makeAuthPagePath = ({
  redirectPath,
  route,
}: MakeAuthPagePathArg): string => {
  const queries = []

  if (redirectPath) {
    queries.push(
      `${AUTH_REDIRECT_PATH_QUERY_NAME}=${encodeURIComponent(redirectPath)}`
    )
  }

  const autoSwitchedCorporationAccountId =
    route.query[AUTO_SWITCHED_CORPORATION_ACCOUNT_ID_QUERY_NAME]
  if (autoSwitchedCorporationAccountId) {
    queries.push(
      `${AUTO_SWITCHED_CORPORATION_ACCOUNT_ID_QUERY_NAME}=${String(
        autoSwitchedCorporationAccountId
      )}`
    )
  }

  return queries.length > 0 ? `/auth?${queries.join("&")}` : "/auth"
}

/**
 * 認証後にリダイレクトするパスをクエリパラメータから作成する関数
 * 現状の仕様だと、認証は /auth ページに飛ばされて行われ、そのパスのクエリパラメータとして認証後にリダイレクトするページが指定されるようになっている。
 * この関数では、そのクエリパラメーターで指定されたリダイレクト先をパスとして作成している。
 */
export const makeRedirectPathFromQuery = (
  redirectPathInQuery: string | (string | null)[] | undefined
): string => {
  if (typeof redirectPathInQuery !== "string") {
    return ""
  }

  const redirectPath = decodeURIComponent(redirectPathInQuery)

  /**
   * リダイレクト先のパスから アカウント自動切り替え用 のクエリパラメータのみを削除している。
   * 削除している理由は以下の2つ。
   *
   * 1. アカウント自動切り替えは認証と同時にpluginで行われているため、認証後のリダイレクトパスに指定する必要はないため。
   * 2. セキュリティ観点で、可能な限り認証周りの情報は見えないようにしたいため。
   */
  const url = new URL(redirectPath, location.origin)
  url.searchParams.delete(AUTO_SWITCHED_CORPORATION_ACCOUNT_ID_QUERY_NAME)

  return url.pathname + url.search
}

export const makeActionAfterAuthSuccess = (
  router: VueRouter,
  transitionPagePath: string | null = null
) => {
  return (params: CallBackWhenSuccess) => {
    if (!params.userAccount) {
      // 未ログインの場合、プロジェクト一覧ページに遷移
      router.push({ path: "/projects" })
      return
    }

    if (params.userAccount.roleId === 2) {
      // ユーザーロールが企業担当者の場合
      if (!clAuth.corporationId) {
        // 企業アカウントが紐づいていない場合、企業アカウントにSwitch
        switchAccount(router)
        return
      }
      // 指定されたページ、または企業プロフィールページに遷移
      const path = transitionPagePath || "/corporations/profile"
      router.push({ path })
      return
    }

    if (!decorateUserAccountView(params.userAccount).isCompletedSignUp()) {
      // 一般ユーザーで登録が完了していない場合、プロフィール登録ページに遷移
      window.location.href = `${process.env.FRONTEND_WORKER_BASE_URL}/worker/profiles/new/base`
      return
    }

    // 指定されたページかプロフィール一覧ページに遷移
    const path = transitionPagePath || "/projects"
    router.push({ path })
  }
}

const switchAccount = async (router: VueRouter) => {
  const corporationAccountList = await new BackendApi.User.CorporationAccount.GetMyCorporationAccountListReadRequest().get()
  const universalCorporationId =
    corporationAccountList.list[0].universalCorporationId
  router.push({
    path: `/switch_account?${CORPORATION_ID_QUERY_KEY}=${universalCorporationId}`,
  })
}
