// アクションログ等で複数のフロントエンドAPPを跨いで利用されているキーであるため、変更する場合は必ず該当箇所全てを変更すること。
const globalKey = "switchedCorporationAccountId"

export const setSwitchedCorporationAccountId = (
  universalCorporationId: string
): void => {
  localStorage.setItem(globalKey, universalCorporationId)
}

export const getSwitchedCorporationAccountId = (): string | null => {
  return localStorage.getItem(globalKey)
}

export const removeSwitchedCorporationAccountId = (): void => {
  localStorage.removeItem(globalKey)
}
