












































import Vue, { PropType } from "vue"
import ClickableIconMenu from "./navigationMenuItem/ClickableIconMenu.vue"
import UnclickableIconMenu from "./navigationMenuItem/UnclickableIconMenu.vue"
import { NormalNavigationItem } from "./type"

export default Vue.extend({
  components: {
    ClickableIconMenu,
    UnclickableIconMenu,
  },
  props: {
    normalNavigationItems: {
      type: Array as PropType<NormalNavigationItem[]>,
      required: true,
    },
    isVisibleAuthMenus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    isCurrentPage(path: string): boolean {
      return (
        this.$nuxt.$route.path.replace(/\/$/, "") === path.replace(/\/$/, "")
      )
    },
    navigateTo(path: string) {
      this.$nuxt.$router.push({ path })
    },
    handleClickLoginMenu() {
      this.$emit("click-login-menu")
    },
    handleClickSignupMenu() {
      this.$emit("click-signup-menu")
    },
  },
})
