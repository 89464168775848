/**
 * ページのスクロール位置に関する状態管理をしている。
 * 無限スクロールを採用しているページ等で、遷移した後もとのページに戻った際に、元々いた位置までスクロールされた状態にしたいという仕様を実現するために、
 * ページ単位でスクロール位置を保持できるようにしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface ScrollPositionState {
  /**
   * パス単位でスクロール位置（offset top）を保持
   */
  offsetTops: { [path: string]: number }
}
const makeDefaultState = () => ({
  offsetTops: {},
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ScrollPositionState = Vue.observable<ScrollPositionState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.offsetTops = defaultState.offsetTops
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setOffsetTop(parmas: { path: string; offsetTop: number }): void {
    state.offsetTops = {
      ...state.offsetTops,
      [parmas.path]: parmas.offsetTop,
    }
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  getOffsetTop: (path: string): number => state.offsetTops[path] || 0,
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * リストを最新状態にするための処理
   */
  setOffsetTop(params: { path: string; offsetTop: number }) {
    mutations.setOffsetTop(params)
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const scrollPosition = { getters, actions }
