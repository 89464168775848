export interface UserContactInformationView {
  email: string
  phoneNumber?: string
}

export interface UserMailNotificationView {
  universalUid: string
  settings: Array<{
    receiveSettableMailType: ReceiveSettableMailType
    receptionStatus: ReceptionStatus
  }>
}

export interface MailSubscribeSettingView {
  universalUid: string
  suppressions: Array<{
    id: number
    name: ReceiveSettableMailType
    description: string
    isSuppressed: boolean
  }>
}

export interface ContactableUserView {
  universalUid: string
  fullName: string
  email: string
}

export interface ContactableUserListView {
  list: ContactableUserView[]
}

export interface SystemAutomaticMailView {
  id: string
  destinationType: "to_user" | "to_all_corporation_members"
  subject: string
  textContent: string
  htmlContent: string | null
  memo: string
  status: "active" | "archived"
  mailType: ReceiveSettableMailType | null
}

export interface SystemAutomaticMailListView {
  list: SystemAutomaticMailView[]
}

export interface CwSlackNotificationView {
  id: string
  webhookUrl: string
  text: string
  memo: string
  status: "active" | "archived"
}

export interface CwSlackNotificationListView {
  list: CwSlackNotificationView[]
}

export type ReceiveSettableMailType =
  | "new_chat_messages"
  | "new_features_announcement"
  | "appended_favorite_list"
  | "recommended_projects"
  | "mail_magazine"
  | "agent_mail"

export const decorateReceiveSettableMailType = () => ({
  generateMailTypeWithName: (): Array<{
    key: ReceiveSettableMailType
    name: string
  }> => {
    return [
      { key: "new_chat_messages", name: "新着メッセージ" },
      { key: "new_features_announcement", name: "新機能お知らせ" },
      { key: "appended_favorite_list", name: "お気に入りリスト追加" },
      { key: "recommended_projects", name: "おすすめプロジェクト" },
      { key: "mail_magazine", name: "メールマガジン" },
      { key: "agent_mail", name: "エージェントからのメール" },
    ]
  },
})

export type ReceptionStatus = "accepting" | "rejecting"
