import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  outputList: {
    description: string | null
    filePath: string | null
    relatedUrl: string | null
  }[]
}

export class ChangeOutputListWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/user_profile/user/edit/output_list/change"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
