import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { MailSubscribeSettingView } from "~/backendApis/viewModel/externalNotification"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

export class GetMailSubscribeSettingReadRequest extends ReadRequest<MailSubscribeSettingView> {
  private readonly endpoint: string =
    "/external_notification/user/mail_subscribe_setting/suppression/list"

  public get(): Promise<MailSubscribeSettingView | null> {
    return this.apiClient.get(this.endpoint).catch((e: ApplicationApiError) => {
      if (e.isNotFoundError()) {
        return null
      }
      throw e
    })
  }
}
