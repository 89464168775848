import { ApplicationError } from "./ApplicationError"

/**
 * This exception occurs when an error what we already know and want it notify as warning happens.
 * Rollbar notifies this error as warning.
 */
export class KnownErrorWarning extends ApplicationError {
  constructor(message: string) {
    super({ className: "KnownErrorWarning", message })
  }
}
