/**
 * read
 */
import { read } from "./read"

/**
 * write
 */
import { write } from "./write"
import { ChangeBaseInformationWriteRequest } from "./write/ChangeBaseInformationWriteRequest"
import { ChangeCareerWriteRequest } from "./write/ChangeCareerWriteRequest"
import { ChangeJobChangeIntentionWriteRequest } from "./write/ChangeJobChangeIntentionWriteRequest"
import { ChangeJobWriteRequest } from "./write/ChangeJobWriteRequest"
import { ChangeOutputListWriteRequest } from "./write/ChangeOutputListWriteRequest"
import { ChangePublicStatusWriteRequest } from "./write/ChangePublicStatusWriteRequest"
import { ChangeSelfIntroductionWriteRequest } from "./write/ChangeSelfIntroductionWriteRequest"
import { ChangeSkillListWriteRequest } from "./write/ChangeSkillListWriteRequest"
import { ChangeSkillTagListWriteRequest } from "./write/ChangeSkillTagListWriteRequest"
import { ChangeSnsAccountListWriteRequest } from "./write/ChangeSnsAccountListWriteRequest"
import { ChangeSupplementaryInformationWriteRequest } from "./write/ChangeSupplementaryInformationWriteRequest"
import { ChangeUserProfileImageWriteRequest } from "./write/ChangeUserProfileImageWriteRequest"
import { ChangeWorkValueWriteRequest } from "./write/ChangeWorkValueWriteRequest"
import { CreateUserProfileWriteRequest } from "./write/CreateUserProfileWriteRequest"
import { UpdateUserLastAccessWriteRequest } from "./write/UpdateUserLastAccessWriteRequest"

export const UserProfile = {
  /**
   * read
   */
  read,
  /**
   * write
   */
  write,
  ChangeBaseInformationWriteRequest,
  ChangeCareerWriteRequest,
  ChangeJobChangeIntentionWriteRequest,
  ChangeJobWriteRequest,
  ChangeOutputListWriteRequest,
  ChangePublicStatusWriteRequest,
  ChangeSelfIntroductionWriteRequest,
  ChangeSkillListWriteRequest,
  ChangeSkillTagListWriteRequest,
  ChangeSnsAccountListWriteRequest,
  ChangeSupplementaryInformationWriteRequest,
  ChangeUserProfileImageWriteRequest,
  ChangeWorkValueWriteRequest,
  CreateUserProfileWriteRequest,
  UpdateUserLastAccessWriteRequest,
}
