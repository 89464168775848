import { Matching } from "./matching"
import { CorporationAccount } from "./corporationAccount"
import { FavoriteProject } from "./favoriteProject"
import { File } from "./file"
import { ProjectSearch } from "./projectSearch"
import { UserAccount } from "./userAccount"
import { ExternalNotification } from "./externalNotification"
import { UserProfile } from "./userProfile"
import { UserProfileSearch } from "./userProfileSearch"

export const User = {
  Matching,
  CorporationAccount,
  FavoriteProject,
  File,
  ProjectSearch,
  UserAccount,
  ExternalNotification,
  UserProfile,
  UserProfileSearch,
}
