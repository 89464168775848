



















import Vue, { PropType } from "vue"

export interface Option {
  label: string
  value: number
}

export default Vue.extend({
  props: {
    value: {
      type: Number,
      default: null,
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
  },
  methods: {
    isSelected(availableUptimeId: number): boolean {
      return this.value === availableUptimeId
    },
    handleInput(selectedAvailableUptimeId: number) {
      this.$emit("input", selectedAvailableUptimeId)
    },
    select(availableUptimeId: number) {
      // すでに選択している場合はvalueを更新しない
      if (this.isSelected(availableUptimeId)) {
        return
      }

      this.handleInput(availableUptimeId)
    },
  },
})
