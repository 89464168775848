import { ApiClient } from "~/backendApis/foundation/ApiClient"

const basePath = "/event_log/corporation_member"

export const write = {
  logAll: (
    params: UserProfileSearchedEventParams | UserProfileImpressionEventParams
  ) =>
    new ApiClient<void>().post(
      `${basePath}/${params.universalCorporationId}/event_log/log_all`,
      {
        version: params.version,
        type: params.type,
        items: (params.items as object[]).map((i) => JSON.stringify(i)),
      }
    ),
}

interface UserProfileSearchedEventParams {
  universalCorporationId: string
  version: 1
  type: "user_profile_searched_event"
  items: Array<{
    searchWord?: string
    smallJobCategoryIds?: number[]
    availableUptimeIds?: number[]
    prefectureIds?: number[]
    minAge?: number
    maxAge?: number
    sinceLastAccessDays?: number
    page: number
  }>
}

interface UserProfileImpressionEventParams {
  universalCorporationId: string
  version: 1
  type: "user_profile_impression_event"
  items: Array<{
    universalUid: string
    order: number
  }>
}
