/*
 * ブラウザ上で発生したエラーをキャプチャーしてログとして記録する設定をしている。
 * ログは本番環境ではRollbarに通知して、開発環境ではコンソールログとして出力するようにしている。
 *
 * エラーをキャプチャーするためにしている設定は以下３種
 *
 * 1. Vue.config.errorHandlerの設定
 * 2. Vue.config.warnHandlerの設定
 * 3. errorイベントをlistenする設定
 * 4. unhandledrejectionイベントをlistenする設定
 *
 * 多くのエラーは 1 の設定でキャプチャーできるが、それだけではキャプチャーできないものを 3, 4 の設定で拾うようにしている。
 * また 2 についてはdevelopmentモードでのみ出力されるwarnログ用の設定。
 * なお、 1, 2 でキャプチャーできるエラーについては、 3, 4 で対象としているイベントには伝播しない仕様となっているので、重複して捕捉されることはない。
 *
 * errorHandler - Vue.js
 * @see https://jp.vuejs.org/v2/api/#errorHandler
 */
import Vue from "vue"
import { clHelpers } from "~/clHelpers"
import { KnownErrorWarning } from "~/errorHandlers/models/KnownErrorWarning"
import { clAuth } from "~/clAuth"
import { authState } from "~/clAuth/authState"
import { errorNotifier } from "~/clHelpers/errorNotifier"

const defaultDebugLogTitle = "Captured In plugins/errorHandler"

const makeCurrentUserRolePlainObject = (): object =>
  JSON.parse(JSON.stringify(authState.getters.getRole()))

const isTimeoutError = (error: Error | string): boolean =>
  String(error).match("timeout of") !== null

const isNetworkError = (error: Error | string): boolean =>
  ["Network Error", "client is offline"].some(
    (str) => String(error).match(str) !== null
  )

const isChunkError = (error: Error | string): boolean =>
  String(error).match("ChunkLoadError") !== null

const isIgnoreError = (error: Error | string): boolean => {
  const isIgnoreErrorList = ["HierarchyRequestError", "TypeError"]

  // staging環境の場合、追加でエラーを無視するエラーを設定
  if (process.env.APP_ENV === "staging") {
    // VPN接続が切れた際に発生するエラーを無視する
    isIgnoreErrorList.push("Request failed with status code 403")
  }

  return isIgnoreErrorList.some((str) => String(error).match(str) !== null)
}

const isWarningLevelError = (error: Error | string): boolean =>
  error instanceof KnownErrorWarning

const handleError = (handlerName: string, error: Error | string) => {
  /*
   * 開発環境でのデバック用の処理
   */
  const title = `${defaultDebugLogTitle} by ${handlerName}`
  clHelpers.debugLogger.printList({
    title,
    contents: [
      error,
      `UserId : ${clAuth.userId}`,
      `CorporationId : ${clAuth.corporationId}`,
      makeCurrentUserRolePlainObject(),
      `Stack Trace : ${error instanceof Error ? error.stack : "NOTHING"}`,
    ],
    logType: "ERROR",
  })

  /*
   * エラー通知処理 & Alert
   */
  if (isChunkError(error)) {
    alert(
      "ネットワークエラーが発生しました。リロードし、ページを再読み込みします。"
    )
    window.location.reload()
  } else if (isNetworkError(error)) {
    // Network Error の場合、クライアント側のネットワーク状況の問題である可能性が高いため、強制リロードはせずクライアント側のタイミングでリロードできるようにしている。
    alert(
      "ネットワークエラーが発生しました。リロードし、ページを再読み込みしてください。"
    )
  } else if (isTimeoutError(error)) {
    alert(
      "タイムアウトが発生しました。ネットワーク環境をご確認の上、ページを再読み込みしてください。"
    )
  } else if (isIgnoreError(error)) {
    // Rollbarに通知しないエラーを除外する。
    // 除外するエラーは慎重に議論して追加すること。
  } else if (isWarningLevelError(error)) {
    errorNotifier.warning(error)
  } else {
    errorNotifier.error(error)
  }
}

/*
 * 1. Vue.config.errorHandlerの設定
 */
Vue.config.errorHandler = (error: Error) => {
  const handlerName = "Vue Config Error Handler"
  handleError(handlerName, error)
}

/*
 * 2. Vue.config.warnHandlerの設定
 * developmentモードで起動しているのみのみキャプチャーされるため、debugログの設定のみをしている。
 */
Vue.config.warnHandler = (message: string) => {
  const title = `${defaultDebugLogTitle} - By Vue.config.warnHandler`
  clHelpers.debugLogger.print({ title, content: message, logType: "WARN" })
}

/*
 * 3. errorイベントをlistenする設定
 */
window.addEventListener("error", (event: ErrorEvent) => {
  const handlerName = "Error Event Listener"
  handleError(handlerName, event.error)

  // handleErrorで設定したデバックログが出力されるので、コンソールへの標準の出力を無効にしている。
  event.preventDefault()
})

/*
 * 4. unhandledrejectionイベントをlistenする設定
 */
window.addEventListener(
  "unhandledrejection",
  (event: PromiseRejectionEvent) => {
    const handlerName = "Unhandledrejection Event Listener"
    handleError(handlerName, event.reason)

    // handleErrorで設定したデバックログが出力されるので、コンソールへの標準の出力を無効にしている。
    event.preventDefault()
  }
)
