import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { UserMailNotificationView } from "~/backendApis/viewModel/externalNotification"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

export class GetMyUserMailNotificationReadRequest extends ReadRequest<UserMailNotificationView> {
  private readonly endpoint: string =
    "/external_notification/user/user_mail_notification/my"

  public get(): Promise<UserMailNotificationView | null> {
    return this.apiClient.get(this.endpoint).catch((e: ApplicationApiError) => {
      if (e.isNotFoundError()) {
        return null
      }
      throw e
    })
  }
}
