import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  invitationId: string
}

export class ApproveCorporationMemberInvitationWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/corporation_account/user/corporation_member_invitation_reply/approve"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
