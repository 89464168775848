/**
 * write
 */
import { UploadCorporationLogoImageWriteRequest } from "./write/UploadCorporationLogoImageWriteRequest"
import { UploadLeadingCompanyLogoImageWriteRequest } from "./write/UploadLeadingCompanyLogoImageWriteRequest"
import { UploadUserProfileImageWriteRequest } from "./write/UploadUserProfileImageWriteRequest"
import { UploadUserProfileOutputFileWriteRequest } from "./write/UploadUserProfileOutputFileWriteRequest"
import { UploadMatchingFileWriteRequest } from "./write/UploadMatchingFileWriteRequest"

export const File = {
  /**
   * write
   */
  UploadCorporationLogoImageWriteRequest,
  UploadLeadingCompanyLogoImageWriteRequest,
  UploadUserProfileImageWriteRequest,
  UploadUserProfileOutputFileWriteRequest,
  UploadMatchingFileWriteRequest,
}
