/**
 * Stateの定義
 */
import Vue from "vue"
import {
  AccountMetadata,
  buildDefaultAccountMetaData,
  Role,
  buildUnkownUserRole,
} from "./model"
import {
  ContractTypeView,
  CorporationAccountView,
  FullInfoUserProfileView,
} from "~/backendApis/viewModel"

interface AuthState {
  /**
   * 利用中のユーザーアカウントのユーザーID
   */
  userId: string | null
  /**
   * 切り替え中の企業アカウントの企業ID
   */
  corporationId: string | null
  /**
   * アカウントの付属情報
   */
  accountMetadata: AccountMetadata
  /**
   * 現在のロール（ロールに対して権限が割り当てられており、特定のアクションが実行可能かどうかを判定するために利用される。）
   */
  role: Role
  /**
   * 利用中のユーザープロフィール
   */
  userProfile: FullInfoUserProfileView | null
  /**
   * 認証処理が完了したかどうかを示すフラグ
   */
  finishedAuthProcess: boolean
  /**
   * 企業アカウントの契約タイプ
   */
  contractType: ContractTypeView | null
  /**
   * 企業アカウントのプロフィール
   */
  corporationProfile: CorporationAccountView | null
}

// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
export const state: AuthState = Vue.observable<AuthState>({
  userId: null,
  corporationId: null,
  accountMetadata: buildDefaultAccountMetaData(),
  role: buildUnkownUserRole(),
  userProfile: null,
  finishedAuthProcess: false,
  contractType: null,
  corporationProfile: null,
})
