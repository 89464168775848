















































import Vue from "vue"
import {
  makeSwitchableAccountList,
  SwitchableAccount,
} from "~/uiLogics/switchableAccount/switchableAccountsMaker"
import MaterialIcon, {
  OUTLINED,
} from "~/components/guideline/icon/MaterialIcon.vue"
import { UserRole } from "~/config/acl/userRoleList"

interface DataType {
  accountList: SwitchableAccount[]
  isVisible: boolean
  iconType: {
    OUTLINED: string
  }
}

export default Vue.extend({
  components: {
    MaterialIcon,
  },
  data(): DataType {
    return {
      accountList: [],
      isVisible: false,
      iconType: {
        OUTLINED,
      },
    }
  },
  computed: {
    currentAccountImageUrl(): string | null {
      return this.$clAuth.accountMetadata.imageUrl
    },
    currentAccountName(): string {
      if (this.$clAuth.corporationProfile) {
        return this.$clAuth.corporationProfile.name
      }
      if (this.$clAuth.userProfile) {
        return (
          this.$clAuth.userProfile.lastName +
          " " +
          this.$clAuth.userProfile.firstName
        )
      }
      return ""
    },
    watchedTargetForCorporationId(): string | null {
      return this.$clAuth.corporationId
    },
    // 企業担当者アカウント
    isMember(): boolean {
      return this.$clAuth.userRole === UserRole.CORPORATION
    },
  },
  watch: {
    watchedTargetForCorporationId() {
      this.initAccountList()
    },
  },
  created() {
    this.initAccountList()
  },
  methods: {
    initAccountList() {
      if (this.$clAuth.loggedIn && this.$clAuth.userProfile) {
        // 新規作成直後はプロフィールを取得できないため、処理しない
        makeSwitchableAccountList({ router: this.$nuxt.$router }).then(
          (accountList) => {
            this.accountList = accountList
            this.isVisible = accountList.length > 0
          }
        )
      }
    },
  },
})
