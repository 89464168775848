import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  projectId: string
  publicationPeriod: number
}

export class ChangeSettingWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { publicationPeriod } = this.params
    return this.apiClient.post(this.makeEndpoint(), { publicationPeriod })
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectId } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/${projectId}/setting/change`
  }
}
