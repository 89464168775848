/**
 * read
 */
// import { CountTotalHitsOldAlgoliaReadRequest } from "./read/CountTotalHitsOldAlgoliaReadRequest"
import { CountTotalHitsReadRequest } from "./read/CountTotalHitsReadRequest"
import { GetCurrentUserProfileReadRequest } from "./read/GetCurrentUserProfileReadRequest"
import { GetUserProfileReadRequest } from "./read/GetUserProfileReadRequest"
// import { SearchOldAlgoliaUserProfileListReadRequest } from "./read/SearchOldAlgoliaUserProfileListReadRequest"
import { SearchUserProfileListReadRequest } from "./read/SearchUserProfileListReadRequest"

export const UserProfileSearch = {
  /**
   * read
   */
  // CountTotalHitsOldAlgoliaReadRequest,
  CountTotalHitsReadRequest,
  GetCurrentUserProfileReadRequest,
  GetUserProfileReadRequest,
  // SearchOldAlgoliaUserProfileListReadRequest,
  SearchUserProfileListReadRequest,
}
