import { UserOperatingEventListener } from "~/uiLogics/applicationRevision/userOperatingEventListener"

/*
 * ユーザーの操作状況の監視を責務とするクラス。
 * ユーザーが操作中か一定期間操作していないのかという状態を持っている。
 */
export class UserOperatingStatusWatcher {
  // ユーザーの操作状況を確認する間隔（30分）
  private static INTERVAL_TIME = 1000 * 60 * 30

  private static ACTIVE_OPERATING_STATUS = "ACTIVE"
  private static INACTIVE_OPERATING_STATUS = "INACTIVE"

  private operationgStatus:
    | typeof UserOperatingStatusWatcher.ACTIVE_OPERATING_STATUS
    | typeof UserOperatingStatusWatcher.INACTIVE_OPERATING_STATUS =
    UserOperatingStatusWatcher.ACTIVE_OPERATING_STATUS
  private timerId: NodeJS.Timeout | null = null

  private readonly userOperatingEventListener: UserOperatingEventListener

  constructor(userOperatingEventListener: UserOperatingEventListener) {
    this.userOperatingEventListener = userOperatingEventListener
  }

  startWatching(): void {
    this.setUserOperationStatusToInactiveSchedule()
    this.userOperatingEventListener.startListening(
      this.resetUserOperationStatusToInactiveSchedule.bind(this)
    )
  }

  stopWatching(): void {
    this.deleteUserOperationStatusToInactiveSchedule()
    this.userOperatingEventListener.stopListening()
  }

  isOperating(): boolean {
    return (
      this.operationgStatus ===
      UserOperatingStatusWatcher.ACTIVE_OPERATING_STATUS
    )
  }

  private setUserOperationStatusToInactiveSchedule(): void {
    this.timerId = setTimeout(
      this.changeOperatingStatusToInactive.bind(this),
      UserOperatingStatusWatcher.INTERVAL_TIME
    )
  }

  private deleteUserOperationStatusToInactiveSchedule(): void {
    if (this.timerId !== null) {
      clearTimeout(this.timerId)
    }
  }

  private resetUserOperationStatusToInactiveSchedule(): void {
    this.changeOperatingStatusToActive()

    this.deleteUserOperationStatusToInactiveSchedule()
    this.setUserOperationStatusToInactiveSchedule()
  }

  private changeOperatingStatusToActive(): void {
    this.operationgStatus = UserOperatingStatusWatcher.ACTIVE_OPERATING_STATUS
  }

  private changeOperatingStatusToInactive(): void {
    this.operationgStatus = UserOperatingStatusWatcher.INACTIVE_OPERATING_STATUS
  }

  static build(): UserOperatingStatusWatcher {
    return new UserOperatingStatusWatcher(UserOperatingEventListener.build())
  }
}
