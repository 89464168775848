import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { WpDownloadFormView } from "~/backendApis/viewModel"

const basePath = "/landing_page/general"

export const read = {
  getWpDownloadForm: (id: string) =>
    new ApiClient<WpDownloadFormView>().get(
      `${basePath}/wp_download_form/show/${id}`
    ),
}
