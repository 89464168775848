import { corporationAccount } from "./corporationAccount"
import { project } from "./project"
import { projectSearch } from "./projectSearch"
import { shared } from "./shared"
import { userAccount } from "./userAccount"
import { userProfile } from "./userProfile"
import { userProfileSearch } from "./userProfileSearch"
import { landingPage } from "./landingPage"

export const general = {
  corporationAccount,
  project,
  projectSearch,
  shared,
  userAccount,
  userProfile,
  userProfileSearch,
  landingPage,
}
