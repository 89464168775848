import ApplicationApiError from "../../../../foundation/ApplicationApiError"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ChatGroupView } from "~/backendApis/viewModel/matching"

interface Params {
  chatGroupId: string
}

export class GetChatGroupByIdReadRequest extends ReadRequest<ChatGroupView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ChatGroupView | null> {
    return this.apiClient
      .get(this.makeEndpoint())
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      })
  }

  private makeEndpoint(): string {
    return `/matching/user/chat_group/${this.params.chatGroupId}/show`
  }
}
