/**
 * read
 */
import { GetFavoriteProjectListReadRequest } from "./read/GetFavoriteProjectListReadRequest"

export const ProjectSearch = {
  /**
   * read
   */
  GetFavoriteProjectListReadRequest,
}
