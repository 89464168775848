import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ProjectView } from "~/backendApis/viewModel/projectSearch"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

interface Params {
  universalCorporationId: string
  projectId: string
}

export class GetProjectReadRequest extends ReadRequest<ProjectView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ProjectView | null> {
    return this.apiClient
      .get(this.makeEndpoint())
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      })
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectId } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/show/${projectId}`
  }
}
