import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { NumberOfUnreadMessagesView } from "~/backendApis/viewModel"

export class GetNumberOfUnreadMessagesReadRequest extends ReadRequest<NumberOfUnreadMessagesView> {
  private readonly endpoint: string =
    "/matching/user/message/number_of_unread_messages"

  public get(): Promise<NumberOfUnreadMessagesView> {
    return this.apiClient.get(this.endpoint)
  }
}
