import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { RecruitmentWorkingFormListView } from "~/backendApis/viewModel"

const basePath = "/project/general"

export const read = {
  getRecruitmentWorkingFormList: () =>
    new ApiClient<RecruitmentWorkingFormListView>().get(
      `${basePath}/master/recruitment_working_form/list`
    ),
}
