











import Vue from "vue"
import FloatingAlert, {
  floatingAlertSuccessType,
  floatingAlertErrorType,
  emitEventWhenClickMessage,
} from "~/components/guideline/notice/FloatingAlert.vue"

interface DataType {
  isShown: boolean
  timeIds: NodeJS.Timeout[]
}

export default Vue.extend({
  components: {
    FloatingAlert,
  },
  data(): DataType {
    return {
      isShown: false,
      timeIds: [],
    }
  },
  computed: {
    emitEvents() {
      return { emitEventWhenClickMessage }
    },
    flashMessage(): string {
      return this.$clStores.flashMessage.getters.message
    },
    flashType(): string | null {
      if (this.$clStores.flashMessage.getters.isSuccess) {
        return floatingAlertSuccessType
      } else if (this.$clStores.flashMessage.getters.isError) {
        return floatingAlertErrorType
      }
      return null
    },
  },
  watch: {
    flashMessage(newFlashMessage) {
      if (newFlashMessage !== "") {
        this.clearAllTimeOut()
        this.isShown = true
        this.setHideSchedule()
      }
    },
  },
  methods: {
    hide() {
      this.clearAllTimeOut()
      this.isShown = false

      const timeId = setTimeout(() => {
        this.$clStores.flashMessage.actions.initialize()
      }, 500)
      this.timeIds.push(timeId)
    },
    setHideSchedule() {
      const timeId = setTimeout(() => {
        this.isShown = false
      }, 4000)
      this.timeIds.push(timeId)

      const timeId2 = setTimeout(() => {
        this.$clStores.flashMessage.actions.initialize()
        this.timeIds = []
      }, 4500)
      this.timeIds.push(timeId2)
    },
    clearAllTimeOut() {
      this.timeIds.forEach((timeId) => {
        clearTimeout(timeId)
      })
      this.timeIds = []
    },
  },
})
