

















import Vue from "vue"

export default Vue.extend({
  props: {
    isSignup: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    text() {
      return this.isSignup ? "登録" : "ログイン"
    },
    handleClick(): void {
      this.$emit("click")
    },
  },
})
