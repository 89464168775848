import { main } from "./main"
import { publicOGP } from "./publicOGP"

export const clApi = {
  main,
  publicOGP,
}

export interface CLApiSchema {
  main: typeof main
  publicOGP: typeof publicOGP
}
