/**
 * Getterの定義
 */
import { AccountMetadata, Role, hasPermission, limitCount } from "./model"
import { state } from "./state"
import { Acl } from "~/config/acl/accessControlList"
import {
  ContractTypeView,
  CorporationAccountView,
  FullInfoUserProfileView,
} from "~/backendApis/viewModel"

export const getters = {
  hasPermission: (action: Acl): boolean => hasPermission(state.role, action),
  limitCount: (action: Acl): number | null => limitCount(state.role, action),
  isLoggedIn: (): boolean => !!state.userId,
  getUserId: (): string | null => state.userId,
  getCorporationId: (): string | null => state.corporationId,
  getAccountMetadata: (): AccountMetadata => state.accountMetadata,
  getRole: (): Role => state.role,
  getUserProfile: (): FullInfoUserProfileView | null => state.userProfile,
  isFinishedAuthProcess: (): boolean => state.finishedAuthProcess,
  getContractType: (): ContractTypeView | null => state.contractType,
  getCorporationProfile: (): CorporationAccountView | null =>
    state.corporationProfile,
}
