import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  address: string
  email: string
  logoImagePath: string | null
  name: string
  phoneNumber: string
  websiteUrl: string
}

export class ChangeCorporationProfileAndContactInformationWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const {
      address,
      email,
      logoImagePath,
      name,
      phoneNumber,
      websiteUrl,
    } = this.params
    return this.apiClient.post(this.makeEndpoint(), {
      address,
      email,
      logoImagePath,
      name,
      phoneNumber,
      websiteUrl,
    })
  }

  private makeEndpoint(): string {
    return `/corporation_account/corporation_member/${this.params.universalCorporationId}/corporation_profile_and_contact_information/change`
  }
}
