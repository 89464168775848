/*
 * # 説明
 * ユーザーの最終アクセス時刻を記録している。
 * 初期表示を含めたページ遷移のタイミングで記録するが、記録毎に一定のインターバルを設けて、その間は記録をしないようにしている。
 * これは毎回APIにリクエストするのはパフォーマンスや負荷的によくないことが理由。
 */
import dayjs from "dayjs"
import { Context } from "@nuxt/types"
import { BackendApi } from "~/backendApis"
import { clAuth } from "~/clAuth"

const localStorageKey = "user_last_access_updater_last_datetime"

// インターバル
const interval = 1000 * 60 * 30 // 30分

// 更新する必要があるかどうかを判定
const needToUpdate = (): boolean => {
  const lastDateTime = localStorage.getItem(localStorageKey)

  // 一度も記録していない場合は無条件で記録する。
  if (!lastDateTime) {
    return true
  }

  const lastRecordDateTime = dayjs(lastDateTime)
  const now = dayjs()
  const diff = now.diff(lastRecordDateTime)
  return interval < diff
}

/**
 * 必要な時だけ最終アクセス時刻を更新。
 */
const updateUserLastAccessIfNecessary = () => {
  // 認証が完了していない場合は認証を待ってから処理を実行するために開始時間を遅らせている。
  const delayTime = clAuth.finishedAuthProcess ? 0 : 5000

  setTimeout(() => {
    if (clAuth.loggedIn && needToUpdate()) {
      new BackendApi.User.UserProfile.UpdateUserLastAccessWriteRequest().post()
      localStorage.setItem(localStorageKey, dayjs().toString())
    }
  }, delayTime)
}

export default ({ app }: Context) => {
  // 認証はクライアントサイドで行っているため、本処理もクライアント側でのみ利用している。そのため、何らかの理由でサーバーサイド（SSR）で実行された場合は例外が発生する。
  if (process.server) {
    throw new Error("このプラグインはクライアントサイドでのみ実行可能です")
  }

  if (!app.router) {
    throw new Error("Contextにrouterが存在しません")
  }

  // 初回描画時含めてページ遷移ごとに実行される。
  app.router.afterEach(updateUserLastAccessIfNecessary)
}
