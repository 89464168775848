import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  projectId: string
  publishedEndDate: string
}

export class ChangeProjectStatusPublishedWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { publishedEndDate } = this.params
    return this.apiClient.post(this.makeEndpoint(), { publishedEndDate })
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectId } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/${projectId}/status/published`
  }
}
