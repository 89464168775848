/**
 * プロフィール検索のインプレッションイベントの記録に関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { clAuth } from "~/clAuth"
import { BackendApi } from "~/backendApis"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface Impression {
  userId: string
  order: number
}
interface ProfileSearchImpressionEventState {
  /**
   * 記録済みのインプレッション
   */
  loggedImpressions: Impression[]
  /**
   * 記録予定のインプレッション
   * 極力サーバー側への負担を軽減するために、インプレッションは一定時間保持してまとめて記録するようにしている。
   */
  reservedImpressions: Impression[]
}
const makeDefaultState = () => ({
  loggedImpressions: [],
  reservedImpressions: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProfileSearchImpressionEventState = Vue.observable<ProfileSearchImpressionEventState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.loggedImpressions = defaultState.loggedImpressions
  state.reservedImpressions = defaultState.reservedImpressions
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  resetLoggedImpressionList() {
    state.loggedImpressions = []
  },
  resetReservedImpressionList() {
    state.reservedImpressions = []
  },
  appendLoggedImpressions(impressions: Impression[]) {
    // idをキーに重複を除外している。
    const uniqueProfiles = [
      ...state.loggedImpressions,
      ...impressions.filter((i1) =>
        state.loggedImpressions.every((i2) => i1.userId !== i2.userId)
      ),
    ]
    state.loggedImpressions = uniqueProfiles
  },
  appendReservedImpressions(impressions: Impression[]) {
    // idをキーに重複を除外している。
    const uniqueProfiles = [
      ...state.reservedImpressions,
      ...impressions.filter(
        (i1) =>
          state.loggedImpressions.every((i2) => i1.userId !== i2.userId) &&
          state.reservedImpressions.every((i2) => i1.userId !== i2.userId)
      ),
    ]
    state.reservedImpressions = uniqueProfiles
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const performLogging = () => {
  // 配列操作をしている間に新規のインプレッションが入って更新されるのを考慮しないでいいように別の定数に中身を移して記録予定のインプレッションはすぐに初期化する。
  const targets = state.reservedImpressions
  mutations.resetReservedImpressionList()
  const items = targets
    // 記録済みのIDに含まれていないIDのみに抽出する。
    .filter((impression1) =>
      state.loggedImpressions.every(
        (impression2) => impression1.userId !== impression2.userId
      )
    )
    .map((impression) => ({
      universalUid: impression.userId,
      order: impression.order,
    }))

  if (items.length === 0) {
    return
  }

  // インプレッションイベントを記録
  BackendApi.CorporationMember.eventLog.write.logAll({
    universalCorporationId: clAuth.corporationIdOrFail,
    version: 1,
    type: "user_profile_impression_event",
    items,
  })
  mutations.appendLoggedImpressions(targets)
}
let timerId: ReturnType<typeof setTimeout> | null = null
const actions = {
  /**
   * インプレッションを記録するための処理。
   * 同じユーザーに対する重複したインプレッションは本関数内の処理で除外されるため、呼び出し元で重複を考慮する必要はない。
   */
  logAllImpressions(impressions: Impression[]) {
    if (!clAuth.corporationId) {
      // 企業アカウントに切り替え中の際のみ有効
      return
    }

    mutations.appendReservedImpressions(impressions)

    // スクロールの度に記録するのだとサーバー側の負担がかなり大きくなるため、最低1秒溜めてからまとめてインプレッションを記録するようにしている。
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(performLogging, 1000)

    // 記録予定のIDが500件を超えた場合は例外ですぐに記録処理を実行する。
    if (state.reservedImpressions.length >= 500) {
      performLogging()
    }
  },
  /**
   * （記録済みのものを含めた）インプレッションを初期化する。
   * 検索条件が変わった際に実行される。
   */
  reset() {
    mutations.resetLoggedImpressionList()
    mutations.resetReservedImpressionList()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const profileSearchImpressionEvent = { getters, actions }
