















import Vue from "vue"
import MaterialIcon, {
  OUTLINED,
} from "~/components/guideline/icon/MaterialIcon.vue"

export default Vue.extend({
  components: {
    MaterialIcon,
  },
  props: {
    defaultIcon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconType() {
      return { OUTLINED }
    },
  },
})
