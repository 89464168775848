import { ApiClient } from "~/backendApis/foundation/ApiClient"

const basePath = "/user_account/general"

export const write = {
  createUserAccount: (params: { email: string | null }) =>
    new ApiClient<void>().post(`${basePath}/user_account/create`, params),

  createTemporaryAccount: (params: { email: string }) =>
    new ApiClient<void>().post(`${basePath}/temporary_account/create`, params),
}
