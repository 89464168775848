import { CountFavoriteForProjectsReadRequest } from "./read/CountFavoriteForProjectsReadRequest"
import { CountPublishedProjectsReadRequest } from "./read/CountPublishedProjectsReadRequest"
import { ChangeFeatureWriteRequest } from "./write/ChangeFeatureWriteRequest"
import { ChangeDetailWriteRequest } from "./write/ChangeDetailWriteRequest"
import { ChangeProjectStatusWriteRequest } from "./write/ChangeProjectStatusWriteRequest"
import { ChangeProjectStatusPublishedWriteRequest } from "./write/ChangeProjectStatusPublishedWriteRequest"
import { ChangeTitleWriteRequest } from "./write/ChangeTitleWriteRequest"
import { ChangeSettingWriteRequest } from "./write/ChangeSettingWriteRequest"
import { CopyProjectWriteRequest } from "./write/CopyProjectWriteRequest"
import { write } from "./write"

export const Project = {
  /**
   * read
   */
  CountFavoriteForProjectsReadRequest,
  CountPublishedProjectsReadRequest,

  /**
   * write
   */
  write,
  ChangeFeatureWriteRequest,
  ChangeDetailWriteRequest,
  ChangeProjectStatusWriteRequest,
  ChangeProjectStatusPublishedWriteRequest,
  ChangeTitleWriteRequest,
  ChangeSettingWriteRequest,
  CopyProjectWriteRequest,
}
