import dayjs from "dayjs"

export interface AvailableUptimeListView {
  list: {
    id: number
    name: string
  }[]
}

export interface ContactableTypeListView {
  list: {
    id: number
    name: string
  }[]
}

export interface JobChangeIntentionListView {
  list: {
    id: number
    name: string
  }[]
}

export interface PersonnelScaleListView {
  list: {
    id: number
    name: string
  }[]
}

export interface WorkFormListView {
  list: {
    id: number
    name: string
  }[]
}

export interface LeadingCompany {
  id: string
  name: string
}

export interface LeadingCompanyListView {
  list: LeadingCompany[]
}

export interface UserProfileCumulativeStatsView {
  numberOfTotalProfile: number
  numberOfDeletedProfile: number
  numberOfEachStatusProfile: {
    openToPublic: number
    openToUsers: number
    close: number
  }
}

export interface Skill {
  id: string
  text: string
}

export interface SkillListView {
  list: Skill[]
}

export interface ProfileViewedEventCountView {
  value: number
}

export interface ProfileViewedByCorporationEventView {
  id: string
  universalUidOfProfileOwner: string
  universalCorporationIdOfViewer: string
  viewedDateTime: string
}

export interface ProfileViewedByCorporationEventListView {
  list: ProfileViewedByCorporationEventView[]
}

export interface ViewEvent {
  id: string
  viewedDate: string
  viewedTime: string
  linkToProfileOfViewer: string
  nameOfViewer: string
}

export const decorateProfileViewedByCorporationEventView = (
  view: ProfileViewedByCorporationEventView
) => ({
  generateViewEventObject: (corporationName: string): ViewEvent => {
    return {
      id: view.id,
      viewedDate: dayjs(new Date(view.viewedDateTime)).format("MM/DD"),
      viewedTime: dayjs(new Date(view.viewedDateTime)).format("HH:mm"),
      linkToProfileOfViewer: `/corporation/profiles/${view.universalCorporationIdOfViewer}`,
      nameOfViewer: corporationName,
    }
  },
})

export interface SexListView {
  list: {
    id: number
    name: string
  }[]
}

export type RewardType = "hourly" | "monthly" | "negotiable"

export const convertRewardTypeToLabel = (rewardType: RewardType) =>
  ({
    hourly: "時給",
    monthly: "月固定",
    negotiable: "応相談",
  }[rewardType])
