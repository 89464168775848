










import Vue, { PropType } from "vue"
import colors from "~/config/color"

const smallCircleSize = 30
const middleCircleSize = 50
const largeCircleSize = 70

type SizeType = "small" | "middle" | "large"

export default Vue.extend({
  props: {
    sizeType: {
      type: String as PropType<SizeType>,
      default: "middle",
    },
    isCenter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    circleSize(): number {
      if (this.sizeType === "small") {
        return smallCircleSize
      } else if (this.sizeType === "large") {
        return largeCircleSize
      }
      return middleCircleSize
    },
    color() {
      return { mainColor: colors.mainColor }
    },
  },
})
