import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  experienceCorporations: {
    corporationName: string
    leadingCompanyId: string | null
    isCurrentCorporation: boolean
    joinReason: string | null
    workSpan: {
      startYear: number
      startMonth: number
      endYear: number | null
      endMonth: number | null
    }
    experienceProjects: {
      name: string
      personnelScaleId: number
      relatedUrl: string | null
      role: string
      achievedContent: string | null
      workContent: string
    }[]
  }[]
}

export class ChangeCareerWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string = "/user_profile/user/edit/career/change"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
