import { FavoriteUserListView } from "~/backendApis/viewModel/userProfileSearch"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"

interface Params {
  universalCorporationId: string
}

export class GetFavoriteUserListReadRequest extends ReadRequest<FavoriteUserListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<FavoriteUserListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/user_profile_search/corporation_member/${this.params.universalCorporationId}/favorite_user/list`
  }
}
