/**
 * read
 */
import { GetCorporationMemberListReadRequest } from "./read/GetCorporationMemberListReadRequest"
import { GetJoiningCorporationAccountReadRequest } from "./read/GetJoiningCorporationAccountReadRequest"
import { GetMyCorporationMemberInfoReadRequest } from "./read/GetMyCorporationMemberInfoReadRequest"

/**
 * write
 */
import { ChangeCorporationProfileAndContactInformationWriteRequest } from "./write/ChangeCorporationProfileAndContactInformationWriteRequest"
import { InviteCorporationMemberByEmailWriteRequest } from "./write/InviteCorporationMemberByEmailWriteRequest"
import { InviteCorporationMemberWriteRequest } from "./write/InviteCorporationMemberWriteRequest"
import { RemoveCorporationMemberListWriteRequest } from "./write/RemoveCorporationMemberListWriteRequest"

export const CorporationAccount = {
  /**
   * read
   */
  GetCorporationMemberListReadRequest,
  GetJoiningCorporationAccountReadRequest,
  GetMyCorporationMemberInfoReadRequest,

  /**
   * write
   */
  ChangeCorporationProfileAndContactInformationWriteRequest,
  InviteCorporationMemberWriteRequest,
  InviteCorporationMemberByEmailWriteRequest,
  RemoveCorporationMemberListWriteRequest,
}
