import Vue, { VNode } from "vue"

const splitByNewLine = (text: string, createElement: any): (string | VNode)[] =>
  text.split("\n").reduce((allElms: (string | VNode)[], elm: string): (
    | string
    | VNode
  )[] => {
    if (allElms.length === 0) {
      return [elm]
    }
    return allElms.concat([createElement("br"), elm])
  }, [])

// TODO(Shokei Takanashi)
// 正規表現を２種類定義していたり、条件分岐が多かったりと少し複雑なので、後々もうちょいうまい方法がないか調べてリファクタする。
// （なお、同様の処理を行うVue用のライブラリとして vue-linkify があったが、v-htmlを利用することを前提としており、XSSの貧弱性を懸念して採用しなかった。）
const text2link = (
  text: string,
  createElement: any,
  nl2brDisabled: boolean
): (string | VNode)[] => {
  const separatorRegex = /(https?:\/\/[^ 　\n]+)/ // eslint-disable-line no-irregular-whitespace
  const testRegex = /^https?:\/\/[^ 　\n]+$/ // eslint-disable-line no-irregular-whitespace

  return text
    .split(separatorRegex)
    .reduce((allElms: (string | VNode)[], elm: string): (string | VNode)[] => {
      if (testRegex.test(elm)) {
        return [
          ...allElms,
          createElement(
            "a",
            {
              attrs: {
                href: elm,
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
            elm
          ),
        ]
      } else if (nl2brDisabled) {
        return [...allElms, elm]
      }
      return [...allElms, ...splitByNewLine(elm, createElement)]
    }, [])
}

Vue.component("Text2link", {
  functional: true,
  props: {
    tag: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: null,
    },
    nl2brDisabled: {
      type: Boolean,
      default: false,
    },
  },
  render: (createElement: any, context) => {
    const { tag, text, className, nl2brDisabled } = context.props

    const elms = text2link(text, createElement, nl2brDisabled)

    return createElement(tag, { class: className }, elms)
  },
})
