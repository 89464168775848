/**
 * 前回ログインに使った方法を保持する
 *
 * "password": メールアドレス/パスワード認証
 * "facebook.com": Facebook認証
 * "google.com": Google認証
 */
const globalKey = "lastLoginMethod"

export const setLastLoginMethod = (method: string): void => {
  localStorage.setItem(globalKey, method)
}

export const removeLastLoginMethod = (): void => {
  localStorage.removeItem(globalKey)
}

export const getLastLoginMethod = (): string | null => {
  return localStorage.getItem(globalKey)
}

export const isPassword = (): boolean => {
  return localStorage.getItem(globalKey) === "password"
}

export const isFacebook = (): boolean => {
  return localStorage.getItem(globalKey) === "facebook.com"
}

export const isGoogle = (): boolean => {
  return localStorage.getItem(globalKey) === "google.com"
}
