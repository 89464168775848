



















































import Vue, { PropType } from "vue"
import MobileBottomNavigationBar from "./MobileBottomNavigationBar.vue"
import MobileHeaderNavigationBar from "./MobileHeaderNavigationBar.vue"
import PcNavigationBar from "./PcNavigationBar.vue"
import { NormalNavigationItem, ImageIconNavigationItem } from "./type"
import CDrawrMenu from "~/components/custom/drawrMenu/CDrawrMenu.vue"

export default Vue.extend({
  components: {
    MobileBottomNavigationBar,
    MobileHeaderNavigationBar,
    PcNavigationBar,
    CDrawrMenu,
  },
  props: {
    navItems: {
      type: Array as PropType<NormalNavigationItem[]>,
      required: true,
    },
    imageIcon: {
      type: Object as PropType<ImageIconNavigationItem>,
      default: null,
    },
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    isVisibleHeaderNav: {
      type: Boolean,
      required: true,
    },
    isVisibleBottomNav: {
      type: Boolean,
      required: true,
    },
    headerTitle: {
      type: String,
      default: null,
    },
    headerPreviousPagePath: {
      type: String,
      default: null,
    },
    headerPreviousIcon: {
      type: String,
      default: "arrow_back",
    },
  },
  data() {
    return {
      isVisibleMenu: false,
    }
  },
  watch: {
    $route() {
      // ページ遷移でメニュー閉じる
      this.closeMenu()
    },
  },
  methods: {
    handleOpenLoginModal() {
      this.$emit("open-login-modal")
    },
    handleOpenSignupModal() {
      this.$emit("open-signup-modal")
    },
    openMenu() {
      this.isVisibleMenu = true
    },
    closeMenu() {
      this.isVisibleMenu = false
    },
  },
})
