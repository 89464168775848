/*
 * # アクションログ収集の仕組みについて
 * 初回描画含めてページ遷移する度にクライアントサイドでアクションログの内容が作成されてサーバー側に送信されるようになっている。
 * MPA(Multi Page Application)と違いSPA(Single Page Application)ではフロントエンドサーバーにアクセスするのが初回のみで、
 * それ以降は必要なリソースのみを取得してページを部分的に更新していくため、通常のサーバーサイドでアクションログを記録するような構成が採用できない。
 * そのため、ページ遷移ごとにクライアントサイドからアクションログを送信する仕組みを採用している。
 *
 * # 備考
 * 本処理で用意した値をAPI側にPOSTする際に、値を暗号化して、API側で複合化するようにしている。
 * このようにしたのは、APIへの不正リクエストによりアクションログが荒らされるリスクを軽減したかったため。
 * 完璧な対策ではないが、仮に不正リクエストでログが荒らされてもユーザーへの影響がなかったことと、
 * シークレットキーが流出した場合は、更新することも可能なので、一旦このようなやり方を採った。
 */
import { Context } from "@nuxt/types"
import { Route } from "vue-router"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { auth } from "~/plugins/firebase"

const loggedInGlobalKey = "loggedIn"
const corporationIdGlobalKey = "switchedCorporationAccountId"

const CryptoJS = require("crypto-js")

// デバイスごとの識別子を生成・取得
const makeDeviceUuid = (): string => {
  /**
   * 分析の際デバイスを識別できるようにするためのdevice_uuid。
   * できる限りユーザーに操作されないように略をkeyとしている。
   */
  const key = "cl_du"
  const storedValue: string | null = localStorage.getItem(key)

  if (storedValue) {
    return storedValue
  }

  const newValue = uuidv4().replace(/-/g, "")
  localStorage.setItem(key, newValue)

  return newValue
}

// タブごとの識別子を生成・取得
const makeTabUuid = (): string | null => {
  const key = "tab_uuid"
  const storedValue: string | null = sessionStorage.getItem(key)

  if (storedValue) {
    return storedValue
  }

  const newValue = uuidv4().replace(/-/g, "")
  sessionStorage.setItem(key, newValue)

  return newValue
}

interface ActionLog {
  // アクションログのスキーマのバージョン。構造が変わったらここの番号も必ず１ずつインクリメントさせて更新する。
  schemeVersion: number
  // デバイスごとの識別子
  deviceUuid: string | null
  // タブごとに割り当てられる識別子
  tabUuid: string | null
  // ログイン済みかどうかを判定
  loggedIn: boolean
  // ログイン中のユーザーID（初回のみ認証前にアクションログが記録される関係でログイン済みでもnullとして記録されることに注意。）
  userId: string | null
  // アカウント切り替え中の企業ID
  corporationId: string | null
  // 表示中のページのパス
  path: string
  // 表示中のページのクエリパラメータを含めたパス
  fullPath: string
  // 前のページのパス
  previousPath: string | null
  // 前のページのクエリパラメータを含めたパス
  previousFullPath: string | null
  // ユーザーエージェント
  userAgent: string | null
  // リファラー（１つ前のページではなく、サイトを最初に表示した時のリファラーが毎回記録される。）
  referrer: string
  // ブラウザのタイムスタンプ
  browserTimestamp: number
  // ホーム画面より（PWAとして）起動しているかどうかを判定
  isPwa: boolean | null
}

// アクションログ用のオブジェクトを生成
const makeActionLog = (route: { to: Route; from: Route }): ActionLog => ({
  schemeVersion: 2, // 構造が変わったら番号も１ずつインクリメントさせて更新すること。
  deviceUuid: makeDeviceUuid(),
  tabUuid: makeTabUuid(),
  loggedIn: !!localStorage.getItem(loggedInGlobalKey),
  userId: auth.currentUser?.uid || null,
  corporationId: localStorage.getItem(corporationIdGlobalKey),
  path: route.to.path,
  fullPath: route.to.fullPath,
  previousPath: route.from.name ? route.from.path : null,
  previousFullPath: route.from.name ? route.from.fullPath : null,
  userAgent: navigator.userAgent,
  referrer: document.referrer,
  browserTimestamp: new Date().getTime(),
  isPwa: !!window.matchMedia("(display-mode: standalone)").matches,
})

// オブジェクトを暗号化する。
const encryptActionLog = (actionLog: ActionLog): string => {
  const secretKey = process.env.ACTION_LOG_SECRET_KEY
  return CryptoJS.AES.encrypt(JSON.stringify(actionLog), secretKey).toString()
}

// アクションログを保存
const saveActionLog = (payload: string) => {
  const baseUrl = process.env.SERVICE_BASE_URL
  axios
    .post(
      `${baseUrl}/api/stats`,
      { payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .catch((e: Error) => {
      throw new Error("Action Log Error:" + String(e))
    })
}

// アクションログを収集する処理
const collectActionLog = (to: Route, from: Route) => {
  // アクションログ用のオブジェクトを生成
  const actionLog: ActionLog = makeActionLog({ to, from })

  // アクションログの内容を暗号化
  const payload = encryptActionLog(actionLog)

  // アクションログを保存
  saveActionLog(payload)
}

export default ({ app }: Context) => {
  // SSRで記録することにメリットがなかったため、処理の実行場所をクライアントサイド側に統一している。そのため何らかの理由でサーバーサイドで実行された場合は例外が発生する。
  if (process.server) {
    throw new Error("このプラグインはクライアントサイドでのみ実行可能です")
  }

  if (!app.router) {
    throw new Error("Contextにrouterが存在しません")
  }

  // 開発環境では、APIを載せているCloud Functionsが稼働していないので処理を実行しないようにしている。
  if (process.env.NODE_ENV === "development") {
    return
  }

  // 初回描画時含めてページ遷移ごとに実行される。
  app.router.afterEach(collectActionLog)
}
