import { ApiClient } from "~/backendApis/foundation/ApiClient"

const basePath = "/user_profile/user"

export const write = {
  addProfileUnreadableCorporation: (params: { name: string }) =>
    new ApiClient<void>().post(
      `${basePath}/profile_unreadable_corporation/add`,
      {
        name: params.name,
      }
    ),
  removeProfileUnreadableCorporation: (params: { name: string }) =>
    new ApiClient<void>().post(
      `${basePath}/profile_unreadable_corporation/remove`,
      {
        name: params.name,
      }
    ),
  changeWorkStyle: (params: {
    availableUptimeId: number
    workingFormId: number
    availableHoursPerDay: number
    contactableTypeIds: number[]
  }) =>
    new ApiClient<void>().post(`${basePath}/edit/work_style/change`, params),
  changeAvailableUptime: (params: { availableUptimeId: number }) =>
    new ApiClient<void>().post(
      `${basePath}/edit/work_style/change_available_uptime`,
      params
    ),
  changeDesiredReward: (params: {
    rewardType: string
    minReward: number | null
    maxReward: number | null
  }) =>
    new ApiClient<void>().post(
      `${basePath}/edit/desired_reward/change`,
      params
    ),
}
