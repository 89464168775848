/**
 * 公開OGP用のAPIクライアント
 * 外部サイトのURLからそのサイトのOGP情報を取得したり、OGP画像をキャッシュした状態で取得することができる。
 */
import axios, { AxiosError } from "axios"

const baseURL = process.env.BACKEND_PUBLIC_OGP_API_BASE_URL

const client = axios.create({
  baseURL,
  timeout: 10000, // 10秒
})

interface Metadata {
  version: number // 形式が変わった時にversionが上がる。ブラウザ側で必要に応じてキャッシュを消す際に利用。
  imageURL: string // og:image
  title: string // og:title
  description?: string // og:description
  siteName?: string // og:site_name
  type?: string // og:type
  twitterCard?: string // twitter:card
  url?: string // og:url
  favicon?: string // icon
}

export const publicOGP = {
  getMetadata: (siteURL: string): Promise<Metadata | null> =>
    client
      .get<Metadata>(`/metadata?url=${encodeURIComponent(siteURL)}`)
      .then((response) => {
        return response.data
      })
      .catch((_e: AxiosError) => {
        return null
      }),
}
