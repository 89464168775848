/**
 * Mutationの定義
 */
import { AccountMetadata, buildAccountMetadata, buildRole, Role } from "./model"
import { state } from "./state"
import {
  UserAccountView,
  FullInfoUserProfileView,
  ContractTypeView,
  CorporationAccountView,
} from "~/backendApis/viewModel"
import { StandardApplicationError } from "~/errorHandlers"
import { clHelpers } from "~/clHelpers"

interface InitializeMutationParams {
  userAccount: UserAccountView | null
  userProfile: FullInfoUserProfileView | null
  corporationAccount: CorporationAccountView | null
  contractType: ContractTypeView | null
}

export const mutations = {
  /**
   * 認証状態を初期化する
   */
  initialize: ({
    userAccount,
    userProfile,
    corporationAccount,
    contractType,
  }: InitializeMutationParams) => {
    if (
      (!userAccount || !userProfile) &&
      (corporationAccount || contractType)
    ) {
      throw new StandardApplicationError(
        "認証情報初期化でリクエストされた状態が不正でした。"
      )
    }

    // アカウントIDを作成
    const userId = userAccount?.universalUid || null
    const corporationId = corporationAccount?.universalCorporationId || null

    // アカウントのメタデータを作成
    const accountMetadata = buildAccountMetadata(
      userProfile,
      corporationAccount
    )

    // ロールを作成
    const role = buildRole({
      userAccount,
      corporationAccount,
      contractType,
    })

    printDebugLogWhenFinishInitialization(
      userId,
      corporationId,
      accountMetadata,
      role
    )

    state.userId = userId
    state.corporationId = corporationId
    state.accountMetadata = accountMetadata
    state.role = role
    state.userProfile = userProfile
    state.finishedAuthProcess = true
    state.contractType = contractType
    state.corporationProfile = corporationAccount

    // GA4 ユーザープロパティ設定
    window.$nuxt.$gtag("set", "user_properties", {
      user_id: userId,
      corporation_id: corporationId,
    })
  },
  setAccountMetadata(accountMetadata: AccountMetadata) {
    state.accountMetadata = accountMetadata
  },
}

const printDebugLogWhenFinishInitialization = (
  userId: string | null,
  corporationId: string | null,
  accountMetadata: AccountMetadata,
  role: Role
) => {
  const accountMetadataPlainObject = JSON.stringify(accountMetadata)
  const rolePlainObject = JSON.stringify(role)

  clHelpers.debugLogger.printList({
    title: "currentAuthenticationStatusでの認証情報初期化処理完了",
    contents: [
      `UserId : ${userId}`,
      `CorporationId : ${corporationId}`,
      `AccountMetadata : ${accountMetadataPlainObject}`,
      `Role : ${rolePlainObject}`,
    ],
    logType: "SUCCESS",
  })
}
