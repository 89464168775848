import { ApiClient } from "~/clApi/main/foundation/ApiClient"
import { NumberOfFavoritesView } from "~/clApi/viewModel"

const basePath = "/project/corporation_member"

export const read = {
  countFavoriteForProject: (params: {
    universalCorporationId: string
    projectId: string
  }) =>
    new ApiClient<NumberOfFavoritesView>().get(
      `${basePath}/${params.universalCorporationId}/favorite_project/count_for_project?projectId=${params.projectId}`
    ),
}
