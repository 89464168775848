import ApplicationApiError from "../../../../foundation/ApplicationApiError"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ProjectApplicationView } from "~/backendApis/viewModel"

interface Params {
  projectId: string
}

export class GetProjectApplicationReadRequest extends ReadRequest<ProjectApplicationView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ProjectApplicationView | null> {
    return this.apiClient
      .get(this.makeEndpoint())
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      })
  }

  private makeEndpoint(): string {
    return `/matching/user/project_application/show?projectId=${this.params.projectId}`
  }
}
