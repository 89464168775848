import axios, { AxiosResponse } from "axios"

/*
 * アプリケーションのRevisionの更新に関する関心事を責務とするクラス。
 * Revisionに変更があったかどうかの判定をしてくれる。
 */
export class ApplicationRevisionUpdate {
  // 現在のリビジョンのハッシュ値
  private readonly currentRevisionHash: string

  constructor(currentRevisionHash: string) {
    this.currentRevisionHash = currentRevisionHash
  }

  async isChangedRevision(): Promise<boolean> {
    const latestRevisionHash = await ApplicationRevisionUpdate.fetchRevisionHash()
    return latestRevisionHash !== this.currentRevisionHash
  }

  static async build() {
    const currentRevisionHash = await ApplicationRevisionUpdate.fetchRevisionHash()
    return new ApplicationRevisionUpdate(currentRevisionHash)
  }

  // 現在のリビジョンのハッシュ値を取得
  // リビジョンのハッシュ値はリリースの度に別のランダム文字列に更新されるようになっている。
  private static async fetchRevisionHash(): Promise<string> {
    // 最新のリビジョンの状態を取得したいので、キャッシュを利用しないようにしている。
    const response: AxiosResponse = await axios.get("/revision.json", {
      headers: { "Cache-Control": "no-cache" },
    })
    const currentRevisionHash = response.data.hash
    return currentRevisionHash
  }
}
