import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  skillTagList: {
    maxYearsOfExperience: number | null
    minYearsOfExperience: number | null
    skillId: string
  }[]
}

export class ChangeSkillTagListWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/user_profile/user/edit/skill_tag_list/change"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
