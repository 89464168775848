import { auth } from "~/plugins/firebase"
import { resetSwitchedCorporationAccount } from "~/uiLogics/corporation/switchedCorporationStateManager"
import { removeLoggedInStatus } from "~/uiLogics/webStorage/loggedInStatus"

export const logout = async () => {
  removeLoggedInStatus()

  resetSwitchedCorporationAccount()
  await auth.signOut()
}
