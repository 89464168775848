/**
 * ユーザーのお気に入りプロジェクトに関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { BackendApi } from "~/backendApis"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface FavoriteProjectState {
  /**
   * 現在のユーザーがお気に入り中のプロジェクトIDのリスト
   */
  favoriteProjectIds: string[]
}
const makeDefaultState = () => ({
  favoriteProjectIds: [],
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: FavoriteProjectState = Vue.observable<FavoriteProjectState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.favoriteProjectIds = defaultState.favoriteProjectIds
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  setFavoriteProjectIds(favoriteProjectIds: string[]): void {
    state.favoriteProjectIds = favoriteProjectIds
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  getIdList: (): string[] => state.favoriteProjectIds,
  isFavorited: (projectId: string): boolean =>
    state.favoriteProjectIds.includes(projectId),
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * リストを最新状態にするための処理
   */
  refreshList: async (): Promise<void> => {
    const favoriteProjectList = await new BackendApi.User.ProjectSearch.GetFavoriteProjectListReadRequest().get()
    mutations.setFavoriteProjectIds(
      favoriteProjectList.list.map((project) => project.id)
    )
  },
  /**
   * お気に入り状態を切り替えるための処理
   * サーバーとの通信時間を待たずに画面に変更が反映されるように、通信より先にstateを更新している。
   */
  toggleFavoriteStatus: async (targetProjectId: string): Promise<void> => {
    if (getters.isFavorited(targetProjectId)) {
      // 現在お気に入り中。本アクションによってお気に入りリストから外す。
      mutations.setFavoriteProjectIds(
        getters.getIdList().filter((id) => id !== targetProjectId)
      )

      await new BackendApi.User.FavoriteProject.RemoveFromFavoriteProjectList({
        targetProjectId,
      }).post()
    } else {
      // 現在お気に入りリストに入っていない。本アクションによってリストに追加される。
      mutations.setFavoriteProjectIds([...getters.getIdList(), targetProjectId])

      await new BackendApi.User.FavoriteProject.AddToFavoriteProjectList({
        targetProjectId,
      }).post()
    }

    // サーバー側の状態更新が終わった後にリストを最新状態に更新する。これにより連打等でフロントとサーバー側の状態に一時的に差異が総じても同期される。
    actions.refreshList()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const favoriteProjectList = { getters, actions }
