import { Context } from "@nuxt/types"
import {
  switchCorporationAccount,
  resetSwitchedCorporationAccount,
} from "../uiLogics/corporation/switchedCorporationStateManager"
import { AUTO_SWITCHED_CORPORATION_ACCOUNT_ID_QUERY_NAME } from "../uiLogics/auth/authExecutor"

/**
 * クエリパラメータを利用して、アカウントを画面表示時に自動で切り替えるためのプラグイン。
 * 現在切り替え中のアカウントの状態管理はLocalStorageを利用して行っているため、このプラグインはクライアントサイドで呼ばれるようにconfigファイルで設定されている。
 */
export default ({ route }: Context) => {
  const targetUniversalCorporationId =
    route.query[AUTO_SWITCHED_CORPORATION_ACCOUNT_ID_QUERY_NAME]

  if (!targetUniversalCorporationId) return

  if (targetUniversalCorporationId === "reset") {
    // ユーザーアカウントに切り替え
    resetSwitchedCorporationAccount()
  } else {
    // 指定された企業アカウントに切り替え
    switchCorporationAccount(String(targetUniversalCorporationId))
  }
}
