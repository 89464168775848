/*
 * 本サービスでは、Role-Based Access Control（RBAC）の考え方に沿って、ユーザーをロールごとに権限管理するよう実装している。
 * ここでは、本サービスに存在するユーザーのロールを全て列挙したものを定義している。
 * 本サービスの特性上、ロールはユーザーアカウント用と企業アカウント用の2種類存在している。
 * 「企業アカウントへの切り替え」はユーザーアカウント用のロールに企業アカウント用のロールが足されて、権限がマージされるような仕様となっている。
 */

/*
 * ユーザーアカウントとしてのロール
 * TODO これはロールではなく、状態なので、フロントエンド刷新時にリファクタリングする
 */
export enum UserAccountRoleList {
  /*
   * UNKNOWN_ACCOUNT_ROLE
   * 未ログイン状態のロール
   */
  UNKNOWN_ACCOUNT_ROLE = "UNKNOWN_ACCOUNT_ROLE",

  /*
   * PRE_SIGN_UP_ACCOUNT_ROLE
   * 認証はされているが基本情報が登録されていないアカウントのロール
   */
  PRE_SIGN_UP_ACCOUNT_ROLE = "PRE_SIGN_UP_ACCOUNT_ROLE",

  /*
   * NO_ENOUGH_PROFILE_ACCOUNT_ROLE
   * 一定量のプロフィール情報が入力されていないアカウントのロール
   */
  NO_ENOUGH_PROFILE_ACCOUNT_ROLE = "NO_ENOUGH_PROFILE_ACCOUNT_ROLE",

  /*
   * ENOUGH_PROFILE_ACCOUNT_ROLE
   * 一定量のプロフィール情報が入力されているアカウントのロール
   */
  ENOUGH_PROFILE_ACCOUNT_ROLE = "ENOUGH_PROFILE_ACCOUNT_ROLE",
}

/*
 * 企業アカウントとしてのロール
 */
export enum CorporationAccountRoleList {
  /*
   * FREE_ACCOUNT_ROLE
   * 無料アカウントとしてのロール
   */
  FREE_ACCOUNT_ROLE = "FREE_ACCOUNT_ROLE",

  /*
   * TRIAL_ACCOUNT_ROLE
   * トライアルアカウントとしてのロール
   */
  TRIAL_ACCOUNT_ROLE = "TRIAL_ACCOUNT_ROLE",

  /*
   * PAID_ACCOUNT_ROLE
   * 有料アカウントとしてのロール
   */
  PAID_ACCOUNT_ROLE = "PAID_ACCOUNT_ROLE",
}

/**
 * ユーザーのロール
 */
export enum UserRole {
  /*
   * 一般ユーザー
   */
  USER = "USER",
  /*
   * 企業担当者ユーザー
   */
  CORPORATION = "CORPORATION",
}
