import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  skillList: {
    title: string
    description: string
  }[]
}

export class ChangeSkillListWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/user_profile/user/edit/skill_list/change"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
