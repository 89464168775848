/**
 * read
 */
import { GetContactableUserListOfEmailReadRequest } from "./read/GetContactableUserListOfEmailReadRequest"

export const ExternalNotification = {
  /**
   * read
   */
  GetContactableUserListOfEmailReadRequest,
}
