/**
 * 前提として、クラウドリンクスでは、認証にFirebase Authenticationを利用しており、
 * その仕様上、ブラウザにリソースを表示後に認証状態を取得するため、数秒のタイムラグが生じる。
 *
 * ログイン済みの場合はすぐに画面遷移をさせたいようなケースも多いため、その挙動を実現するために認証状態をLocalStorageに別に持つようにしている。
 */

// アクションログ等で複数のフロントエンドAPPを跨いで利用されているキーであるため、変更する場合は必ず該当箇所全てを変更すること。
const globalKey = "loggedIn"
const value = "1"

export const setLoggedInStatus = (): void => {
  localStorage.setItem(globalKey, value)
}

export const isLoggedIn = (): boolean => {
  return localStorage.getItem(globalKey) === value
}

export const removeLoggedInStatus = (): void => {
  localStorage.removeItem(globalKey)
}
