













































import Vue from "vue"
import RedBatch from "./RedBatch.vue"
import MaterialIcon, {
  FILLED,
  OUTLINED,
} from "~/components/guideline/icon/MaterialIcon.vue"

export default Vue.extend({
  components: {
    MaterialIcon,
    RedBatch,
  },
  props: {
    isCurrentPage: {
      type: Boolean,
      default: false,
    },
    defaultIcon: {
      type: String,
      required: true,
    },
    activeIcon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    hasBatch: {
      type: Boolean,
      default: false,
    },
    isMainColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconType() {
      return { FILLED, OUTLINED }
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    handleClick() {
      this.$emit("click")
    },
  },
})
