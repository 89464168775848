/**
 * read
 */
import { CountTotalHitsForPaidReadRequest } from "./read/CountTotalHitsForPaidReadRequest"
// import { CountTotalHitsOldAlgoliaReadRequest } from "./read/CountTotalHitsOldAlgoliaReadRequest"
// import { CountTotalHitsOldAlgoliaForPaidReadRequest } from "./read/CountTotalHitsOldAlgoliaForPaidReadRequest"
import { CountTotalHitsReadRequest } from "./read/CountTotalHitsReadRequest"
import { GetFavoriteUserListReadRequest } from "./read/GetFavoriteUserListReadRequest"
import { SearchUserProfileListForPaidReadRequest } from "./read/SearchUserProfileListForPaidReadRequest"
import { SearchUserProfileListReadRequest } from "./read/SearchUserProfileListReadRequest"
// import { SearchOldAlgoliaUserProfileListReadRequest } from "./read/SearchOldAlgoliaUserProfileListReadRequest"
// import { SearchOldAlgoliaUserProfileListForPaidReadRequest } from "./read/SearchOldAlgoliaUserProfileListForPaidReadRequest"

export const UserProfileSearch = {
  /**
   * read
   */
  CountTotalHitsForPaidReadRequest,
  // CountTotalHitsOldAlgoliaReadRequest,
  // CountTotalHitsOldAlgoliaForPaidReadRequest,
  CountTotalHitsReadRequest,
  GetFavoriteUserListReadRequest,
  SearchUserProfileListForPaidReadRequest,
  SearchUserProfileListReadRequest,
  // SearchOldAlgoliaUserProfileListReadRequest,
  // SearchOldAlgoliaUserProfileListForPaidReadRequest,
}
