import { ApiClient } from "~/backendApis/foundation/ApiClient"
import {
  UserProfileSearchResultListView,
  UserProfileSearchResultView,
  NumberOfTotalHitView,
} from "~/backendApis/viewModel"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"
import { queryParamsGenerator } from "~/backendApis/helpers/queryParamsGenerator"

const basePath = "/user_profile_search/general"

export const read = {
  getUserProfile: (params: { universalUid: string }) =>
    new ApiClient<UserProfileSearchResultView | null>()
      .get(`${basePath}/user_profile/show/${params.universalUid}`)
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      }),
  searchUserProfileList: (params: {
    searchWord: string | null
    smallJobCategoryIds: number[]
    availableUptimeIds: number[]
    page: number
  }) => {
    const keys: Array<keyof typeof params> = [
      "searchWord",
      "smallJobCategoryIds",
      "availableUptimeIds",
      "page",
    ]

    const queryString = queryParamsGenerator.generateSearchQuery<typeof params>(
      {
        keys,
        params,
      }
    )
    return new ApiClient<UserProfileSearchResultListView>().get(
      `${basePath}/user_profile/search${queryString}`
    )
  },
  countTotalHits: (params: {
    searchWord: string | null
    smallJobCategoryIds: number[]
    availableUptimeIds: number[]
  }) => {
    const keys: Array<keyof typeof params> = [
      "searchWord",
      "smallJobCategoryIds",
      "availableUptimeIds",
    ]

    const queryString = queryParamsGenerator.generateSearchQuery<typeof params>(
      { keys, params }
    )

    return new ApiClient<NumberOfTotalHitView>().get(
      `${basePath}/user_profile/count_total_hits${queryString}`
    )
  },
  // TODO 開発用に旧Algoliaの検索APIを呼び出すエンドポイントを追加(Algolia廃止時に削除する)
  // searchOldAlgoliaUserProfileList: (params: {
  //   searchWord: string | null
  //   smallJobCategoryIds: number[]
  //   availableUptimeIds: number[]
  //   page: number
  // }) => {
  //   const keys: Array<keyof typeof params> = [
  //     "searchWord",
  //     "smallJobCategoryIds",
  //     "availableUptimeIds",
  //     "page",
  //   ]

  //   const queryString = queryParamsGenerator.generateSearchQuery<typeof params>(
  //     {
  //       keys,
  //       params,
  //     }
  //   )
  //   return new ApiClient<UserProfileSearchResultListView>().get(
  //     `${basePath}/user_profile/old_algolia/search${queryString}`
  //   )
  // },
  // CountTotalHitsOldAlgolia: (params: {
  //   searchWord: string | null
  //   smallJobCategoryIds: number[]
  //   availableUptimeIds: number[]
  // }) => {
  //   const keys: Array<keyof typeof params> = [
  //     "searchWord",
  //     "smallJobCategoryIds",
  //     "availableUptimeIds",
  //   ]

  //   const queryString = queryParamsGenerator.generateSearchQuery<typeof params>(
  //     { keys, params }
  //   )

  //   return new ApiClient<NumberOfTotalHitView>().get(
  //     `${basePath}/user_profile/old_algolia/count_total_hits${queryString}`
  //   )
  // },
}
