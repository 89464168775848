import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { NumberOfFavoritesListView } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
  projectIds: string[]
}

export class CountFavoriteForProjectsReadRequest extends ReadRequest<NumberOfFavoritesListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<NumberOfFavoritesListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectIds } = this.params
    return `/project/corporation_member/${universalCorporationId}/favorite_project/count_for_projects?projectIds=${projectIds.join(
      ","
    )}`
  }
}
