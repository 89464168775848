


















































import Vue from "vue"
import SimpleModal from "~/components/guideline/modal/SimpleModal.vue"
import ConversionButton from "~/components/guideline/button/ConversionButton.vue"
import MaterialIcon, {
  OUTLINED,
} from "~/components/guideline/icon/MaterialIcon.vue"

/**
 * localStorageに記録するためのキー
 */
const localStorageKeyPrefix = "information_"

interface Information {
  id: string
  title: string
  content: string
  linkTitle: string | null
  link: string | null
}

interface DataType {
  information: Information
  isShown: boolean
}

export default Vue.extend({
  components: {
    SimpleModal,
    ConversionButton,
    MaterialIcon,
  },
  data(): DataType {
    return {
      information: {
        id: "",
        title: "",
        content: "",
        linkTitle: "",
        link: "",
      },
      isShown: false,
    }
  },
  computed: {
    iconType() {
      return { OUTLINED }
    },
  },
  watch: {
    $route() {
      this.initialize()
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      if (this.$clAuth.userId === null && this.$clAuth.corporationId === null) {
        return
      }
      await this.getInformation(this.$clAuth.userId, this.$clAuth.corporationId)
    },
    getInformation(userId: string | null, corporationId: string | null) {
      const path = this.$route.path
      if (corporationId !== null && path === "/corporation/profile") {
        this.getCorporationInformation()
      } else if (
        userId !== null &&
        corporationId == null &&
        path === "/projects"
      ) {
        this.getUserInformation()
      }
    },
    getUserInformation() {
      this.$clApi.main.user.internalNotification.read
        .getUserInformationList()
        .then((list) => {
          for (let i = 0; i < list.list.length; i++) {
            if (!this.isDisplayedCheck(list.list[i].id)) {
              this.information = list.list[i]
              this.isShown = true
              this.setLocalStorage(this.information.id)
              break
            }
          }
        })
    },
    getCorporationInformation() {
      this.$clApi.main.corporationMember.internalNotification.read
        .getCorporationInformationList({
          universalCorporationId: this.$clAuth.corporationIdOrFail,
        })
        .then((list) => {
          for (let i = 0; i < list.list.length; i++) {
            if (!this.isDisplayedCheck(list.list[i].id)) {
              this.information = list.list[i]
              this.isShown = true
              this.setLocalStorage(this.information.id)
              break
            }
          }
        })
    },
    isDisplayedCheck(id: string): boolean {
      const isDisplayed = localStorage.getItem(localStorageKeyPrefix.concat(id))
      if (isDisplayed) {
        return true
      }
      return false
    },
    setLocalStorage(id: string) {
      localStorage.setItem(localStorageKeyPrefix.concat(id), "displayed")
    },
    handleCloseIcon() {
      this.isShown = !this.isShown
    },
    isExternalLink(): boolean {
      return (
        this.information.link !== null &&
        (!this.information.link!!.startsWith(process.env.SERVICE_BASE_URL!!) ||
          this.information.link!!.startsWith(
            `${process.env.FRONTEND_BIZ_MAIN_BASE_URL}/biz`!!
          ))
      )
    },
    makeInternalLink(link: string): string {
      return link.replace(process.env.SERVICE_BASE_URL!!, "")
    },
  },
})
