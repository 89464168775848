import { AddToFavoriteProjectList } from "./write/AddToFavoriteProjectList"
import { RemoveFromFavoriteProjectList } from "./write/RemoveFromFavoriteProjectList"

export const FavoriteProject = {
  /**
   * write
   */
  AddToFavoriteProjectList,
  RemoveFromFavoriteProjectList,
}
