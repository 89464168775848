import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  phoneNumber: string | null
}

export class ChangePhoneNumberWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/external_notification/user/contact_information/change_phone_number"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
