import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  firstName: string
  lastName: string
  firstNameKana: string
  lastNameKana: string
  prefectureId: string
  isLivingAbroad: boolean
  sexId: string | null
  birthday: string | null
}

export class CreateUserProfileWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string = "/user_profile/user/create"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
