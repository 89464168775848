import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ContactableUserListView } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
  email: string
}

export class GetContactableUserListOfEmailReadRequest extends ReadRequest<ContactableUserListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ContactableUserListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { universalCorporationId, email } = this.params
    return `/external_notification/corporation_member/${universalCorporationId}/contactable_user/list_of_email?email=${email}`
  }
}
