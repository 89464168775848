import Vue from "vue"
import { clAuth } from "~/clAuth"
import { authState } from "~/clAuth/authState"
import { clHelpers } from "~/clHelpers"

const makeMetadata = (): object => ({
  userId: clAuth.userId,
  corporationId: clAuth.corporationId,
  role: { ...authState.getters.getRole() },
})

const toErrorMessage = (error: Error | string) =>
  typeof error === "string" ? error : error.message

const debugLogTitle = "【Error Notifier Helper】デバックログ"

/**
 * フロントエンドからのエラー通知を行うための処理
 */
export const errorNotifier = {
  // Level : Error
  error: (error: Error | string, metadata: object = {}) => {
    clHelpers.debugLogger.print({
      title: debugLogTitle,
      content: toErrorMessage(error),
      logType: "ERROR",
    })
    const rollbarClient = (Vue as any).rollbar
    rollbarClient &&
      rollbarClient.error(error, { ...makeMetadata(), ...metadata })
  },
  // Level : Warning
  warning: (error: Error | string, metadata: object = {}) => {
    const message = toErrorMessage(error)
    clHelpers.debugLogger.print({
      title: debugLogTitle,
      content: message,
      logType: "WARN",
    })
    const rollbarClient = (Vue as any).rollbar
    // waningの第一引数にはString型しか渡せないため、 Errorクラス の場合はmessageプロパティの値を渡すようにしている。
    rollbarClient &&
      rollbarClient.warning(message, { ...makeMetadata(), ...metadata })
  },
  // Level : Info
  info: (error: Error | string, metadata: object = {}) => {
    const message = toErrorMessage(error)
    clHelpers.debugLogger.print({
      title: debugLogTitle,
      content: message,
      logType: "INFO",
    })
    const rollbarClient = (Vue as any).rollbar
    // waningの第一引数にはString型しか渡せないため、 Errorクラス の場合はmessageプロパティの値を渡すようにしている。
    rollbarClient &&
      rollbarClient.info(message, { ...makeMetadata(), ...metadata })
  },
}
