/**
 * プロジェクトの応募メッセージに関する状態管理をしている。
 */
import Vue from "vue"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
interface ProjectApplyMessageState {
  /**
   * 応募対象であるプロジェクトのID
   */
  projectId: string
  /**
   * プロジェクト応募メッセージ
   */
  applyMessage: string
  /**
   * メッセージを入力するプロジェクト応募パネルを表示するかどうかを示すフラグ
   */
  isShownProjectApplyPanel: boolean
  /**
   * プロジェクトに応募できるかどうかを示すフラグ
   */
  isApplicable: boolean
}
const makeDefaultState = () => ({
  projectId: "",
  applyMessage:
    "■応募理由（必須）\n・\n\n■このプロジェクトで活かせる経験・スキル\n・\n\n■企業に聞きたいこと（任意）\n・",
  isShownProjectApplyPanel: false,
  isApplicable: false,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProjectApplyMessageState = Vue.observable<ProjectApplyMessageState>(
  makeDefaultState()
)

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  resetProjectApplyMessage(projectId: string) {
    state.projectId = projectId
    state.applyMessage = makeDefaultState().applyMessage
    state.isShownProjectApplyPanel = makeDefaultState().isShownProjectApplyPanel
    state.isApplicable = makeDefaultState().isApplicable
  },
  setApplyMessage(message: string) {
    state.applyMessage = message
  },
  setUpIsShownProjectApplyPanelFlag() {
    state.isShownProjectApplyPanel = true
  },
  setDownIsShownProjectApplyPanelFlag() {
    state.isShownProjectApplyPanel = false
  },
  setIsApplicable(isApplicable: boolean) {
    state.isApplicable = isApplicable
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get projectId(): string {
    return state.projectId
  },
  get applyMessage(): string {
    return state.applyMessage
  },
  get isShownProjectApplyPanel(): boolean {
    return state.isShownProjectApplyPanel
  },
  get isApplicable(): boolean {
    return state.isApplicable
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * 新しいプロジェクトページを訪問する時にstateを初期化
   */
  initializeStateForNewProject(projectId: string) {
    if (projectId !== getters.projectId) {
      mutations.resetProjectApplyMessage(projectId)
    }
  },
  openProjectApplyPanel() {
    mutations.setUpIsShownProjectApplyPanelFlag()
  },
  closeProjectApplyPanel() {
    mutations.setDownIsShownProjectApplyPanelFlag()
  },
  updateProjectApplyMessage(message: string) {
    mutations.setApplyMessage(message)
  },
  updateIsApplicable(isApplicable: boolean) {
    mutations.setIsApplicable(isApplicable)
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const projectApplyMessage = { getters, actions }
