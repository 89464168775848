import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { FreeTrialContractableStatus } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
}

export class CanContractFreeTrialReadRequest extends ReadRequest<FreeTrialContractableStatus> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<FreeTrialContractableStatus> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/corporation_contract/corporation_member/${this.params.universalCorporationId}/free_trial_contract/can_apply`
  }
}
