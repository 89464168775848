export const queryParamsGenerator = {
  // 検索用のクエリを生成する
  // "searchWord"による検索指定を受け取り、他に T で指定されたパラメータから
  // クエリバラメータを組み立てる
  generateSearchQuery: <T extends { searchWord: string | null }>({
    keys,
    params,
  }: {
    keys: Array<keyof T>
    params: T
  }): string => {
    const queryArray = keys
      .filter((k) => !!params[k]) // 空の検索パラメーターを除外
      .map((k) =>
        k === "searchWord"
          ? `${k}=${encodeURIComponent(params.searchWord as string)}` // 検索キーワードをencodeする
          : `${k}=${params[k]}`
      )
    return queryArray.length > 0 ? `?${queryArray.join("&")}` : ""
  },

  generateSkillSearchQuery: <T extends { keyword: string | null }>({
    keys,
    params,
  }: {
    keys: Array<keyof T>
    params: T
  }): string => {
    const queryArray = keys
      .filter((k) => !!params[k]) // 空の検索パラメーターを除外
      .map((k) =>
        k === "keyword"
          ? `${k}=${encodeURIComponent(params.keyword as string)}` // 検索キーワードをencodeする
          : `${k}=${params[k]}`
      )
    return queryArray.length > 0 ? `?${queryArray.join("&")}` : ""
  },
}
