import { NumberOfTotalHitView } from "../../../../viewModel/userProfileSearch"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { queryParamsGenerator } from "~/backendApis/helpers/queryParamsGenerator"

interface Params {
  searchWord: string | null
  smallJobCategoryIds: number[]
  availableUptimeIds: number[]
}

export class CountTotalHitsReadRequest extends ReadRequest<NumberOfTotalHitView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<NumberOfTotalHitView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const keys: Array<keyof Params> = [
      "availableUptimeIds",
      "smallJobCategoryIds",
      "searchWord",
    ]

    const queryString = queryParamsGenerator.generateSearchQuery<Params>({
      keys,
      params: this.params,
    })

    return `/user_profile_search/user/user_profile/count_total_hits${queryString}`
  }
}
