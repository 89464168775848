







































































import Vue, { PropType } from "vue"
import AccountImageMenu from "./navigationMenuItem/AccountImageMenu.vue"
import ClickableIconMenu from "./navigationMenuItem/ClickableIconMenu.vue"
import UnclickableIconMenu from "./navigationMenuItem/UnclickableIconMenu.vue"
import { NormalNavigationItem, ImageIconNavigationItem } from "./type"

export default Vue.extend({
  components: {
    AccountImageMenu,
    ClickableIconMenu,
    UnclickableIconMenu,
  },
  props: {
    normalNavigationItems: {
      type: Array as PropType<NormalNavigationItem[]>,
      required: true,
    },
    imageIconNavigationItem: {
      type: Object as PropType<ImageIconNavigationItem>,
      default: null,
    },
    isVisibleAuthMenus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    bizMainBaseUrl() {
      return `${process.env.FRONTEND_BIZ_MAIN_BASE_URL}/biz/applications/`
    },
    isAuthCorporation() {
      return !!this.$clAuth.corporationId
    },
  },
  methods: {
    isCurrentPage(path: string): boolean {
      return (
        this.$nuxt.$route.path.replace(/\/$/, "") === path.replace(/\/$/, "")
      )
    },
    navigateTo(path: string) {
      this.$nuxt.$router.push({ path })
    },
    handleClickLoginMenu() {
      this.$emit("click-login-menu")
    },
    handleClickSignupMenu() {
      this.$emit("click-signup-menu")
    },
    handleClickAccountImageMenu() {
      this.$emit("click-account-image-menu")
    },
  },
})
