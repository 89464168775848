import { ApiClient } from "~/backendApis/foundation/ApiClient"

const basePath = "/user_profile/corporation_member"

export const write = {
  createProfileViewedByCorporationEvent: (params: {
    universalCorporationIdOfViewer: string
    universalUidOfProfileOwner: string
  }) =>
    new ApiClient<void>().post(
      `${basePath}/${params.universalCorporationIdOfViewer}/profile_viewed_event/create`,
      {
        universalUid: params.universalUidOfProfileOwner,
      }
    ),
}
