import {
  setSwitchedCorporationAccountId as setSwitchedCorporationAccountIdOnWebStorage,
  getSwitchedCorporationAccountId as getSwitchedCorporationAccountIdOnWebStorage,
  removeSwitchedCorporationAccountId as removeSwitchedCorporationAccountIdOnWebStorage,
} from "~/uiLogics/webStorage/switchedCorporationAccountId"

/*
 * 企業アカウントを切り替える処理
 */
export const switchCorporationAccount: (
  universalCorporationId: string
) => void = setSwitchedCorporationAccountIdOnWebStorage

/*
 * 切り替え中の企業アカウントのIDを取得する処理
 */
export const getSwitchedCorporationAccountId: () =>
  | string
  | null = getSwitchedCorporationAccountIdOnWebStorage

/*
 * 切り替え中の企業アカウントを初期化する処理
 */
export const resetSwitchedCorporationAccount: () => void = removeSwitchedCorporationAccountIdOnWebStorage

/**
 * 企業アカウントに切り替える時に企業IDを設定するためのクエリのキーとする値。
 * ユーザーから容易に見られる値で安易に意味を明確にしたくなかったため、意図的に不明確な文字をクエリのキーとしている。
 */
export const CORPORATION_ID_QUERY_KEY = "c"
