/**
 * read
 */
import { GetProjectListReadRequest } from "./read/GetProjectListReadRequest"
import { GetProjectReadRequest } from "./read/GetProjectReadRequest"

export const ProjectSearch = {
  /**
   * read
   */
  GetProjectListReadRequest,
  GetProjectReadRequest,
}
