import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  projectId: string
  detail: string
}

export class ChangeDetailWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { detail } = this.params
    return this.apiClient.post(this.makeEndpoint(), { detail })
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectId } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/${projectId}/content/change_detail`
  }
}
