import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  address: string
  email: string
  logoImagePath: string | null
  name: string
  phoneNumber: string
  websiteUrl: string
}

export class CreateCorporationAccountWriteRequest extends WriteRequest<string> {
  private readonly endpoint: string =
    "/corporation_account/user/corporation_account/create"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  /**
   * @returns universal corporation id of created corporation
   */
  public post(): Promise<string> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
