import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { CorporationAccountView } from "~/backendApis/viewModel"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

const basePath = "/corporation_account/general"

export const read = {
  getCorporationAccount: (params: { universalCorporationId: string }) =>
    new ApiClient<CorporationAccountView | null>()
      .get(
        `${basePath}/corporation_account/show/${params.universalCorporationId}`
      )
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      }),
}
