import { auth } from "~/plugins/firebase"
import { FirebaseAuthFailure } from "~/errorHandlers"
import { clHelpers } from "~/clHelpers"
import { BackendApi } from "~/backendApis"
import {
  setLoggedInStatus,
  isLoggedIn,
  removeLoggedInStatus,
} from "~/uiLogics/webStorage/loggedInStatus"
import { isTryToSignupCorporationMember } from "~/uiLogics/webStorage/signupCorporationState"
import {
  UserAccountView,
  FullInfoUserProfileView,
} from "~/backendApis/viewModel"
import { authState } from "~/clAuth/authState"
import { finishTryingToLogin } from "~/clAuth/login"
import { errorNotifier } from "~/clHelpers/errorNotifier"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"
import { logout } from "~/clAuth/logout"
import { setLastLoginMethod } from "~/uiLogics/webStorage/lastLoginMethod"

export interface CallBackWhenSuccess {
  userAccount: UserAccountView | null
  userProfile: FullInfoUserProfileView | null
}

export const completeAuthProcess = (
  params: {
    callbackWhenSuccess?: (params: CallBackWhenSuccess) => void
    callbackWhenFailed?: () => void
  } = {}
) => {
  /**
   * リダイレクトを使ったFirebase認証後にその結果を得られる。
   * 3rd party cookieに関するエラーなどが発生しうるため、それらの原因調査のために例外としてthrowして通知している。
   */
  auth.getRedirectResult().catch(
    (e: {
      code: string
      message: string
      email: string
      credential: {
        providerId: string
        signInMethod: string
      }
    }) => {
      // 別のOAuthプロバイダーでアカウントを作成している場合に発生するエラー
      if (e.code === "auth/account-exists-with-different-credential") {
        // アラート表示後に確実に例外を発生させてエラー通知を飛ばすためにsetTimeoutでアラートの表示を遅らせている。
        setTimeout(() => {
          alert(
            `このメールアドレスは${e.credential.providerId}以外のサービスですでにアカウントが作成されています。アカウントを作成したサービスでログインしてください。`
          )
        }, 100)
        errorNotifier.warning(
          `認証エラー auth/account-exists-with-different-credential - ${JSON.stringify(
            e
          )}`
        )
      } else {
        // アラート表示後に確実に例外を発生させてエラー通知を飛ばすためにsetTimeoutでアラートの表示を遅らせている。
        setTimeout(() => {
          alert(
            "認証時に問題が発生しました。問題が解消されない場合はお手数ですが認証を試みたメールアドレスと合わせて運営までお問い合わせをお願いします。"
          )
        }, 100)
        throw new FirebaseAuthFailure(
          JSON.stringify({ ...e, ua: navigator.userAgent })
        )
      }
    }
  )

  // @see https://firebase.google.com/docs/auth/web/manage-users?hl=ja
  auth.onAuthStateChanged(async (authUser: firebase.default.User | null) => {
    /**
     * 企業担当者アカウント作成中の場合、処理を終了する。
     * 同一ブラウザで複数のタブを開いている状態で企業担当者アカウント作成を実行した場合にエラーが発生するため
     * このような処理を行っている。
     */
    if (isTryToSignupCorporationMember()) {
      return
    }

    if (authUser !== null) {
      clHelpers.debugLogger.print({
        title: "completeAuthProcessにより認証処理開始",
        content: authUser.uid,
        logType: "START",
      })

      authUser.getIdToken().then((idToken) => {
        clHelpers.debugLogger.print({
          title: "認証ユーザーのID TOKEN",
          content: idToken,
          logType: "INFO",
        })
      })

      if (!isLoggedIn()) {
        /**
         * ユーザー登録処理。
         * もし同じユーザーIDですでに会員登録がされている場合は何も起こらない。
         */
        await BackendApi.general.userAccount.write
          .createUserAccount({
            email: authUser.email,
          })
          .catch((e: ApplicationApiError) => {
            if (e.isUnprocessableEntity()) {
              alert(
                "このアカウントはすでに退会済みです。お手数ですが、アカウントを切り替えてログインをやり直してください。"
              )

              finishTryingToLogin()
              finishNotTransition()
              logout()
              params?.callbackWhenFailed && params.callbackWhenFailed()
            }
            throw e
          })
      }

      const {
        userAccount,
        userProfile,
      } = await authState.actions.refreshAuthState()

      // ログイン状態を保持
      setLoggedInStatus()
      setLastLoginMethod(authUser.providerData[0]?.providerId || "")

      finishTryingToLogin()
      finishNotTransition()
      params?.callbackWhenSuccess &&
        params.callbackWhenSuccess({
          userAccount,
          userProfile,
        })
    } else {
      clHelpers.debugLogger.print({
        content: "未認証ユーザーとしてサービスの利用を開始しました。",
        logType: "INFO",
      })

      // 認証処理が完了したことを示すフラグを立てる。
      authState.actions.refreshAuthState()

      removeLoggedInStatus()

      finishTryingToLogin()
      if (isNotTransition()) {
        // 退会処理中はTOPに遷移してほしくないので、callbackしない
        finishNotTransition()
        return
      }
      params?.callbackWhenFailed && params.callbackWhenFailed()
    }
  })
}

const key = "cl_auth.not_transition"
const value = "1"

/*
 * AuthState変更時に画面遷移させない：開始
 */
export const setNotTransition = (): void => {
  sessionStorage.setItem(key, value)
}

/*
 * AuthState変更時に画面遷移させない：確認
 */
const isNotTransition = (): boolean => {
  return sessionStorage.getItem(key) === value
}

/*
 * AuthState変更時に画面遷移させない：解除
 */
const finishNotTransition = (): void => {
  sessionStorage.removeItem(key)
}
