import {
  reauthenticateGoogle,
  reauthenticateFacebook,
  linkPassword,
  linkGoogle,
  linkFacebook,
  unlink,
  isReauth,
  finishReauth,
} from "./accountLink"
import { authState } from "./authState"
import {
  ProviderData,
  getProviderData,
  loginEmail,
  loginGoogle,
  loginFacebook,
  isTryingToLogin,
} from "./login"
import { logout } from "./logout"
import { AccountMetadata } from "./authState/model"
import {
  CallBackWhenSuccess,
  completeAuthProcess,
  setNotTransition,
} from "./completer"
import {
  getAuthEmail,
  isEmailVerified,
  sendEmailVerification,
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  sendPasswordResetEmail,
  applyActionCode,
  updateEmail,
  updatePassword,
  reauthenticate,
} from "./email"
import { signupEmail } from "./signup"
import { fetchSignInMethods } from "./firebase"
import {
  NotFoundUserIdOnClAuthError,
  NotFoundCorporationIdOnClAuthError,
} from "~/errorHandlers"
import { Acl } from "~/config/acl/accessControlList"
import {
  UserAccountView,
  FullInfoUserProfileView,
  ContractTypeView,
  CorporationAccountView,
} from "~/backendApis/viewModel"
import { UserRole } from "~/config/acl/userRoleList"

/**
 * ## CLAuthの説明
 * クラウドリンクスでの認証に関する処理実行や状態管理を責務とする。
 *
 * 前提として、クラウドリンクスでは「ユーザーアカウント」と「企業アカウント」という２種類のアカウントがあり、
 * どちらを利用する場合もまず必ず「ユーザーアカウント」を利用するために認証する必要がある。
 * 認証後「ユーザーアカウント」を利用している状態で、「企業アカウント」に切り替えることができる。
 * 権限としては、「ユーザーアカウント」でできることに、切り替えた「企業アカウント」でできることが追加される仕様となっている。
 * そのため、「企業アカウント」に切り替え中の場合も、以下のcorporationIdに加えて、userIdにも引き続き利用中の「ユーザーアカウント」のIDが格納される。
 *
 * ここでは、利用中の「ユーザーアカウント」と「企業アカウント」の両方の状態を管理している。
 *
 * ## 利用方法
 * ここで定義しているオブジェクトは、plugins/dependencyInjection でVueインスタンスにプロパティとして定義し、グローバルで利用できるようにしている。
 * そのため各コンポーネントからは以下のように利用することができる。
 *
 * e.g. this.$clAuth.userId
 *
 *
 * **注意**
 * 認証状態の管理にはIndexedDBやLocalStorageが使われているため、
 * 認証が完了するタイミングは常にブラウザ側の画面描画後になり、SSRや描画と同時のタイミングでは認証が完了していない。
 * IndexedDB等を利用しているのは、認証処理に用いているFirebase Authenticationがそのような仕様になっているため。
 * そのため、以下のloggedInの値は、ログイン中だとしても画面描画後しばらく経ってからtrueになるような挙動になる。
 * それまでの認証処理中は以下のfinishedAuthProcessがfalseになっているため、
 * 必要があればこのフラグを用いて認証処理が完了しているかどうかを確かめること。
 */
export interface CLAuthSchema {
  /**
   * 現在利用中のアカウントのユーザーID
   */
  userId: string | null
  /**
   * 現在利用中のアカウントのユーザーID
   * 認証中であることが保証されている状況で利用される関数。
   * アカウントを利用していなければ例外が発生する。
   *
   * @throws NotFoundUserIdOnClAuthError
   */
  userIdOrFail: string
  /**
   * 切り替え中のアカウントの企業ID
   */
  corporationId: string | null
  /**
   * 切り替え中のアカウントの企業ID
   * 企業アカウントに切り替えていることが保証されている状況で利用される関数。
   * アカウントを切り替えていない場合は例外が発生する。
   *
   * @throws NotFoundCorporationIdOnClAuthError
   */
  corporationIdOrFail: string
  /**
   * アカウントの付属情報
   * ナビゲーションバーに表示するアカウント画像などが該当する。
   */
  accountMetadata: AccountMetadata
  /**
   * ログイン中かどうかを示すフラグ
   */
  loggedIn: boolean
  /**
   * ログインを試みている状態かどうかを示すフラグ
   */
  tryingToLogin: boolean
  /**
   * 再認証を試みている状態かどうかを示すフラグ
   */
  isReauth: boolean
  /**
   * 認証処理が完了したかどうかを示すフラグ
   * 初回描画後、認証が完了するまでの間も false になる。
   */
  finishedAuthProcess: boolean
  /**
   * 現在利用中のアカウントのユーザープロフィール
   */
  userProfile: FullInfoUserProfileView | null
  /**
   * 企業アカウントの契約タイプ
   */
  contractType: ContractTypeView | null
  /**
   * 企業アカウントのプロフィール
   */
  corporationProfile: CorporationAccountView | null
  /**
   * ユーザーロール
   */
  userRole: UserRole | null
  /**
   * 認証処理を完了させる処理
   */
  completeAuthProcess: (params?: {
    callbackWhenSuccess?: (params: CallBackWhenSuccess) => void
    callbackWhenFailed?: () => void
  }) => void
  /**
   * AuthState変更時に画面遷移させない設定
   */
  setNotTransition: () => void
  /**
   * メールアドレス/パスワードで新規登録
   */
  signupEmail: (email: string, password: string) => Promise<any>
  /**
   * 認証方法
   * ・パスワード
   * ・Goggle
   * ・Facebook
   */
  getProviderData: () => ProviderData
  /**
   * ・パスワード認証用メールアドレス
   */
  getAuthEmail: () => string | null
  /**
   * メールアドレス/パスワードでログイン
   */
  loginEmail: (email: string, password: string) => Promise<any>
  /**
   * Googleログインを開始（認証処理開始）
   */
  loginGoogle: () => void
  /**
   * Facebookログインを開始（認証処理開始）
   */
  loginFacebook: () => void
  /**
   * ログアウト
   */
  logout: () => Promise<void>
  /**
   * 現在利用中のアカウントに該当アクションを実行する権限があるかどうかを判定
   */
  hasPermission: (action: Acl) => boolean
  /**
   * 認証状態を初期化
   */
  refreshAuthState: () => Promise<{
    userAccount: UserAccountView | null
    userProfile: FullInfoUserProfileView | null
    corporationAccount: CorporationAccountView | null
    contractType: ContractTypeView | null
  }>
  /**
   * アカウントの付属情報を再読み込み
   * アカウント画像を変更した後などに実行される。
   */
  reloadAccountMetadata: () => Promise<void>
  /**
   * 現在利用中のアカウントの該当アクションの件数制限を取得する
   */
  limitCount: (action: Acl) => number | null
  /**
   * パスワード認証：メールアドレス検証用のメールを送信する。
   */
  sendEmailVerification: () => Promise<void>
  /**
   * パスワード認証：メールアドレス検証済みか確認する
   */
  isEmailVerified: () => boolean
  /**
   * パスワード認証：パスワードリセットコード検証
   */
  verifyPasswordResetCode: (actionCode: string) => Promise<string>
  /**
   * パスワード認証：アクションコードの正当性をチェックする
   */
  checkActionCode: (actionCode: string) => Promise<any>
  /**
   * パスワード認証：メールアドレスを検証済みにする。
   */
  applyActionCode: (actionCode: string) => Promise<void>
  /**
   * パスワード認証：パスワードリセット
   */
  confirmPasswordReset: (
    actionCode: string,
    newPassword: string
  ) => Promise<void>
  /**
   * パスワード認証：パスワードリセットのメールを送信する。
   */
  sendPasswordResetEmail: (restoredEmail: string) => Promise<void>
  /**
   * パスワード認証：メールアドレス更新
   */
  updateEmail: (newEmail: string) => Promise<void>
  /**
   * パスワード認証：パスワード更新
   */
  updatePassword: (newPassword: string) => Promise<void>
  /**
   * パスワード認証：再認証
   */
  reauthenticate: (password: string) => Promise<any>
  /**
   * Google認証：再認証
   */
  reauthenticateGoogle: () => Promise<any>
  /**
   * Facebook認証：再認証
   */
  reauthenticateFacebook: () => Promise<any>
  /**
   * パスワード認証を既存アカウントにリンク
   */
  linkPassword: (email: string, password: string) => Promise<any>
  /**
   * Google認証を既存アカウントにリンク
   */
  linkGoogle: () => void
  /**
   * Facebookアカウントを既存アカウントにリンク
   */
  linkFacebook: () => void
  /**
   * アカウントのリンクを解除
   */
  unlink: (prividerId: string) => void
  finishReauth: () => void
  fetchSignInMethods: (email: string) => Promise<string[]>
}

export const clAuth: CLAuthSchema = {
  get userId() {
    return authState.getters.getUserId()
  },
  get userIdOrFail() {
    const userId = authState.getters.getUserId()
    if (!userId) throw new NotFoundUserIdOnClAuthError()
    return userId
  },
  get corporationId() {
    return authState.getters.getCorporationId()
  },
  get corporationIdOrFail() {
    const corporationId = authState.getters.getCorporationId()
    if (!corporationId) throw new NotFoundCorporationIdOnClAuthError()
    return corporationId
  },
  get accountMetadata() {
    return authState.getters.getAccountMetadata()
  },
  get loggedIn() {
    return authState.getters.isLoggedIn()
  },
  get tryingToLogin() {
    return isTryingToLogin()
  },
  get isReauth() {
    return isReauth()
  },
  get finishedAuthProcess() {
    return authState.getters.isFinishedAuthProcess()
  },
  get userProfile() {
    return authState.getters.getUserProfile()
  },
  get contractType() {
    return authState.getters.getContractType()
  },
  get corporationProfile() {
    return authState.getters.getCorporationProfile()
  },
  get userRole() {
    return authState.getters.getRole().userRole
  },
  completeAuthProcess: (
    params: {
      callbackWhenSuccess?: (params: CallBackWhenSuccess) => void
      callbackWhenFailed?: () => void
    } = {}
  ) => completeAuthProcess(params),
  setNotTransition,
  signupEmail: (email: string, password: string) =>
    signupEmail(email, password),
  getProviderData,
  loginEmail: (email: string, password: string) => loginEmail(email, password),
  loginGoogle,
  loginFacebook,
  logout,
  hasPermission: authState.getters.hasPermission,
  refreshAuthState: authState.actions.refreshAuthState,
  reloadAccountMetadata: authState.actions.reloadAccountMetadata,
  limitCount: authState.getters.limitCount,
  sendEmailVerification,
  getAuthEmail,
  isEmailVerified,
  applyActionCode: (actionCode: string) => applyActionCode(actionCode),
  verifyPasswordResetCode: (actionCode: string) =>
    verifyPasswordResetCode(actionCode),
  checkActionCode: (actionCode: string) => checkActionCode(actionCode),
  confirmPasswordReset: (actionCode: string, newPassword: string) =>
    confirmPasswordReset(actionCode, newPassword),
  sendPasswordResetEmail: (restoredEmail: string) =>
    sendPasswordResetEmail(restoredEmail),
  updateEmail: (newEmail: string) => updateEmail(newEmail),
  updatePassword: (newPassword: string) => updatePassword(newPassword),
  reauthenticate: (password: string) => reauthenticate(password),
  reauthenticateGoogle: () => reauthenticateGoogle(),
  reauthenticateFacebook: () => reauthenticateFacebook(),
  linkPassword: (email: string, password: string) =>
    linkPassword(email, password),
  linkGoogle,
  linkFacebook,
  unlink: (prividerId: string) => unlink(prividerId),
  finishReauth,
  fetchSignInMethods: (email: string) => fetchSignInMethods(email),
}
