/**
 * プロジェクト応募に関する状態管理をしている。
 * 企業アカウント用のStore。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { StandardApplicationError } from "~/errorHandlers"
import { ProjectApplicationDetailView } from "~/backendApis/viewModel"
import { BackendApi } from "~/backendApis"
import { clAuth } from "~/clAuth"
import {
  SelectionStatus,
  ProjectApplicationSortType,
} from "~/backendApis/viewModel/matching"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface FilteringParameter {
  projectId: string | null
  statusKey: SelectionStatus
  sortType: ProjectApplicationSortType
}
interface ProjectApplicationListState {
  /**
   * 現在の企業ID
   * 異なる企業アカウントのリストが誤って表示されないように利用している。
   */
  currentCorporationId: string | null
  /**
   * フィルタリングのパラメーター
   */
  filteringParameter: FilteringParameter
  /**
   * 現在のページ数
   */
  currentPage: number
  /**
   * 応募のリスト
   */
  applications: ProjectApplicationDetailView[]
  /**
   * 応募を取得中（バックエンドと通信中）かどうかを示すフラグ
   */
  isFetching: boolean
  /**
   * 次のページが取得可能かどうかを示すフラグ
   */
  canFetchNextPage: boolean
}
const makeDefaultState = (): ProjectApplicationListState => ({
  currentCorporationId: null,
  filteringParameter: {
    projectId: null,
    statusKey: "during_selection",
    sortType: "project_application_created",
  },
  currentPage: 1,
  applications: [],
  isFetching: false,
  canFetchNextPage: true,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProjectApplicationListState = Vue.observable<ProjectApplicationListState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.filteringParameter = defaultState.filteringParameter
  state.currentPage = defaultState.currentPage
  state.applications = defaultState.applications
  state.isFetching = defaultState.isFetching
  state.canFetchNextPage = defaultState.canFetchNextPage
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
enum AppendType {
  TO_HEAD = "TO_HEAD", // 先頭に追加
  TO_TAIL = "TO_TAIL", // 末尾に追加
}
const mutations = {
  setFilteringParameter(parameter: FilteringParameter) {
    state.filteringParameter = parameter
  },
  updateCorporationId(corporationId: string) {
    state.currentCorporationId = corporationId
  },
  resetProjectApplicationList() {
    state.currentPage = 1
    state.applications = []
    state.canFetchNextPage = true
  },
  increasePageNumber() {
    state.currentPage += 1
  },
  appendApplications(
    applications: ProjectApplicationDetailView[],
    toTail: AppendType = AppendType.TO_TAIL
  ) {
    const newList =
      toTail === AppendType.TO_TAIL
        ? [...state.applications, ...applications]
        : [...applications, ...state.applications]

    // idをキーに重複を除外している。
    const uniqueApplications = newList.reduce(
      (
        sum: ProjectApplicationDetailView[],
        application: ProjectApplicationDetailView
      ) => {
        // まだ配列（sum）に追加されていない応募(ID)の場合のみ配列（sum）に加える。
        if (
          sum.every(
            (s: ProjectApplicationDetailView) => s.id !== application.id
          )
        ) {
          sum.push(application)
        }
        return sum
      },
      []
    )
    state.applications = uniqueApplications
  },
  removeFromList(projectApplicationId: string) {
    state.applications = state.applications.filter(
      (s) => s.id !== projectApplicationId
    )
  },
  startFetching() {
    state.isFetching = true
  },
  finishFetching() {
    state.isFetching = false
  },
  setDownCanFetchNextPageFlag() {
    state.canFetchNextPage = false
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get filteringParameter(): FilteringParameter {
    return state.filteringParameter
  },
  get currentPage(): number {
    return state.currentPage
  },
  get applications(): ProjectApplicationDetailView[] {
    return state.applications
  },
  get exists(): boolean {
    return state.applications.length > 0
  },
  get isFetching(): boolean {
    return state.isFetching
  },
  get canFetchNextPage(): boolean {
    return state.canFetchNextPage
  },
  findProjectApplicationById(
    projectApplicationId: string
  ): ProjectApplicationDetailView | null {
    return state.applications.find((s) => s.id === projectApplicationId) || null
  },
  requireSameCorporationId(corporationId: string) {
    if (state.currentCorporationId !== corporationId) {
      throw new StandardApplicationError(
        "現在設定されている企業IDと異なるIDでprojectApplicationListを利用しようとしています。"
      )
    }
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  updateFilteringParameter(parameter: FilteringParameter) {
    mutations.setFilteringParameter(parameter)
  },
  async updateLatestProjectApplication() {
    // 企業IDをチェック
    getters.requireSameCorporationId(clAuth.corporationIdOrFail)

    const projectApplicationDetail = await BackendApi.CorporationMember.Matching.read.getProjectApplicationList(
      {
        page: 1,
        selectionStatus: getters.filteringParameter.statusKey,
        sortType: getters.filteringParameter.sortType,
        universalCorporationId: clAuth.corporationIdOrFail,
        projectId: getters.filteringParameter.projectId || undefined,
      }
    )
    mutations.appendApplications(
      projectApplicationDetail.list,
      AppendType.TO_HEAD
    )
  },
  /**
   * 現在設定されている検索パラメータを使って新しく取得する。
   */
  async fetchNew() {
    if (getters.isFetching) {
      // 現在スカウトを取得中の場合は何もしない。
      return
    }

    mutations.updateCorporationId(clAuth.corporationIdOrFail) // 企業IDを更新

    mutations.startFetching() // 取得処理開始
    mutations.resetProjectApplicationList() // リストの情報を初期化

    const initialPage = 1
    const projectApplicationDetail = await BackendApi.CorporationMember.Matching.read
      .getProjectApplicationList({
        page: initialPage,
        selectionStatus: getters.filteringParameter.statusKey,
        sortType: getters.filteringParameter.sortType,
        universalCorporationId: clAuth.corporationIdOrFail,
        projectId: getters.filteringParameter.projectId || undefined,
      })
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (projectApplicationDetail.numberOfTotalPages <= initialPage) {
      mutations.setDownCanFetchNextPageFlag() // プロジェクトが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendApplications(projectApplicationDetail.list)
  },
  /**
   * 現在設定されているパラメータを利用して次のページのスカウトを取得する。
   */
  async fetchNextPage() {
    if (getters.isFetching || !getters.canFetchNextPage) {
      // 現在スカウトを取得中の場合は何もしない。
      return
    }

    // 企業IDをチェック
    getters.requireSameCorporationId(clAuth.corporationIdOrFail)

    mutations.startFetching() // 取得処理開始

    const nextPage = getters.currentPage + 1
    const projectApplicationDetail = await BackendApi.CorporationMember.Matching.read
      .getProjectApplicationList({
        page: nextPage,
        selectionStatus: getters.filteringParameter.statusKey,
        sortType: getters.filteringParameter.sortType,
        universalCorporationId: clAuth.corporationIdOrFail,
        projectId: getters.filteringParameter.projectId || undefined,
      })
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (projectApplicationDetail.numberOfTotalPages <= nextPage) {
      mutations.setDownCanFetchNextPageFlag() // プロジェクトが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendApplications(projectApplicationDetail.list)
    mutations.increasePageNumber()
  },
  async finishSelection(params: {
    projectApplicationId: string
    isSendMessage: boolean
  }) {
    // 企業IDをチェック
    getters.requireSameCorporationId(clAuth.corporationIdOrFail)

    const projectApplication = getters.findProjectApplicationById(
      params.projectApplicationId
    )

    if (!projectApplication) {
      throw new StandardApplicationError(
        "選考を終了しようとする応募が見つかりません"
      )
    }

    await new BackendApi.CorporationMember.Matching.FinishSelectionWriteRequest(
      {
        universalCorporationId: clAuth.corporationIdOrFail,
        selectionId: projectApplication.selectionId,
        isSendMessage: params.isSendMessage,
      }
    )
      .post()
      .then(() => {
        mutations.removeFromList(params.projectApplicationId)
      })
      .catch((e: ApplicationApiError) => {
        if (e.isUnprocessableEntity()) {
          // 422エラーの場合は、エラーメッセージを表示し、リロードする
          alert("選考終了済みです。リロードし、ページを再読み込みします。")
          window.location.reload()
        }
      })
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const projectApplicationList = { getters, actions }
