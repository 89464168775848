import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  status: PublicStatus
}

export type PublicStatus = "open_to_public" | "open_to_users" | "close"

export class ChangePublicStatusWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string = "/user_profile/user/status/change"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
