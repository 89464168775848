import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { OnboardingStatusView } from "~/backendApis/viewModel"

const basePath = "user_account/user"

export const read = {
  getCurrentUserOnboardingStatus: () =>
    new ApiClient<OnboardingStatusView>().get(
      `${basePath}/onboarding_status/my`
    ),
}
