import { Store } from "vuex"
import { clAuth } from "~/clAuth"

// TODO : anyのところは他の箇所含めてあとで調べて設定する。
export default ({ app }: { app: any; store: Store<any> }) => {
  // 本番環境以外では処理を実行しないようにここで中断する。
  if (process.env.NODE_ENV !== "production") {
    return
  }

  app.router.afterEach((_to: Object, _from: Object) => {
    if (!clAuth.loggedIn) {
      app.$ga.set("userId", clAuth.userId)
    }
  })
}
