import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ProjectListView } from "~/backendApis/viewModel/projectSearch"

interface Params {
  universalCorporationId: string
  page?: number // pageをそもそもないものとして組み上げた箇所もあるので、optionalとした
}

export class GetProjectListReadRequest extends ReadRequest<ProjectListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ProjectListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    // queryParamsGeneratorが、ここでは利用しない検索ワード(searchWord)を
    // 要求するシグネチャであったので、ここではstringを直接組み立てた
    //
    // [TODO] queryParamsGeneratorのように、パラメータを組み立てられる
    // 利用可能なメソッドが出来次第、そちらを使いたい
    return `/project/corporation_member/${
      this.params.universalCorporationId
    }/project/list${this.params.page ? `?page=${this.params.page}` : ""}`
  }
}
