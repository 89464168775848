

















import Vue from "vue"

export default Vue.extend({
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
  },
  methods: {
    clickAccountImageMenu() {
      this.$emit("click-account-image-menu")
    },
  },
})
