import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
  })
}

const db = firebase.firestore()
const auth = firebase.auth()
const passwordProvider = firebase.auth.EmailAuthProvider
const facebookProvider = new firebase.auth.FacebookAuthProvider()
const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.setCustomParameters({
  prompt: "select_account",
})
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
export {
  db,
  auth,
  passwordProvider,
  facebookProvider,
  googleProvider,
  serverTimestamp,
}
