import { ApplicationError } from "./ApplicationError"

/*
 * 主に認証必須画面で利用するエラークラス。
 * 認証必須画面では必ず userId がclAuthに格納されているはずなので、それが格納されていなければ本エラークラスをThrowする。
 */
export class NotFoundUserIdOnClAuthError extends ApplicationError {
  constructor() {
    super({
      className: "NotFoundUserIdOnClAuthError",
      message: "userIdの値がclAuthに設定されていません。",
    })
  }
}
