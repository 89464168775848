// プロフィール登録画面に利用する処理
// プロフィール登録画面は複数ページで構成されるもので、登録完了後は登録画面に来る前にいたページにリダイレクトする仕様になっている。
// そのために来る前にいたページのパスをSession Storageに保持するようにしている。
// 尚、Storeに保持する方法を取らなかったのは、ユーザーがページをリロードしたケースなども対応できるようにするため。
const key = "profileCreationReferer"

// 登録完了後に戻るページとして妥当なパスかどうかを判定
const validPathForRefererPath = (path: string): boolean => {
  return /^\/projects\/[a-zA-Z0-9]+(\?.*)?$/.test(path)
}

export const setRefererPath = (path: string): void => {
  if (validPathForRefererPath(path)) {
    sessionStorage.setItem(key, path)
  }
}

export const consumeRefererPath = (): string | null => {
  const path = sessionStorage.getItem(key)

  if (path === null || !validPathForRefererPath(path)) {
    return null
  }

  sessionStorage.removeItem(key)
  return path
}
