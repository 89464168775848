import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { NumberOfUnreadMessagesView } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
}

export class GetNumberOfUnreadMessagesOfScoutReadRequest extends ReadRequest<NumberOfUnreadMessagesView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<NumberOfUnreadMessagesView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/matching/corporation_member/${this.params.universalCorporationId}/message/number_of_unread_messages_of_scout`
  }
}
