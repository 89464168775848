import { corporationContractAppeal } from "./corporationContractAppeal"
import { corporationSideChat } from "./corporationSideChat"
import { corporationSideFavoriteUserList } from "./corporationSideFavoriteUserList"
import { favoriteProjectList } from "./favoriteProjectList"
import { flashMessage } from "./flashMessage"
import { mobileNavigationMenu } from "./mobileNavigationMenu"
import { ogp } from "./ogp"
import { profileInitialCreationFlow } from "./profileInitialCreationFlow"
import { profileSearchImpressionEvent } from "./profileSearchImpressionEvent"
import { profileSearchList } from "./profileSearchList"
import { profileSearchParameterOption } from "./profileSearchParameterOption"
import { projectApplicationFilteringParameterOption } from "./projectApplicationFilteringParameterOption"
import { projectApplicationList } from "./projectApplicationList"
import { scoutFilteringParameterOption } from "./scoutFilteringParameterOption"
import { scoutList } from "./scoutList"
import { projectSearchList } from "./projectSearchList"
import { projectSearchParameterOption } from "./projectSearchParameterOption"
import { scrollPosition } from "./scrollPosition"
import { userSideChat } from "./userSideChat"
import { initlizeStateWhenSwitchingAccount } from "./stateInitializer"
import { projectApplyMessage } from "./projectApplyMessage"
import { projectApplicationFilteringParameterForSingleProject } from "./projectApplicationFilteringParameterForSingleProject"
import { templateList } from "./templateList"
import { corporationProjectCount } from "./corporationProjectCount"

/**
 * グローバルで保持する状態を操作するためのオブジェクトの定義。（プリフィックスの cl はCrowdLinksの略。）
 * この値を plugins/dependencyInjection でVueインスタンスにプロパティとして定義し、グローバルで利用できるようにしている。
 *
 * 同じ目的を果たす代替手段としてVuexが存在するが、クラウドリンクスではVuexは利用せずに独自にグローバルで状態管理するオブジェクトを定義している。
 * こうしているのはVuexだと型チェックが入らない（入れるのが難しい）ためであり、運用を考えると型が効かないデメリットが大きいと考えて独自で型が効く形で定義することにした。
 * ただし、オブジェクトの構成は基本的にVuexと同じで、それぞれstate,mutations,getters,actionsを定義して状態を操作するようにしている。
 */
export const clStores = {
  corporationContractAppeal,
  corporationSideChat,
  corporationSideFavoriteUserList,
  favoriteProjectList,
  flashMessage,
  mobileNavigationMenu,
  ogp,
  profileInitialCreationFlow,
  profileSearchImpressionEvent,
  profileSearchList,
  profileSearchParameterOption,
  projectApplicationFilteringParameterOption,
  projectApplicationList,
  scoutFilteringParameterOption,
  scoutList,
  projectSearchList,
  projectSearchParameterOption,
  scrollPosition,
  userSideChat,
  initlizeStateWhenSwitchingAccount,
  projectApplyMessage,
  projectApplicationFilteringParameterForSingleProject,
  templateList,
  corporationProjectCount,
}

export type CLStoresSchema = typeof clStores
