import { Context } from "@nuxt/types"
import { makeAuthPagePath } from "~/uiLogics/auth/authExecutor"
import { clAuth } from "~/clAuth"

/**
 * ユーザーアカウントであることが必須のページで利用されるミドルウェア。
 */
export default function ({ route, redirect, error }: Context) {
  if (clAuth.loggedIn) {
    return
  }

  if (clAuth.finishedAuthProcess) {
    error({ statusCode: 404 })
  } else {
    const authPath = makeAuthPagePath({ redirectPath: route.fullPath, route })
    redirect(authPath)
  }
}
