import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { CorporationMemberView } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
}

export class GetMyCorporationMemberInfoReadRequest extends ReadRequest<CorporationMemberView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<CorporationMemberView | null> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/corporation_account/corporation_member/${this.params.universalCorporationId}/corporation_member/show`
  }
}
