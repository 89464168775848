












import Vue from "vue"
import PSwitchableAccountList from "./local/PSwitchableAccountList.vue"
import MenuList, {
  MenuItem,
} from "~/components/guideline/navigation/MenuList.vue"
import { OUTLINED, FILLED } from "~/components/guideline/icon/MaterialIcon.vue"
import { UserRole } from "~/config/acl/userRoleList"

export default Vue.extend({
  components: {
    MenuList,
    PSwitchableAccountList,
  },
  props: {
    isVisibleMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    menuList(): MenuItem[] {
      // 企業担当者用メニュー
      if (
        this.$clAuth.corporationId &&
        this.$clAuth.userRole === UserRole.CORPORATION
      ) {
        const allMenuListItems: MenuItem[] = [
          {
            link: "/corporation/profile",
            label: "企業アカウントページ",
            iconName: "business",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/edit",
            label: "企業プロフィール編集",
            iconName: "settings",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/members",
            label: "メンバー管理",
            iconName: "people_outline",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/member_profile/edit",
            label: "担当者情報",
            iconName: "manage_accounts",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/member_profile/login_password",
            label: "パスワード変更",
            iconName: "key",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/payment/management",
            label: "ご加入プラン",
            iconName: "assignment_turned_in",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/logout",
            label: "ログアウト",
            iconName: "exit_to_app",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "https://help.crowdlinks.jp/",
            label: "ヘルプ",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
          {
            link:
              "https://docs.google.com/forms/d/1hzkn9p5qbpZmpMmgNlrzSWeNUQxOv-FJrTrhsZBwUzo/edit",
            label: "お問い合わせ",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
          {
            link: "/terms",
            label: "利用規約",
            iconName: "",
            iconType: "",
            isTargetBlank: false, // falseだと `http://localhost:8000/profiles/mailto:crowdlinks-support@crowdworks.co.jp` に飛ばされる...
          },
          {
            link: "https://crowdworks.co.jp/privacy_policy/",
            label: "プライバシーポリシー",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
        ]
        if (this.$clAuth.contractType?.contractType === "paid_plan_contract") {
          return allMenuListItems
        }
        return allMenuListItems.filter(
          (item) => item.label !== "ご加入プランについて"
        )
      }
      if (this.$clAuth.corporationId) {
        // 企業アカウント
        const allMenuListItems: MenuItem[] = [
          {
            link: "/corporation/profile",
            label: "企業アカウントページ",
            iconName: "business",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/edit",
            label: "企業プロフィール編集",
            iconName: "settings",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/members",
            label: "メンバー管理",
            iconName: "people_outline",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/payment/management",
            label: "ご加入プラン",
            iconName: "assignment_turned_in",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/logout",
            label: "ログアウト",
            iconName: "exit_to_app",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "https://help.crowdlinks.jp/",
            label: "ヘルプ",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
          {
            link:
              "https://docs.google.com/forms/d/1hzkn9p5qbpZmpMmgNlrzSWeNUQxOv-FJrTrhsZBwUzo/edit",
            label: "お問い合わせ",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
          {
            link: "/terms",
            label: "利用規約",
            iconName: "",
            iconType: "",
            isTargetBlank: false, // falseだと `http://localhost:8000/profiles/mailto:crowdlinks-support@crowdworks.co.jp` に飛ばされる...
          },
          {
            link: "https://crowdworks.co.jp/privacy_policy/",
            label: "プライバシーポリシー",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
        ]
        if (this.$clAuth.contractType?.contractType === "paid_plan_contract") {
          return allMenuListItems
        }
        return allMenuListItems.filter(
          (item) => item.label !== "ご加入プランについて"
        )
      }
      if (this.$clAuth.loggedIn) {
        // 個人アカウント
        return [
          {
            link: `/profiles/${this.$clAuth.userIdOrFail}`,
            label: "マイページ",
            iconName: "person",
            iconType: FILLED,
            isTargetBlank: false,
          },
          {
            link: `/user/account_setting/profile_readability`,
            label: "企業ブロック",
            iconName: "visibility_off",
            iconType: FILLED,
            isTargetBlank: false,
          },
          {
            link: "/user/account_setting",
            label: "アカウント設定",
            iconName: "settings",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/corporation/new",
            label: "企業アカウント作成",
            iconName: "business",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "/logout",
            label: "ログアウト",
            iconName: "exit_to_app",
            iconType: OUTLINED,
            isTargetBlank: false,
          },
          {
            link: "https://help.crowdlinks.jp/",
            label: "ヘルプ",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
          {
            link:
              "https://docs.google.com/forms/d/e/1FAIpQLSfOLlcU4ezAa3EWqo3xG653h2Ap2TvCbtMausmNxHIYfP3rHg/viewform",
            label: "お問い合わせ",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
          {
            link: "/terms",
            label: "利用規約",
            iconName: "",
            iconType: "",
            isTargetBlank: false, // falseだと `http://localhost:8000/profiles/mailto:crowdlinks-support@crowdworks.co.jp` に飛ばされる...
          },
          {
            link: "https://crowdworks.co.jp/privacy_policy/",
            label: "プライバシーポリシー",
            iconName: "",
            iconType: "",
            isTargetBlank: true,
          },
        ]
      }
      return []
    },
  },
  watch: {
    $route() {
      // ページ遷移でメニュー閉じる
      this.closeMenu()
    },
  },
  methods: {
    closeMenu() {
      this.$emit("close-menu")
    },
  },
})
