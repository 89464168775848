












import Vue, { PropType } from "vue"
import PErrorPage from "~/components/custom/error/PErrorPage.vue"
import { UniversalReadAcl } from "~/config/acl/accessControlList"

interface Error {
  statusCode: number
}

interface ErrorPageContent {
  imagePath: string
  title: string
  description: string
  isPossibleRequiredAuth: boolean
}

const errorPageContents: { [code: number]: ErrorPageContent } = {
  403: {
    imagePath: require("~/assets/images/error-page/403.svg"),
    title: "閲覧権限がありませんでした",
    description: "ログイン中のアカウントやアクセス権限を確認してください。",
    isPossibleRequiredAuth: true,
  },
  404: {
    imagePath: require("~/assets/images/error-page/404.svg"),
    title: "ページが見つかりませんでした",
    description:
      "お探しのページは削除されたか、閲覧するにはログインが必要な可能性がございます。",
    isPossibleRequiredAuth: true,
  },
  500: {
    imagePath: require("~/assets/images/error-page/500.svg"),
    title: "システムエラーです",
    description: "しばらく時間を置いてからアクセスしてください。",
    isPossibleRequiredAuth: false,
  },
}

export default Vue.extend({
  components: {
    PErrorPage,
  },
  layout: "application",
  props: {
    error: {
      type: Object as PropType<Error>,
      required: true,
    },
  },
  computed: {
    errorImagePath(): string {
      return (
        this.errorPageContent?.imagePath || errorPageContents[500].imagePath
      )
    },
    errorTitle(): string {
      return this.errorPageContent?.title || errorPageContents[500].title
    },
    errorDescription(): string {
      return (
        this.errorPageContent?.description || errorPageContents[500].description
      )
    },
    errorPageContent(): ErrorPageContent | undefined {
      return errorPageContents[this.error.statusCode]
    },
    isAccessibleRequireAuthenticationResources(): boolean {
      return this.$clAuth.hasPermission(
        UniversalReadAcl.REQUIRE_AUTHENTICATION_RESOURCES
      )
    },
    isVisibleAuthButtons(): boolean {
      return (
        !this.isAccessibleRequireAuthenticationResources &&
        !!this.errorPageContent?.isPossibleRequiredAuth
      )
    },
  },
  mounted() {
    const statusCode = this.error.statusCode

    // 500 または errorPageContents が用意されていない場合
    if (statusCode === 500 || !this.errorPageContent) {
      this.rollbar &&
        this.rollbar.error(
          `フロントエンドで予期せぬエラーが発生しました。 ステータスコード : ${statusCode}`
        )
    }
  },
  methods: {
    facebookLogin(): void {
      this.$clAuth.loginFacebook()
    },
    googleLogin(): void {
      this.$clAuth.loginGoogle()
    },
  },
})
