interface ApplicationErrorFields {
  className: string
  message: string
}

export class ApplicationError extends Error {
  constructor({ className, message }: ApplicationErrorFields) {
    super(message)
    this.name = className
  }
}
