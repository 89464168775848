import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { UserContactInformationView } from "~/backendApis/viewModel/externalNotification"
import ApplicationApiError from "~/backendApis/foundation/ApplicationApiError"

export class GetMyContactInformationReadRequest extends ReadRequest<UserContactInformationView> {
  private readonly endpoint: string =
    "/external_notification/user/contact_information/my_contact_information"

  public get(): Promise<UserContactInformationView | null> {
    return this.apiClient.get(this.endpoint).catch((e: ApplicationApiError) => {
      if (e.isNotFoundError()) {
        return null
      }
      throw e
    })
  }
}
