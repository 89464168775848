import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { NumberOfProjectApplicationsForProjectListView } from "~/backendApis/viewModel/matching"

interface Params {
  universalCorporationId: string
  projectIds: string[]
}

export class CountApplicationsForProjectsReadRequest extends ReadRequest<NumberOfProjectApplicationsForProjectListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<NumberOfProjectApplicationsForProjectListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectIds } = this.params
    return `/matching/corporation_member/${universalCorporationId}/project_application/count_for_projects?projectIds=${projectIds.join(
      ","
    )}`
  }
}
