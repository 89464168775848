









































































































import Vue, { PropType } from "vue"
import MaterialIcon, {
  OUTLINED,
} from "~/components/guideline/icon/MaterialIcon.vue"
import Overlay from "~/components/guideline/modal/Overlay.vue"

export interface MenuItem {
  link: string
  label: string
  iconName: string
  iconType: string
  isTargetBlank: boolean
}

export default Vue.extend({
  components: {
    MaterialIcon,
    Overlay,
  },
  props: {
    menuList: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconType() {
      return { OUTLINED }
    },
  },
  methods: {
    handleClose() {
      this.$emit("close")
    },
  },
})
