






import Vue from "vue"
import CLayoutTemplate from "~/components/custom/frame/CLayoutTemplate.vue"

export default Vue.extend({
  components: {
    CLayoutTemplate,
  },
})
