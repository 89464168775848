












import Vue from "vue"
import RevisionUpdateModal, {
  emitEventWhenClickReloadButton,
} from "~/components/custom/frame/local/RevisionUpdateModal.vue"
import Wrapper from "~/components/guideline/frame/Wrapper.vue"
import { NewRevisionReflector } from "~/uiLogics/applicationRevision/newRevisionReflector"

interface DataType {
  isRequiredRevisionUpdate: boolean
  newRevisionReflector: NewRevisionReflector | null
}

export default Vue.extend({
  components: {
    Wrapper,
    RevisionUpdateModal,
  },
  data(): DataType {
    return {
      isRequiredRevisionUpdate: false,
      newRevisionReflector: null,
    }
  },
  computed: {
    emitEvents() {
      return {
        emitEventWhenClickReloadButton,
      }
    },
  },
  mounted() {
    this.reflectNewRevision()
  },
  beforeDestroy() {
    if (this.newRevisionReflector !== null) {
      this.newRevisionReflector.finish()
    }
  },
  methods: {
    actionWhenRevisionChanged() {
      this.isRequiredRevisionUpdate = true
    },
    reloadPage() {
      location.reload()
    },
    async reflectNewRevision() {
      this.newRevisionReflector = await NewRevisionReflector.build()
      this.newRevisionReflector.executeActionWhenRevisionChanged(
        this.actionWhenRevisionChanged.bind(this)
      )
    },
  },
})
