






import Vue from "vue"
import { makeAuthPagePath } from "~/uiLogics/auth/authExecutor"
import CLayoutTemplate from "~/components/custom/frame/CLayoutTemplate.vue"
import { isLoggedIn } from "~/uiLogics/webStorage/loggedInStatus"

export default Vue.extend({
  components: {
    CLayoutTemplate,
  },
  data() {
    return {
      isFinishedAuthVerification: false,
    }
  },
  beforeMount() {
    if (isLoggedIn() && this.$route.query.disableAutoTransition !== "1") {
      const path = makeAuthPagePath({ route: this.$route })
      this.$nuxt.$router.push({ path })
      return
    }

    if (this.$clAuth.tryingToLogin) {
      const path = makeAuthPagePath({ route: this.$route })
      this.$nuxt.$router.push({ path })
      return
    } else if (!this.$clAuth.finishedAuthProcess) {
      this.$clAuth.completeAuthProcess()
    }

    this.isFinishedAuthVerification = true
  },
})
