const basePath = "/profiles/new/initial"

export interface FormPage {
  step: number
  type: FormType
  title: string
  path: string
  isSkip: boolean
}

export const formPages: FormPage[] = [
  {
    step: 1,
    type: "job",
    title: "職種を教えて下さい",
    path: `${basePath}/job`,
    isSkip: false,
  },
  {
    step: 2,
    type: "skill_tag",
    title: "スキルを教えて下さい",
    path: `${basePath}/skill_tag`,
    isSkip: false,
  },
  {
    step: 3,
    type: "work_style",
    title: "ワークスタイルを教えて下さい",
    path: `${basePath}/work_style`,
    isSkip: false,
  },
  {
    step: 4,
    type: "career",
    title: "経歴を教えて下さい",
    path: `${basePath}/career`,
    isSkip: false,
  },
  {
    step: 5,
    type: "self_introduction",
    title: "キャリアの概要・略歴を教えて下さい",
    path: `${basePath}/self_introduction`,
    isSkip: true,
  },
  {
    step: 6,
    type: "capability",
    title: "課題解決できることを教えて下さい",
    path: `${basePath}/capability`,
    isSkip: true,
  },
  {
    step: 7,
    type: "career_change_intention",
    title: "副業からの転職意向を教えて下さい",
    path: `${basePath}/career_change_intention`,
    isSkip: false,
  },
  {
    step: 8,
    type: "image",
    title: "プロフィール画像を設定して下さい",
    path: `${basePath}/image`,
    isSkip: true,
  },
]

export type FormType =
  | "job"
  | "skill_tag"
  | "work_style"
  | "career"
  | "self_introduction"
  | "capability"
  | "career_change_intention"
  | "image"
