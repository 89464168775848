import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { CorporationAccountListView } from "~/backendApis/viewModel"

const basePath = "/corporation_account/user"

export const read = {
  getCorporationAccountListByIds: ({
    universalCorporationIds,
  }: {
    universalCorporationIds: string[]
  }) =>
    new ApiClient<CorporationAccountListView>().get(
      `${basePath}/corporation_account/list_by_ids?universalCorporationIds=${universalCorporationIds}`
    ),
}
