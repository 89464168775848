import { WriteRequest } from "~/backendApis/foundation/WriteRequest"
import { ReceiveSettableMailType } from "~/backendApis/viewModel/externalNotification"

interface Params {
  subscribeGroupName: ReceiveSettableMailType
}

export class RemoveSuppressionMailSubscribeSettingWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/external_notification/user/mail_subscribe_setting/suppression/remove"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
