import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ContractTypeView } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
}

export class GetCurrentContractTypeReadRequest extends ReadRequest<ContractTypeView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<ContractTypeView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/corporation_contract/corporation_member/${this.params.universalCorporationId}/contract_type/current_contract_type`
  }
}
