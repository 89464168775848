import { FullInfoUserProfileView } from "../../../../viewModel/userProfileSearch"
import ApplicationApiError from "../../../../foundation/ApplicationApiError"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"

export class GetCurrentUserProfileReadRequest extends ReadRequest<FullInfoUserProfileView> {
  private readonly endpoint: string =
    "/user_profile_search/user/user_profile/me"

  public get(): Promise<FullInfoUserProfileView | null> {
    return this.apiClient.get(this.endpoint).catch((e: ApplicationApiError) => {
      if (e.isNotFoundError()) {
        return null
      }
      throw e
    })
  }
}
