import { ApiClient } from "~/backendApis/foundation/ApiClient"
import {
  ScoutDetailListView,
  ProjectApplicationDetailListView,
  NumberOfScoutsView,
  NumberOfProjectApplicationsForCorporationView,
} from "~/backendApis/viewModel"

import {
  SelectionStatus,
  ScoutSortType,
  ProjectApplicationSortType,
  NumberOfUnreadMessagesView,
} from "~/backendApis/viewModel/matching"

const basePath = "/matching/corporation_member"

export const read = {
  getScoutList: (params: {
    page: number
    selectionStatus: SelectionStatus
    sortType: ScoutSortType
    universalCorporationId: string
  }) => {
    const queryParams = [
      `page=${params.page}`,
      `selectionStatus=${params.selectionStatus}`,
      `orderBy=${params.sortType}`,
    ]

    const query = queryParams.join("&")
    return new ApiClient<ScoutDetailListView>().get(
      `${basePath}/${params.universalCorporationId}/scout/list?${query}`
    )
  },

  getProjectApplicationList: (params: {
    page: number
    selectionStatus: SelectionStatus
    sortType: ProjectApplicationSortType
    universalCorporationId: string
    projectId?: string
  }) => {
    const queryParams = [
      `page=${params.page}`,
      `selectionStatus=${params.selectionStatus}`,
      `orderBy=${params.sortType}`,
    ]
    if (params.projectId) {
      queryParams.push(`projectId=${params.projectId}`)
    }

    const query = queryParams.join("&")
    return new ApiClient<ProjectApplicationDetailListView>().get(
      `${basePath}/${params.universalCorporationId}/project_application/list?${query}`
    )
  },

  countScouts: (params: {
    universalCorporationId: string
    daysOfAggregationPeriod?: number
  }) =>
    new ApiClient<NumberOfScoutsView>().get(
      `${basePath}/${params.universalCorporationId}/scout/count${
        params.daysOfAggregationPeriod
          ? `?daysOfAggregationPeriod=${params.daysOfAggregationPeriod}`
          : ""
      }`
    ),

  countProjectApplications: (params: {
    universalCorporationId: string
    daysOfAggregationPeriod?: number
  }) =>
    new ApiClient<NumberOfProjectApplicationsForCorporationView>().get(
      `${basePath}/${params.universalCorporationId}/project_application/count${
        params.daysOfAggregationPeriod
          ? `?daysOfAggregationPeriod=${params.daysOfAggregationPeriod}`
          : ""
      }`
    ),
  getNumberOfUnreadMessagesOfApplication: (params: {
    universalCorporationId: string
    projectId?: string
  }) =>
    new ApiClient<NumberOfUnreadMessagesView>().get(
      `${basePath}/${
        params.universalCorporationId
      }/message/number_of_unread_messages_of_application${
        params.projectId ? `?projectId=${params.projectId}` : ""
      }`
    ),
}
