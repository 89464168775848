import { AddToCorporationFavoriteUserList } from "./write/AddToCorporationFavoriteUserList"
import { RemoveFromCorporationFavoriteUserList } from "./write/RemoveFromCorporationFavoriteUserList"

export const FavoriteUser = {
  /**
   * write
   */
  AddToCorporationFavoriteUserList,
  RemoveFromCorporationFavoriteUserList,
}
