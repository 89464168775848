import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  scoutTargetUniversalUid: string
  scoutMessage: string
  targetProjectId: string | null
}

export class ScoutWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const {
      scoutTargetUniversalUid,
      scoutMessage,
      targetProjectId,
    } = this.params
    return this.apiClient.post(this.makeEndpoint(), {
      scoutTargetUniversalUid,
      scoutMessage,
      targetProjectId: targetProjectId || null,
    })
  }

  private makeEndpoint(): string {
    return `/matching/corporation_member/${this.params.universalCorporationId}/scout/create`
  }
}
