import { ApiClient } from "~/clApi/main/foundation/ApiClient"
import { ProjectFreeAppliableCorporationView } from "~/clApi/viewModel/corporationAccount"
import ApplicationApiError from "~/clApi/main/foundation/ApplicationApiError"

const basePath = "/corporation_account/general"

export const read = {
  getProjectFreeAppliableCorporationById: (params: {
    universalCorporationId: string
  }) =>
    new ApiClient<ProjectFreeAppliableCorporationView | null>()
      .get(
        `${basePath}/project_free_appliable_corporation/show/${params.universalCorporationId}`
      )
      .catch((e: ApplicationApiError) => {
        if (e.isNotFoundError()) {
          return null
        }
        throw e
      }),
}
