import { Matching } from "./matching"
import { CorporationAccount } from "./corporationAccount"
import { CorporationContract } from "./corporationContract"
import { eventLog } from "./eventLog"
import { ExternalNotification } from "./externalNotification"
import { FavoriteUser } from "./favoriteUser"
import { Project } from "./project"
import { ProjectSearch } from "./projectSearch"
import { UserAccount } from "./userAccount"
import { userProfile } from "./userProfile"
import { UserProfileSearch } from "./userProfileSearch"

export const CorporationMember = {
  Matching,
  CorporationAccount,
  CorporationContract,
  eventLog,
  ExternalNotification,
  FavoriteUser,
  Project,
  ProjectSearch,
  UserAccount,
  userProfile,
  UserProfileSearch,
}
