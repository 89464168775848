// アクションログ等で複数のフロントエンドAPPを跨いで利用されているキーであるため、変更する場合は必ず該当箇所全てを変更すること。
const globalKey = "tryToSignupCorporationMember"
const value = "1"

/**
 * 企業担当者アカウントの作成中であるか判定する処理
 * crowdlinks-appで設定された値を参照する
 */
export const isTryToSignupCorporationMember = (): boolean => {
  return localStorage.getItem(globalKey) === value
}
