import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { CorporationMemberListView } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
}

export class GetCorporationMemberListReadRequest extends ReadRequest<CorporationMemberListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<CorporationMemberListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return `/corporation_account/corporation_member/${this.params.universalCorporationId}/corporation_member/list`
  }
}
