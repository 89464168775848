import { ApiClient } from "~/backendApis/foundation/ApiClient"
import { TemporaryAccountView } from "~/backendApis/viewModel"

const basePath = "/user_account/general"

export const read = {
  getTemporaryAccount: (params: { oodCode: string }) =>
    new ApiClient<TemporaryAccountView>().get(
      `${basePath}/temporary_account/${params.oodCode}`
    ),
}
