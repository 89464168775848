import {
  auth,
  passwordProvider,
  facebookProvider,
  googleProvider,
} from "~/plugins/firebase"

export const reauthenticateGoogle = () => {
  setReauthFlag()
  return auth.currentUser!.reauthenticateWithRedirect(googleProvider)
}

export const reauthenticateFacebook = () => {
  setReauthFlag()
  return auth.currentUser!.reauthenticateWithRedirect(facebookProvider)
}

export const linkPassword = (email: string, password: string) => {
  const credential = passwordProvider.credential(email, password)
  return auth.currentUser!.linkWithCredential(credential)
}

export const linkGoogle = () => {
  return auth.currentUser!.linkWithRedirect(googleProvider)
}

export const linkFacebook = () => {
  return auth.currentUser!.linkWithRedirect(facebookProvider)
}

export const unlink = (providerId: string) => {
  return auth.currentUser!.unlink(providerId)
}

const key = "cl_auth.proof_of_trying_reauth"
const value = "1"

/*
 * 再認証の開始を示すフラグを立てる処理
 */
const setReauthFlag = (): void => {
  sessionStorage.setItem(key, value)
}

/*
 * 再認証中かどうかを判定する処理
 */
export const isReauth = (): boolean => {
  return sessionStorage.getItem(key) === value
}

/*
 * 再認証の終了を示すフラグを削除する処理
 */
export const finishReauth = (): void => {
  sessionStorage.removeItem(key)
}
