import { WriteRequest } from "~/backendApis/foundation/WriteRequest"
import { ProjectStatusType } from "~/backendApis/viewModel"

interface Params {
  universalCorporationId: string
  projectId: string
  status: ProjectStatusType
}

export class ChangeProjectStatusWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const { status } = this.params
    return this.apiClient.post(this.makeEndpoint(), { status })
  }

  private makeEndpoint(): string {
    const { universalCorporationId, projectId } = this.params
    return `/project/corporation_member/${universalCorporationId}/project/${projectId}/status/change`
  }
}
