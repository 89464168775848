import {
  UserAccountRoleList,
  CorporationAccountRoleList,
  UserRole,
} from "~/config/acl/userRoleList"
import {
  UserAccountView,
  decorateUserAccountView,
  FullInfoUserProfileView,
  CorporationAccountView,
  ContractTypeView,
} from "~/backendApis/viewModel"
import { thumbnail } from "~/clHelpers/thumbnail"
import { StandardApplicationError } from "~/errorHandlers"
import { accessControlListOfRole } from "~/config/acl/accessControlListOfRole"
import { Acl } from "~/config/acl/accessControlList"
import { limitOfRole } from "~/config/acl/limitOfRole"

export interface AccountMetadata {
  imageUrl: string | null
}

export interface Role {
  userAccountRole: UserAccountRoleList
  corporationAccountRole: CorporationAccountRoleList | null
  userRole: UserRole | null
}

export const hasPermission = (role: Role, action: Acl) => {
  const isIncludedInAcl = (
    role: UserAccountRoleList | CorporationAccountRoleList,
    action: Acl
  ) => accessControlListOfRole[role].includes(action)

  return (
    isIncludedInAcl(role.userAccountRole, action) ||
    (!!role.corporationAccountRole &&
      isIncludedInAcl(role.corporationAccountRole, action))
  )
}

export const buildAccountMetadata = (
  userProfile: FullInfoUserProfileView | null,
  corporation: CorporationAccountView | null
): AccountMetadata => {
  if (userProfile && corporation) {
    // 企業アカウントで操作中
    return {
      imageUrl:
        corporation.logoImage &&
        thumbnail.generateURL({
          image: corporation.logoImage,
          width: 50,
          height: 50,
        }),
    }
  } else if (userProfile) {
    // ユーザーアカウントで操作中
    return {
      imageUrl:
        userProfile.image &&
        thumbnail.generateURL({
          image: userProfile.image,
          width: 50,
          height: 50,
        }),
    }
  } else if (!userProfile && !corporation) {
    // 未ログイン
    return {
      imageUrl: null,
    }
  } else {
    throw new StandardApplicationError(
      "AccountMetadataの生成時に要求された状態が不正でした。"
    )
  }
}

export const buildDefaultAccountMetaData = (): AccountMetadata => ({
  imageUrl: null,
})

export const buildRole = (params: {
  userAccount: UserAccountView | null
  corporationAccount: CorporationAccountView | null
  contractType: ContractTypeView | null
}): Role => {
  const judgeUserAccountRole = (): UserAccountRoleList => {
    /**
     * １つ１つで必要な条件をすべて記述するようにしている。
     * 書き方としては単調だが、条件としては複雑になりやすいところなので、１つの条件を見れば理解できるように各ケースで完結するようにしている。
     */
    switch (true) {
      case !params.userAccount:
        return UserAccountRoleList.UNKNOWN_ACCOUNT_ROLE // 未ログインアカウント
      case !!params.userAccount &&
        !decorateUserAccountView(params.userAccount).isCompletedSignUp():
        return UserAccountRoleList.PRE_SIGN_UP_ACCOUNT_ROLE // 会員登録完了前のアカウント
      case !!params.userAccount &&
        decorateUserAccountView(params.userAccount).isCompletedSignUp() &&
        decorateUserAccountView(params.userAccount).isAssistUserGrade():
        return UserAccountRoleList.NO_ENOUGH_PROFILE_ACCOUNT_ROLE // プロフィールの入力が一定以下のアカウント
      case !!params.userAccount &&
        decorateUserAccountView(params.userAccount).isCompletedSignUp() &&
        decorateUserAccountView(params.userAccount).isStandardUserGrade():
        return UserAccountRoleList.ENOUGH_PROFILE_ACCOUNT_ROLE // プロフィールの入力が一定以上のアカウント
      default:
        throw new StandardApplicationError(
          "ユーザーアカウントのロールの判定に不正な状態を要求されました"
        )
    }
  }

  const judgeCorporationAccountRole = (): CorporationAccountRoleList | null => {
    /**
     * １つ１つで必要な条件をすべて記述するようにしている。
     * 書き方としては単調だが、条件としては複雑になりやすいところなので、１つの条件を見れば理解できるように各ケースで完結するようにしている。
     */
    switch (true) {
      case !params.corporationAccount:
        return null // 企業アカウントではない
      case !!params.corporationAccount && !params.contractType?.contractType:
        return CorporationAccountRoleList.FREE_ACCOUNT_ROLE // 契約なしの企業アカウント
      case !!params.corporationAccount &&
        params.contractType?.contractType === "free_trial_contract":
        return CorporationAccountRoleList.TRIAL_ACCOUNT_ROLE // トライアル契約の企業アカウント
      case !!params.corporationAccount &&
        params.contractType?.contractType === "paid_plan_contract":
        return CorporationAccountRoleList.PAID_ACCOUNT_ROLE // 有料契約の企業アカウント
      default:
        throw new StandardApplicationError(
          "企業アカウントのロールの判定に不正な状態を要求されました"
        )
    }
  }
  const judgeUserRole = (): UserRole | null => {
    if (!params.userAccount) {
      return null
    }
    if (params.userAccount.roleId === 1) {
      return UserRole.USER
    }
    if (params.userAccount.roleId === 2) {
      return UserRole.CORPORATION
    }
    return null
  }

  const userAccountRole: UserAccountRoleList = judgeUserAccountRole()
  const corporationAccountRole: CorporationAccountRoleList | null = judgeCorporationAccountRole()
  const userRole: UserRole | null = judgeUserRole()
  return { userAccountRole, corporationAccountRole, userRole }
}

export const buildUnkownUserRole = (): Role => ({
  userAccountRole: UserAccountRoleList.UNKNOWN_ACCOUNT_ROLE,
  corporationAccountRole: null,
  userRole: null,
})

export const limitCount = (role: Role, action: Acl): number | null => {
  if (role.corporationAccountRole) {
    // 企業アカウントの場合
    const limitOfAcl = limitOfRole[role.corporationAccountRole].find(
      (v) => v.acl === action
    )
    if (limitOfAcl) {
      return limitOfAcl.limit
    }
  }
  return null
}
