/**
 * プロジェクト検索に関する状態管理をしている。
 */
import Vue from "vue"
import { registerStateInitializerForSwitchingAccount } from "../stateInitializer"
import { ProjectView } from "~/backendApis/viewModel"
import { BackendApi } from "~/backendApis"

/**
 * Stateの定義
 * グローバルで保持する情報。
 * ==========================================================================
 */
export interface SearchParams {
  /**
   * 公開中のプロジェクトのみに絞り込むかどうかのフラグ
   */
  isFilteredToPublishedProjects: boolean
  /**
   * 検索ワード
   */
  searchWord: string
  /**
   * 稼働形態のID
   */
  workingFormIds: number[]
  /**
   * 職種大カテのID
   */
  largeJobCategoryIds: number[]
  /**
   * 最小稼働可能時間
   */
  possibleOperatingMinHours: number[]
  /**
   * 並び順がスコア（おすすめ）順かどうかのフラグ（おすすめ順ではない場合は公開日順）
   */
  orderByScore: boolean
}
interface ProjectSearchListState {
  /**
   * 現在のページ数
   */
  currentPage: number
  /**
   * 検索のパラメーター
   */
  searchParams: SearchParams
  /**
   * プロジェクトのリスト
   */
  projects: ProjectView[]
  /**
   * プロジェクトを取得中（バックエンドと通信中）かどうかを示すフラグ
   */
  isFetchingProjects: boolean
  /**
   * 次のページが取得可能かどうかを示すフラグ
   */
  canFetchNextPage: boolean
}
const makeDefaultState = () => ({
  currentPage: 1,
  searchParams: {
    isFilteredToPublishedProjects: true,
    searchWord: "",
    workingFormIds: [],
    largeJobCategoryIds: [],
    possibleOperatingMinHours: [],
    orderByScore: true,
  },
  projects: [],
  isFetchingProjects: false,
  canFetchNextPage: true,
})
// オブジェクトをリアクティブ（Vueインスタンスが変更を検知できる状態）にするためにobservableを利用している。
const state: ProjectSearchListState = Vue.observable<ProjectSearchListState>(
  makeDefaultState()
)

// アカウント切り替え時にstateを初期化
registerStateInitializerForSwitchingAccount(() => {
  const defaultState = makeDefaultState()
  state.currentPage = defaultState.currentPage
  state.searchParams = defaultState.searchParams
  state.projects = defaultState.projects
  state.isFetchingProjects = defaultState.isFetchingProjects
  state.canFetchNextPage = defaultState.canFetchNextPage
})

/**
 * Mutationの定義
 * stateを扱える唯一のオブジェクト。stateをどのように更新できるのか明示する役割を持つ。
 * ==========================================================================
 */
const mutations = {
  resetProjectList() {
    state.currentPage = 1
    state.projects = []
    state.canFetchNextPage = true
  },
  increasePageNumber() {
    state.currentPage += 1
  },
  setSearchParams(parmas: SearchParams): void {
    state.searchParams = parmas
  },
  appendProjects(projects: ProjectView[]) {
    // idをキーに重複を除外している。
    const uniqueProjects = [...state.projects, ...projects].reduce(
      (sum: ProjectView[], project: ProjectView) => {
        // まだ配列（sum）に追加されていないプロジェクト(ID)の場合のみ配列（sum）に加える。
        if (sum.every((s: ProjectView) => s.id !== project.id)) {
          sum.push(project)
        }
        return sum
      },
      []
    )
    state.projects = uniqueProjects
  },
  startFetching() {
    state.isFetchingProjects = true
  },
  finishFetching() {
    state.isFetchingProjects = false
  },
  setDownCanFetchNextPageFlag() {
    state.canFetchNextPage = false
  },
}

/**
 * Getterの定義
 * stateの内部情報にアクセスできる唯一のオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const getters = {
  get currentPage(): number {
    return state.currentPage
  },
  get projects(): ProjectView[] {
    return state.projects
  },
  get exists(): boolean {
    return state.projects.length > 0
  },
  get searchParams(): SearchParams {
    return state.searchParams
  },
  get isFetchingProjects(): boolean {
    return state.isFetchingProjects
  },
  get canFetchNextPage(): boolean {
    return state.canFetchNextPage
  },
  /**
   * リスト上に保持しているプロジェクトのリストからIDに該当するものを取得する。
   */
  getProjectByIdFromList: (projectId: string): ProjectView | null =>
    state.projects.find((p) => p.id === projectId) || null,
  /**
   * リスト上に保持しているプロジェクトのリストからIDに該当するものを取得する。
   * リスト上に存在しない場合は、リモート（バックエンド）から取得する。
   */
  getProjectByIdFromListOrRemote: async (
    projectId: string
  ): Promise<ProjectView | null> => {
    const projectFromList = getters.getProjectByIdFromList(projectId)
    if (projectFromList) {
      return projectFromList
    }

    const projectFromRemote = await BackendApi.general.projectSearch.read.getProject(
      {
        projectId,
      }
    )
    return projectFromRemote
  },
}

/**
 * Actionの定義
 * stateを利用した処理を実行するオブジェクト。グローバルでアクセス可能。
 * ==========================================================================
 */
const actions = {
  /**
   * 検索パラメーターを更新する。
   */
  updateSearchParams(params: SearchParams) {
    mutations.setSearchParams(params)
  },
  /**
   * 現在設定されている検索パラメータを使って新しく取得する。
   */
  async fetchNew() {
    if (getters.isFetchingProjects) {
      // 現在プロジェクトを取得中の場合は何もしない。
      return
    }

    mutations.startFetching() // 取得処理開始
    mutations.resetProjectList() // リストの情報を初期化

    const initialPage = 1
    const projectList = await BackendApi.general.projectSearch.read
      .getProjectList({
        page: initialPage,
        searchWord: getters.searchParams.searchWord.trim() || null,
        workingFormIds: getters.searchParams.workingFormIds,
        recruitmentLargeJobCategoryIds:
          getters.searchParams.largeJobCategoryIds,
        excludeClosed: getters.searchParams.isFilteredToPublishedProjects,
        possibleOperatingMinHours:
          getters.searchParams.possibleOperatingMinHours,
        orderByScore: getters.searchParams.orderByScore,
      })
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (projectList.numberOfTotalPages <= initialPage) {
      mutations.setDownCanFetchNextPageFlag() // プロジェクトが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendProjects(projectList.list)
  },
  /**
   * 現在設定されている検索パラメータを利用して次のページのプロジェクトを取得する。
   */
  async fetchNextPage() {
    if (getters.isFetchingProjects || !getters.canFetchNextPage) {
      // 現在プロジェクトを取得中の場合は何もしない。
      return
    }

    mutations.startFetching() // 取得処理開始

    const nextPage = getters.currentPage + 1
    const projectList = await BackendApi.general.projectSearch.read
      .getProjectList({
        page: nextPage,
        searchWord: getters.searchParams.searchWord.trim() || null,
        workingFormIds: getters.searchParams.workingFormIds,
        recruitmentLargeJobCategoryIds:
          getters.searchParams.largeJobCategoryIds,
        excludeClosed: getters.searchParams.isFilteredToPublishedProjects,
        possibleOperatingMinHours:
          getters.searchParams.possibleOperatingMinHours,
        orderByScore: getters.searchParams.orderByScore,
      })
      .finally(() => {
        mutations.finishFetching() // 取得処理終了
      })

    if (projectList.numberOfTotalPages <= nextPage) {
      mutations.setDownCanFetchNextPageFlag() // プロジェクトが存在しなければ、canFetchNextPageFlagフラグを落とす。
    }

    mutations.appendProjects(projectList.list)
    mutations.increasePageNumber()
  },
}

/**
 * exportの定義
 * stateに対して予期せぬ操作をされないように、外部にはgettersとactionsしか公開しない。
 * ==========================================================================
 */
export const projectSearchList = { getters, actions }
