import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
}

export class ContractFreeTrialWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.makeEndpoint(), {})
  }

  private makeEndpoint(): string {
    return `/corporation_contract/corporation_member/${this.params.universalCorporationId}/free_trial_contract/contract`
  }
}
