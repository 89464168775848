import { ApplicationRevisionUpdate } from "~/uiLogics/applicationRevision/applicationRevisionUpdate"
import { UserOperatingStatusWatcher } from "~/uiLogics/applicationRevision/userOperatingStatusWatcher"

/*
 * 新しいRevisionの反映を責務とするクラス。
 * Revisionに更新があったタイミングで、任意の関数を実行してくれる。
 * ただし、UXを考慮してユーザーが操作中でないことが条件。
 */
export class NewRevisionReflector {
  // リビジョンの変更を確認する間隔（1分）
  private static INTERVAL_TIME = 1000 * 60 * 1

  private readonly applicationRevisionUpdate: ApplicationRevisionUpdate
  private readonly userOperatingStatusWatcher: UserOperatingStatusWatcher
  private timerId: NodeJS.Timeout | null = null

  constructor(
    applicationRevisionUpdate: ApplicationRevisionUpdate,
    userOperatingStatusWatcher: UserOperatingStatusWatcher
  ) {
    this.applicationRevisionUpdate = applicationRevisionUpdate
    this.userOperatingStatusWatcher = userOperatingStatusWatcher
  }

  executeActionWhenRevisionChanged(action: () => void) {
    this.userOperatingStatusWatcher.startWatching()

    this.setInterval(async () => {
      // ユーザーが操作中なら何もしない
      if (this.userOperatingStatusWatcher.isOperating()) {
        return
      }

      const isChangedRevision = await this.applicationRevisionUpdate.isChangedRevision()
      if (isChangedRevision) {
        action()
        this.finish()
      }
    })
  }

  finish() {
    this.userOperatingStatusWatcher.stopWatching()
    this.clearInterval()
  }

  private setInterval(func: () => void) {
    this.timerId = setInterval(func, NewRevisionReflector.INTERVAL_TIME)
  }

  private clearInterval() {
    if (this.timerId !== null) {
      clearInterval(this.timerId)
    }
  }

  static async build() {
    const applicationRevisionUpdate = await ApplicationRevisionUpdate.build()
    const userOperatingStatusWatcher = UserOperatingStatusWatcher.build()

    return new NewRevisionReflector(
      applicationRevisionUpdate,
      userOperatingStatusWatcher
    )
  }
}
