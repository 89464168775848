import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { ProjectListView } from "~/backendApis/viewModel"

export class GetFavoriteProjectListReadRequest extends ReadRequest<ProjectListView> {
  public get(): Promise<ProjectListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    return "/project/user/favorite_project/list"
  }
}
