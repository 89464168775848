import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  chatGroupId: string
  messageId: string
}

export class CancelFileMessageWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.makeEndpoint(), {})
  }

  private makeEndpoint(): string {
    const { universalCorporationId, chatGroupId, messageId } = this.params
    return `/matching/corporation_member/${universalCorporationId}/chat_group/${chatGroupId}/cancel/${messageId}`
  }
}
