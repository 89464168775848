import ApplicationApiError from "../../../../foundation/ApplicationApiError"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { UserAccountView } from "~/backendApis/viewModel/userAccount"

export class GetCurrentUserAccountReadRequest extends ReadRequest<UserAccountView> {
  private readonly endpoint: string = "/user_account/user/user_account/me"

  public get(): Promise<UserAccountView | null> {
    return this.apiClient.get(this.endpoint).catch((e: ApplicationApiError) => {
      if (e.isNotFoundError()) {
        return null
      }
      throw e
    })
  }
}
