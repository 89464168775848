export const environment = {
  /**
   * 現在アプリケーションが開発環境で稼働しているのかどうかを判定する。
   */
  get isDev(): boolean {
    return (
      process.env.NODE_ENV === "development" ||
      process.env.ROLLBAR_ENV === "development"
    )
  },
}
