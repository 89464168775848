import { ApiClient } from "~/backendApis/foundation/ApiClient"

/**
 * This is base class for read request.
 */
export abstract class ReadRequest<T> {
  protected readonly apiClient = new ApiClient<T>()

  abstract get(): Promise<any>
}
