import { UserProfileSearchResultListView } from "../../../../viewModel/userProfileSearch"
import { ReadRequest } from "~/backendApis/foundation/ReadRequest"
import { queryParamsGenerator } from "~/backendApis/helpers/queryParamsGenerator"

interface Params {
  searchWord: string | null
  smallJobCategoryIds: number[]
  availableUptimeIds: number[]
  page: number
}

export class SearchUserProfileListReadRequest extends ReadRequest<UserProfileSearchResultListView> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public get(): Promise<UserProfileSearchResultListView> {
    return this.apiClient.get(this.makeEndpoint())
  }

  private makeEndpoint(): string {
    const keys: Array<keyof Params> = [
      "availableUptimeIds",
      "smallJobCategoryIds",
      "page",
      "searchWord",
    ]

    const queryString = queryParamsGenerator.generateSearchQuery<Params>({
      keys,
      params: this.params,
    })

    return `/user_profile_search/user/user_profile/search${queryString}`
  }
}
