import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  jobChangeIntentionId: number
}

export class ChangeJobChangeIntentionWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/user_profile/user/edit/job_change_intention/change"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
