






















import Vue from "vue"
import FacebookAuthButton from "~/components/custom/login/FacebookAuthButton.vue"
import GoogleAuthButton from "~/components/custom/login/GoogleAuthButton.vue"

export default Vue.extend({
  components: {
    FacebookAuthButton,
    GoogleAuthButton,
  },
  props: {
    statusCode: {
      type: Number,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isVisibleAuthButtons: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClickFacebookAuthButton(): void {
      this.$emit("click-facebook-auth-button")
    },
    handleClickGoogleAuthButton(): void {
      this.$emit("click-google-auth-button")
    },
  },
})
