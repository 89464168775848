import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  universalCorporationId: string
  address: string
  corporationName: string
  email: string
  phoneNumber: string
  representativeDepartmentName: string
  representativeDirector: string
  representativePerson: string
}

export class ChangeBillingDestinationWriteRequest extends WriteRequest<void> {
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    const {
      address,
      corporationName,
      email,
      phoneNumber,
      representativeDepartmentName,
      representativeDirector,
      representativePerson,
    } = this.params
    return this.apiClient.post(this.makeEndpoint(), {
      address,
      corporationName,
      email,
      phoneNumber,
      representativeDepartmentName,
      representativeDirector,
      representativePerson,
    })
  }

  private makeEndpoint(): string {
    return `/corporation_contract/corporation_member/${this.params.universalCorporationId}/billing_destination/change`
  }
}
