import { freeAddressList } from "./freeAddressList"

export const emailAddress = {
  /**
   * メールアドレスがフリーアドレスかどうかを判定する。
   */
  isFreeAddress(email: string): boolean {
    if (!email || !email.includes("@")) {
      return false
    }

    const emailDomain = email.split("@")[1]
    return freeAddressList.includes(emailDomain)
  },
}
