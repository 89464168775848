/**
 * exportの定義
 * 認証に関する状態管理をしている。
 * clStoresと同じ役割にはなるが、あえてclAuthに記述するようにしている。
 * これは、認証自体が特殊かつ複雑なものであるため、他の関係のない処理に余計な影響を及ぼさないように、認証関係の処理はclAuthに全てまとめておきたかったことが理由。
 */
import { getters } from "./getters"
import { actions } from "./actions"

export const authState = { getters, actions }
