import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  email: string
}

export class ChangeContactEmailWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/external_notification/user/contact_information/change_email"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
