import { WriteRequest } from "~/backendApis/foundation/WriteRequest"

interface Params {
  facebookUrl: string | null
  githubUrl: string | null
  linkedInUrl: string | null
  twitterUrl: string | null
}

export class ChangeSnsAccountListWriteRequest extends WriteRequest<void> {
  private readonly endpoint: string =
    "/user_profile/user/edit/sns_account_list/change"
  private readonly params: Params

  constructor(params: Params) {
    super()
    this.params = params
  }

  public post(): Promise<void> {
    return this.apiClient.post(this.endpoint, this.params)
  }
}
