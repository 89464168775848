/**
 * Nuxtのinject機能を利用してコンポーネント内などあらゆる箇所から型を有した状態で利用できるプロパティを注入している。
 * 型定義は types/vue.d.ts で他のものとまとめて定義してある。
 *
 * III. Combined Inject - Nuxt TypeScript
 * @link https://typescript.nuxtjs.org/cookbook/plugins.html#iii-combined-inject
 */
import { Plugin, Context } from "@nuxt/types"
import { clApi } from "~/clApi"
import { clAuth } from "~/clAuth"
import { clHelpers } from "~/clHelpers"
import { clStores } from "~/clStores"

const plugin: Plugin = (
  _ctx: Context,
  inject: (key: string, value: any) => void
) => {
  // 将来的にもNuxt標準のプロパティと名前が被らないようにcl（CrowdLinksの略）をプリフィックスに設けている。
  inject("clApi", clApi)
  inject("clAuth", clAuth)
  inject("clHelpers", clHelpers)
  inject("clStores", clStores)
}

export default plugin
