const middleware = {}

middleware['index'] = require('../src/middleware/index.ts')
middleware['index'] = middleware['index'].default || middleware['index']

middleware['requireCorporationAccount'] = require('../src/middleware/requireCorporationAccount.ts')
middleware['requireCorporationAccount'] = middleware['requireCorporationAccount'].default || middleware['requireCorporationAccount']

middleware['requireUserAccount'] = require('../src/middleware/requireUserAccount.ts')
middleware['requireUserAccount'] = middleware['requireUserAccount'].default || middleware['requireUserAccount']

export default middleware
